import {
	RECEIVE_USERS,
	RECEIVE_USERS_ERROR,
	REQUEST_USERS,
} from "@/core/constants";
import type {
	SettingsActions,
	SettingsReducer,
} from "@/core/reducers/settingsReducer/types";

const initState: SettingsReducer = {
	usersLoading: true,
	usersError: true,
	usersData: [],
};

const settingsReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: SettingsActions,
): SettingsReducer => {
	switch (action.type) {
		case REQUEST_USERS:
			return { ...state, usersLoading: true, usersError: false };
		case RECEIVE_USERS: {
			return {
				...state,
				usersLoading: false,
				usersData: action.payload,
				usersError: false,
			};
		}
		case RECEIVE_USERS_ERROR:
			return {
				...state,
				usersLoading: false,
				usersData: [],
				usersError: true,
			};
		default:
			return state;
	}
};
export default settingsReducer;
