import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Collapse, List, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { ReactSVG } from "react-svg";
import resultStyle from "./Result.module.scss";

import { PUBLIC_URL } from "../../../../../constants";
import { MODULE_NAMES } from "../../../../../constants/modules";
import { useAppSelector } from "../../../../../hooks/reduxHooks";
import {
	flattenFormulas,
	getDisplayName,
	getLHSVariable,
} from "../../../../../utils";
import { getFormatedResult } from "./SectionResult";

const { Panel } = Collapse;

function Result(props) {
	const { resultIsOpen, type, isFixed, formulas, arrUI } = props;
	const [isMinus, setIsMinus] = useState(false);
	const [isLow, setIsLow] = useState(false);
	const results = useAppSelector((state) => state.results);

	const allData = useAppSelector((state) => state.calculation);
	const {
		modules: {
			common: {
				inputData: { timeUnitDivider = 60 } = {},
			} = {},
		} = {},
	} = allData;
	const timeUnit = timeUnitDivider === 1 ? "minute" : "hour";

	const currentModuleFormulas = formulas[type] || {};
	const resFormulasData =
		currentModuleFormulas.formulas || currentModuleFormulas;
	const formulasData = resFormulasData.statusCode ? {} : resFormulasData;
	const arrFlattenFormulas = flattenFormulas(formulasData);
	const arrSectionFormulas = arrFlattenFormulas.filter(
		(formula) =>
			formula.Section &&
			formula.Section !== "NA" &&
			formula.Section.trim() === "Overall",
	);

	useEffect(() => {
		setIsMinus(false);
		setIsLow(false);
		for (const secFormula of arrFlattenFormulas) {
			const secFormulaItem = secFormula || {};
			const unit = secFormulaItem.Unit;
			const previewResult = results[getLHSVariable(secFormulaItem.Formula)];
			if (previewResult < 0) {
				setIsMinus(true);
			} else if (unit === "%" && previewResult >= 0 && previewResult < 20) {
				setIsLow(true);
			}
		}
	}, [arrSectionFormulas]);

	return arrSectionFormulas.length > 0 && !resFormulasData.statusCode ? (
		<div className="resultSection">
			<div className={resultStyle.sectionList} id="resultList">
				<List
					header={<h4>Sections in this Module</h4>}
					size="small"
					dataSource={arrUI.map(([sectionName]) => sectionName)}
					renderItem={(item) => (
						<List.Item>
							<Link
								activeClass={resultStyle.active}
								className={item}
								to={item}
								containerId="main"
								spy
								smooth
								duration={10}
								offset={-120}
							>
								{item}
							</Link>
						</List.Item>
					)}
				/>
			</div>
			<div
				className={`${resultStyle.step3Result} ${isFixed && resultStyle.isFixed} ${
					resultStyle[type]
				}`}
			>
				<Collapse
					bordered={false}
					expandIcon={({ isActive }) => (
						<ChevronDownIcon
							style={{
								transform: isActive ? "rotate(180deg)" : "",
								transition: "0.5s",
								transformOrigin: "50% 30%",
							}}
						/>
					)}
					className={resultStyle.collapse}
					expandIconPosition="end"
				>
					<Panel
						className={`${isMinus ? resultStyle.minus : ""}${
							isLow ? resultStyle.low : ""
						}`}
						header={
							<span>
								Overall Preview Result&nbsp;
								{isMinus && (
									<Tooltip
										title={
											<>
												<strong>Your business case is negative</strong>
												<br />
												Your business case is negative as a result of the
												current state level of effort vs. the Harness licensing
												cost. Please double check that we are accounting for all
												level of effort in the customers current state and
												ensure that it exceeds the proposed license cost
												proposed.
											</>
										}
									>
										<WarningOutlined />
									</Tooltip>
								)}
								{isLow && (
									<Tooltip
										title={
											<>
												<strong>Your business case is weak</strong>
												<br />
												Your business case is weak as a result of the current
												state level of effort vs. the Harness licensing cost.
												Please double check that we are accounting for all level
												of effort in the customers current state and ensure that
												it exceeds the proposed license cost proposed.
											</>
										}
									>
										<InfoCircleOutlined />
									</Tooltip>
								)}
							</span>
						}
						key="1"
					>
						{resultIsOpen ? (
							<>
								<div className={resultStyle.previewModuleTitle}>
									<ReactSVG src={`${PUBLIC_URL}/assets/icon_${type}.svg`} />
									<h5>{MODULE_NAMES[type]}</h5>
								</div>
								<ul>
									{arrSectionFormulas.map((secFormula) => {
										const secFormulaItem = secFormula || {};
										const unit = secFormulaItem.Unit;
										const previewResult =
											results[getLHSVariable(secFormulaItem.Formula)];
										return (
											<li
												className={resultStyle.previewItem}
												key={secFormulaItem.Formula}
											>
												<p>{getDisplayName(secFormula)}</p>
												{getFormatedResult(previewResult, unit, timeUnit)}
											</li>
										);
									})}
								</ul>
							</>
						) : null}
					</Panel>
				</Collapse>
			</div>
		</div>
	) : null;
}

export { Result };
