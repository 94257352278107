import { getUserByEmail } from "@/core/actions";
import { UserForm } from "@/core/components";
import { useAppDispatch, useAppSelector } from "@/core/hooks/reduxHooks";
import { Button } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import React from "react";
import styles from "./preferences.module.scss";

function PreferencesPage() {
	const ref = React.createRef<FormInstance>();
	const { permission: userData } = useAppSelector((state) => state.login);
	const dispatch = useAppDispatch();
	const { email = "" } = userData;

	const handleOk = () => {
		const userForm = ref.current;
		if (userForm?.validateFields) {
			userForm.validateFields().then(() => {
				userForm.submit();
			});
		}
	};

	const updateUsersData = () => {
		dispatch(getUserByEmail(email));
	};

	return (
		<div className={styles.preferences}>
			<UserForm
				ref={ref}
				user={userData}
				updateUsersData={updateUsersData}
				preference
			/>
			<div className={styles.btnsWrapper}>
				<Button type="primary" onClick={handleOk}>
					Update Preferences
				</Button>
			</div>
		</div>
	);
}

const Preferences = React.memo(PreferencesPage);

export default Preferences;
