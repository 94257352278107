import {
	CalculatorOutlined,
	LineChartOutlined,
	LogoutOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import leftNavStyle from "./index.module.scss";

import { signOutApp } from "../../auth";
import { ADMINS, ADMIN_ROLE, EDITOR_ROLE, PUBLIC_URL } from "../../constants";
import { useAppSelector } from "../../hooks/reduxHooks";

const getInitials = (displayName: string) => {
	const names = displayName.split(" ");
	const firstName = names[0] || "?";
	const lastName = names[1] || "";
	return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
};

function LeftNavComponent() {
	const location = useLocation();
	const { pathname } = location;
	const loginInfo = useAppSelector((state) => state.login);
	const {
		permission: { role = "", email: userEmail = "", displayName = "" } = {},
	} = loginInfo;
	const initials = getInitials(displayName);
	const isSettingsPage = /^\/settings/.test(pathname);
	const isReportingPage = /^\/reporting/.test(pathname);
	const roleLowerCase = role.toLowerCase();
	const isAdmin =
		roleLowerCase === EDITOR_ROLE ||
		roleLowerCase === ADMIN_ROLE ||
		ADMINS.includes(userEmail);

	return (
		<div className={leftNavStyle.leftNav}>
			<div className={leftNavStyle.logo}>
				<Link to="/home">
					<img
						alt="Harness logo"
						width="32"
						height="28"
						src={`${PUBLIC_URL}/assets/icon_logoOnlyImage.svg`}
					/>
				</Link>
			</div>
			<menu>
				<ul>
					<li
						className={
							isSettingsPage || isReportingPage ? "" : leftNavStyle.selected
						}
					>
						<Link to="/home">
							<div className={leftNavStyle.menuItem}>
								<CalculatorOutlined style={{ fontSize: "24px" }} />
								<div className={leftNavStyle.menuName}>Home</div>
							</div>
						</Link>
					</li>
					{isAdmin && (
						<li className={isReportingPage ? leftNavStyle.selected : ""}>
							<Link to="/reporting">
								<div className={leftNavStyle.menuItem}>
									<LineChartOutlined style={{ fontSize: "24px" }} />
									<div className={leftNavStyle.menuName}>Reporting</div>
								</div>
							</Link>
						</li>
					)}
					<li className={isSettingsPage ? leftNavStyle.selected : ""}>
						<Link to="/settings">
							<div className={leftNavStyle.menuItem}>
								<SettingOutlined style={{ fontSize: "24px" }} />
								<div className={leftNavStyle.menuName}>Settings</div>
							</div>
						</Link>
					</li>
				</ul>
			</menu>
			<div className={leftNavStyle.avatar}>
				<Tooltip title={displayName}>
					<div
						className={leftNavStyle.initials}
						style={{ backgroundSize: "cover" }}
					>
						{initials}
					</div>
				</Tooltip>
				<p
					className={leftNavStyle.signOut}
					onClick={async () => signOutApp()}
					onKeyDown={async () => signOutApp()}
				>
					<span>Logout</span>
					<LogoutOutlined style={{ fontSize: "12px", marginLeft: "3px" }} />
				</p>
			</div>
		</div>
	);
}

const LeftNav = React.memo(LeftNavComponent);
export { LeftNav };
