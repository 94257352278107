import {
	API_BASE_URL,
	COMPLETE_BATCH_FORMULAS,
	COMPLETE_BATCH_UI,
	RECEIVE_BATCH_UI,
	RECEIVE_FORMULAS,
	RECEIVE_FORMULAS_ERROR,
	RECEIVE_GET_USER_BY_EMAIL,
	RECEIVE_GET_USER_BY_EMAIL_ERROR,
	RECEIVE_MODULE_DATA,
	RECEIVE_MODULE_DATA_ERROR,
	RECEIVE_OPPORTUNITY,
	RECEIVE_OPPORTUNITY_ERROR,
	RECEIVE_SDCA_DATA,
	RECEIVE_SDCA_DATA_ERROR,
	RECEIVE_SDCA_DRAFT_DATA,
	RECEIVE_SDCA_DRAFT_DATA_ERROR,
	RECEIVE_SDCA_LISTS_DATA,
	RECEIVE_SDCA_LISTS_DATA_ERROR,
	RECEIVE_UI_ERROR,
	RECEIVE_USERS,
	RECEIVE_USERS_ERROR,
	REQUEST_FORMULAS,
	REQUEST_GET_USER_BY_EMAIL,
	REQUEST_MODULE_DATA,
	REQUEST_OPPORTUNITY,
	REQUEST_SDCA_DATA,
	REQUEST_SDCA_DRAFT_DATA,
	REQUEST_SDCA_LISTS_DATA,
	REQUEST_UI,
	REQUEST_USERS,
	RESET_INFORMATION_DATA,
	RESET_MODULE_DATA,
	SAVE_INVALID_FORM_ID,
	SELECT_MODULE,
	UPDATE_CAL_RESULTS,
	UPDATE_INFORMATION_DATA,
	UPDATE_STEP,
} from "@/core/constants";
import type { SupportedModuleKeys } from "@/core/constants/modules";
import type { PartialSdcaDeckDraft } from "@/core/reducers/calculationReducer/types";
import type { FormulaApiData } from "@/core/reducers/formulaReducer/types";
import type { UserPermissionDetails } from "@/core/reducers/loginReducer/types";
import type {
	FetchModuleDataApiType,
	ResetModuleDataAction,
	SelectModuleAction,
	SelectedModulesMap,
} from "@/core/reducers/moduleReducer/types";
import type { OpportunitiesApiData } from "@/core/reducers/opportunityReducer/types";
import type { UiData } from "@/core/reducers/uiReducer/types";
import type { AppDispatch } from "@/core/store";
import type { Results, SdcaDeck, SdcaDraft } from "@/core/types";
import { authAxios, showErrorMessage } from "@/core/utils/fetch";
import type { AxiosError, AxiosResponse } from "axios";

export const updateStep = (index: number) => ({
	type: UPDATE_STEP,
	payload: index,
});

export const updateInformationData = (object: PartialSdcaDeckDraft) => ({
	type: UPDATE_INFORMATION_DATA,
	payload: object,
});

export const resetInformationData = (data: PartialSdcaDeckDraft) => ({
	type: RESET_INFORMATION_DATA,
	payload: data,
});

export const updateCalResults = (object: Results) => ({
	type: UPDATE_CAL_RESULTS,
	payload: object,
});

export const resetModule = (
	data: SelectedModulesMap,
): ResetModuleDataAction => ({
	type: RESET_MODULE_DATA,
	payload: data,
});

export const selectModule = (
	object: SelectedModulesMap,
): SelectModuleAction => ({
	type: SELECT_MODULE,
	payload: object,
});

export const fetchSDCAListsData = () => {
	const url = `${API_BASE_URL}/decks`;
	return (dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_SDCA_LISTS_DATA,
		});
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: SdcaDeck[] = res.data;
				dispatch({
					type: RECEIVE_SDCA_LISTS_DATA,
					payload,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_SDCA_LISTS_DATA_ERROR,
				});
			});
	};
};

export const fetchSDCAData = (sdcaId: string) => {
	const url = `${API_BASE_URL}/decks/${sdcaId}`;
	return (dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_SDCA_DATA,
		});
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: SdcaDeck = res.data;
				dispatch({
					type: RECEIVE_SDCA_DATA,
					payload,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_SDCA_DATA_ERROR,
				});
			});
	};
};

export const fetchSDCADraftData = () => {
	const url = `${API_BASE_URL}/drafts`;
	return (dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_SDCA_DRAFT_DATA,
		});
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: SdcaDraft[] = res.data;
				dispatch({
					type: RECEIVE_SDCA_DRAFT_DATA,
					payload,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_SDCA_DRAFT_DATA_ERROR,
				});
			});
	};
};

export const getUserByEmail = (email: string) => (dispatch: AppDispatch) => {
	dispatch({ type: REQUEST_GET_USER_BY_EMAIL });
	const url = `${API_BASE_URL}/users/email/${email}`;
	return authAxios({
		method: "GET",
		url,
	})
		.then((res: AxiosResponse) => {
			const payload: UserPermissionDetails = res.data;
			dispatch({
				type: RECEIVE_GET_USER_BY_EMAIL,
				payload,
			});
		})
		.catch((error: AxiosError) => {
			showErrorMessage(error);
			dispatch({ type: RECEIVE_GET_USER_BY_EMAIL_ERROR });
		});
};

export const fetchOpportunityData =
	(url = `${API_BASE_URL}/opportunities`) =>
	(dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_OPPORTUNITY,
		});
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: OpportunitiesApiData = res.data;
				dispatch({
					type: RECEIVE_OPPORTUNITY,
					payload: payload.opportunities,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_OPPORTUNITY_ERROR,
				});
			});
	};

export const saveInvalidFormID = (val: string) => ({
	type: SAVE_INVALID_FORM_ID,
	payload: val,
});

export const fetchModuleData =
	(url = `${API_BASE_URL}/modules`) =>
	(dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_MODULE_DATA,
		});
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: FetchModuleDataApiType = res.data;
				dispatch({
					type: RECEIVE_MODULE_DATA,
					payload: payload.modules,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_MODULE_DATA_ERROR,
				});
			});
	};

export const fetchFormulas =
	(module: SupportedModuleKeys) => (dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_FORMULAS,
		});
		const url = `${API_BASE_URL}/formulas/${module}`;
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: FormulaApiData = res.data;
				dispatch({
					type: RECEIVE_FORMULAS,
					module,
					payload,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_FORMULAS_ERROR,
				});
			});
	};

export const fetchBatchFormulasData =
	(modules: SupportedModuleKeys[]) => async (dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_FORMULAS,
		});
		await Promise.all(
			modules.map(async (module) => {
				const url = `${API_BASE_URL}/formulas/${module}`;
				await authAxios({
					method: "GET",
					url,
				})
					.then((res: AxiosResponse) => {
						const payload: FormulaApiData = res.data;
						dispatch({
							type: RECEIVE_FORMULAS,
							module,
							payload,
						});
					})
					.catch((error: AxiosError) => {
						showErrorMessage(error);
						dispatch({
							type: RECEIVE_FORMULAS_ERROR,
						});
						Promise.reject();
					});
			}),
		);
		return dispatch({
			type: COMPLETE_BATCH_FORMULAS,
		});
	};

export const fetchUsersData =
	(url = `${API_BASE_URL}/users`) =>
	(dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_USERS,
		});
		return authAxios({
			method: "GET",
			url,
		})
			.then((res: AxiosResponse) => {
				const payload: UserPermissionDetails[] = res.data;
				dispatch({
					type: RECEIVE_USERS,
					payload,
				});
			})
			.catch((error: AxiosError) => {
				showErrorMessage(error);
				dispatch({
					type: RECEIVE_USERS_ERROR,
				});
			});
	};

export const fetchBatchUiData =
	(modules: SupportedModuleKeys[]) => async (dispatch: AppDispatch) => {
		dispatch({
			type: REQUEST_UI,
		});
		await Promise.all(
			modules.map(async (module) => {
				const url = `${API_BASE_URL}/ui-layout/${module}`;
				await authAxios({
					method: "GET",
					url,
				})
					.then((res: AxiosResponse) => {
						const payload: UiData = res.data;
						dispatch({
							type: RECEIVE_BATCH_UI,
							module,
							payload,
						});
					})
					.catch((error: AxiosError) => {
						showErrorMessage(error);
						dispatch({
							type: RECEIVE_UI_ERROR,
						});
						Promise.reject();
					});
			}),
		);
		return dispatch({
			type: COMPLETE_BATCH_UI,
		});
	};
