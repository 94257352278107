import React from "react";
import { ReactSVG } from "react-svg";
import { PUBLIC_URL } from "../../constants";
import stepTrackerStyle from "./StepTracker.module.scss";

const stepInfo = [
	"Basic Info",
	"Select Modules",
	"Solution Profiles",
	"Review Summary",
	"View Results",
];

const StepTracker = (props) => {
	const { active, handleOnClickStep } = props;
	const spanName = (index) => {
		if (active === index) {
			return stepTrackerStyle.active;
		}
		if (active > index) {
			return stepTrackerStyle.actived;
		}
		return " ";
	};
	return (
		<ul className={stepTrackerStyle.steps}>
			{stepInfo.map((item, index) => {
				return (
					<li
						key={item}
						className={`${spanName(index)} ${
							index > 2 ? stepTrackerStyle.lock : ""
						} ${index < active ? stepTrackerStyle.linkBack : ""}`}
						onClick={() => {
							if (index < active) {
								handleOnClickStep(index);
							}
						}}
						onKeyDown={() => {
							if (index < active) {
								handleOnClickStep(index);
							}
						}}
					>
						<span>
							<span>
								{active > index ? (
									<ReactSVG src={`${PUBLIC_URL}/assets/icon_tick.svg`} />
								) : (
									index + 1
								)}
							</span>
							{item}
						</span>
						{index + 1 === stepInfo.length ? (
							""
						) : (
							<i>
								<ReactSVG src={`${PUBLIC_URL}/assets/icon_arrowRight.svg`} />
							</i>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export { StepTracker };
