import { PUBLIC_URL } from "@/core/constants";
import React from "react";

function LoadingComponent() {
	return (
		<div className="alignCenter">
			<img
				alt="Loading..."
				src={`${PUBLIC_URL}/assets/loading-animation.gif`}
			/>
		</div>
	);
}

const Loading = React.memo(LoadingComponent);

export { Loading };
