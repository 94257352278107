import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Select } from "antd";
import React, { useState } from "react";
import styles from "./SelectAvailability.module.scss";

const { Option } = Select;

/*
Availability %	Downtime per year
90% ("one nine")	36.53 days
95% ("one and a half nines")	18.26 days
97% ("one and three quarters nines")	10.96 days
98% ("one and seven eights nines")	7.31 days
99% ("two nines")	3.65 days
99.5% ("two and a half nines")	1.83 days
99.8% ("two and seven eighths nines")	17.53 hours
99.9% ("three nines")	8.77 hours
99.95% ("three and a half nines")	4.38 hours
99.99% ("four nines")	52.60 minutes
99.995% ("four and a half nines")	26.30 minutes
99.999% ("five nines")	5.26 minutes
99.9999% ("six nines")	31.56 seconds
*/

const minutesInAnHour = 60;
const minutesInADay = minutesInAnHour * 24;
const AVAILABILITIES = [
	{
		title: '90% ("one nine")',
		value: minutesInADay * 36.53,
	},
	{
		title: '95% ("one and a half nines")',
		value: minutesInADay * 18.26,
	},
	{
		title: '97% ("one and three quarters nines")',
		value: minutesInADay * 10.96,
	},
	{
		title: '98% ("one and seven eights nines")',
		value: minutesInADay * 7.31,
	},
	{
		title: '99% ("two nines")',
		value: minutesInADay * 3.65,
	},
	{
		title: '99.5% ("two and a half nines")',
		value: minutesInADay * 1.83,
	},
	{
		title: '99.8% ("two and seven eighths nines")',
		value: minutesInAnHour * 17.53,
	},
	{
		title: '99.9% ("three nines")',
		value: 526.2, // minutesInAnHour * 8.77
	},
	{
		title: '99.95% ("three and a half nines")',
		value: minutesInAnHour * 4.38,
	},
	{
		title: '99.99% ("four nines")',
		value: 52.6,
	},
	{
		title: '99.995% ("four and a half nines")',
		value: 26.3,
	},
	{
		title: '99.999% ("five nines")',
		value: 5.26,
	},
	{
		title: '99.9999% ("six nines")',
		value: 31.56 / 60,
	},
];
export const DEFAULT_VALUE = AVAILABILITIES[7];

function SelectAvailability({ value = "", onChange }) {
	const [downtime, setDowntime] = useState(0);

	const triggerChange = (changedValue) => {
		onChange(changedValue);
	};

	const onAvailabilityChange = (newTools) => {
		setDowntime(newTools);
		triggerChange(newTools);
	};

	return (
		<span>
			<Select
				allowClear
				value={value || downtime}
				onChange={onAvailabilityChange}
				suffixIcon={<ChevronDownIcon />}
			>
				{AVAILABILITIES.map((ava) => (
					<Option value={ava.value} key={ava.title}>
						{ava.title}
					</Option>
				))}
			</Select>
		</span>
	);
}

export default SelectAvailability;
