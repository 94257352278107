import {
	RESET_INFORMATION_DATA,
	SAVE_INVALID_FORM_ID,
	UPDATE_INFORMATION_DATA,
	UPDATE_STEP,
	step1Matrix,
} from "@/core/constants";
import type {
	CalculationActions,
	CalculationReducer,
} from "@/core/reducers/calculationReducer/types";
import { cloneDeep } from "lodash";

export const initState: CalculationReducer = {
	step: 1,
	draftId: undefined,
	sdcaId: undefined,
	modules: {},
	createdUserTimeZone: "America/Los_Angeles",
	[step1Matrix.nameOfSoftwareDeliveryCapabilityAssessment]: "",
	[step1Matrix.nameOfBusinessUnit]: "",
	[step1Matrix.opportunity]: undefined,
	[step1Matrix.bvType]: "bva",
	timeUnit: "hour",
	loading: false,
	error: false,
	invalidArr: [],
	fromRestore: false,
};

const calculationReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: CalculationActions,
): CalculationReducer => {
	switch (action.type) {
		case UPDATE_STEP: {
			return {
				...state,
				step: action.payload,
			};
		}
		case UPDATE_INFORMATION_DATA: {
			const partialData = cloneDeep(action.payload);
			return {
				...state,
				...partialData,
			};
		}
		case RESET_INFORMATION_DATA: {
			let partialData = {};
			if (action.payload) {
				partialData = cloneDeep(action.payload);
			}
			return { ...initState, ...partialData };
		}
		case SAVE_INVALID_FORM_ID: {
			const { key, type } = action.payload;
			const newInvalidArr = Array.from(new Set(state.invalidArr));

			if (type === "add") {
				newInvalidArr.push(key);
			}
			if (type === "remove") {
				const index = newInvalidArr.indexOf(key);
				if (index > -1) newInvalidArr.splice(index, 1);
			}
			return { ...state, invalidArr: Array.from(new Set(newInvalidArr)) };
		}
		default:
			return state;
	}
};

export default calculationReducer;
