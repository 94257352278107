import {
	RECEIVE_MODULE_DATA,
	RECEIVE_MODULE_DATA_ERROR,
	REQUEST_MODULE_DATA,
	RESET_MODULE_DATA,
	SELECT_MODULE,
} from "@/core/constants";
import { COMMON_KEY, type SupportedModuleKeys } from "@/core/constants/modules";
import type {
	ModuleActions,
	ModuleReducer,
} from "@/core/reducers/moduleReducer/types";
import { sortModules } from "@/core/utils";
import { cloneDeep } from "lodash";

const initState: ModuleReducer = {
	loading: false,
	error: false,
	modules: [],
	moduleOrder: [],
	selectedModule: {},
};

const moduleReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: ModuleActions,
): ModuleReducer => {
	switch (action.type) {
		case SELECT_MODULE: {
			const selectedModuleCopy = cloneDeep(state.selectedModule);
			const [key, value] = Object.entries(action.payload)[0];
			selectedModuleCopy[key as SupportedModuleKeys] = value;
			const arrSelectedModules = sortModules(
				Object.entries(selectedModuleCopy),
				state.moduleOrder,
			);
			for (const mdl of arrSelectedModules) {
				const [mdlKey, mdlVal] = mdl;
				selectedModuleCopy[mdlKey as SupportedModuleKeys] = mdlVal;
			}
			return {
				...state,
				selectedModule: selectedModuleCopy,
			};
		}
		case REQUEST_MODULE_DATA:
			return { ...state, loading: true, error: false };
		case RECEIVE_MODULE_DATA: {
			return {
				...state,
				loading: false,
				modules: action.payload,
				moduleOrder: action.payload
					.sort((a, b) => a.index - b.index)
					.map((mdl) => mdl.id),
				error: false,
			};
		}
		case RECEIVE_MODULE_DATA_ERROR: {
			return {
				...state,
				loading: false,
				modules: [],
				moduleOrder: [],
				error: true,
			};
		}
		case RESET_MODULE_DATA: {
			const selectedModuleCopy = cloneDeep(action.payload);
			delete selectedModuleCopy[COMMON_KEY];
			return {
				...state,
				selectedModule: selectedModuleCopy,
			};
		}
		default:
			return state;
	}
};

export default moduleReducer;
