export const COMMON_KEY = "common";
export const CD_KEY = "cd";
export const CI_KEY = "ci";
export const FF_KEY = "ff";
export const CC_KEY = "cc";
export const SRM_KEY = "srm";
export const STO_KEY = "sto";
export const CE_KEY = "ce";

export const SUPPORTED_MODULE_KEYS = [
	COMMON_KEY,
	CD_KEY,
	CI_KEY,
	FF_KEY,
	CC_KEY,
	SRM_KEY,
	STO_KEY,
	CE_KEY,
] as const;

export const MODULE_NAMES = {
	[COMMON_KEY]: "Basic Information",
	[CD_KEY]: "Continuous Delivery",
	[CI_KEY]: "Continuous Integration",
	[FF_KEY]: "Feature Flags",
	[CC_KEY]: "Cloud Cost Management",
	[SRM_KEY]: "Service Reliability Management",
	[STO_KEY]: "Security Testing Orchestration",
	[CE_KEY]: "Chaos Engineering",
};

export type SupportedModuleKeys = (typeof SUPPORTED_MODULE_KEYS)[number];
