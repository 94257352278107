import {
	RECEIVE_SDCA_DATA,
	RECEIVE_SDCA_DATA_ERROR,
	REQUEST_SDCA_DATA,
} from "@/core/constants";
import type {
	SdcaActions,
	SdcaReducer,
} from "@/core/reducers/sdcaReducer/types";

const initState: SdcaReducer = {
	loading: true,
	error: true,
	data: null,
};

// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
const sdcaReducer = (state = initState, action: SdcaActions): SdcaReducer => {
	switch (action.type) {
		case REQUEST_SDCA_DATA:
			return { ...state, loading: true, error: false };
		case RECEIVE_SDCA_DATA: {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: false,
			};
		}
		case RECEIVE_SDCA_DATA_ERROR:
			return {
				...state,
				loading: false,
				data: null,
				error: true,
			};
		default:
			return state;
	}
};
export default sdcaReducer;
