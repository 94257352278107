import {
	RECEIVE_OPPORTUNITY,
	RECEIVE_OPPORTUNITY_ERROR,
	REQUEST_OPPORTUNITY,
} from "@/core/constants";
import type {
	OpportunityActions,
	OpportunityReducer,
} from "@/core/reducers/opportunityReducer/types";

const initState: OpportunityReducer = {
	loading: false,
	error: false,
	opportunities: [],
};

const opportunityReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: OpportunityActions,
): OpportunityReducer => {
	switch (action.type) {
		case REQUEST_OPPORTUNITY:
			return { ...state, loading: true, error: false };
		case RECEIVE_OPPORTUNITY: {
			return {
				...state,
				opportunities: action.payload,
				loading: false,
				error: false,
			};
		}
		case RECEIVE_OPPORTUNITY_ERROR:
			return {
				...state,
				loading: false,
				opportunities: [],
				error: true,
			};
		default:
			return state;
	}
};

export default opportunityReducer;
