import { EDIT_PERMISSION_ROLES } from "@/core/constants";
import { useAppSelector } from "@/core/hooks/reduxHooks";
import type React from "react";
import { Redirect } from "react-router-dom";

export const useEditAccessCheck = (Component: React.ElementType) => {
	const useWrapper = (props: React.JSX.IntrinsicAttributes) => {
		const userInfo = useAppSelector((state) => state.login);
		const { role } = userInfo.permission;

		if (role) {
			if (
				EDIT_PERMISSION_ROLES.includes(
					role as (typeof EDIT_PERMISSION_ROLES)[number],
				)
			) {
				return <Component {...props} />;
			}
		}
		return <Redirect to="/home" />;
	};

	return useWrapper;
};
