import { Button, message } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { PUBLIC_URL } from "../../../constants";
import { MODULE_NAMES } from "../../../constants/modules";
import step2Style from "./Calculation.module.scss";

const moduleIllustrations = {
	platform: {
		title: "The Modern Software Delivery Platform™",
		subTitle: "Loved by Developers, Trusted by Businesses",
		desc: "Harness is the industry’s first Software Delivery Platform to use AI to simplify your DevOps processes - CI, CD, Feature Flags, Cloud Costs, and much more.",
	},
	ci: {
		title: "Continuous Integration",
		subTitle: "Modern, Fast, Scalable CI with a Focus on Developer Experience",
		desc: "An intelligent, container-native CI solution with isolated builds and standardized extensions. Build artifacts smarter and faster.",
	},
	cd: {
		title: "Continuous Delivery",
		subTitle: "Pipelines, GitOps... we have you covered",
		desc: `Your application deployment needs aren't all the same - some need configurability and flexibility, others can be simply automated through Git. All need enterprise-grade governance and security.
		Harness Continuous Delivery is your solution for all.`,
	},
	ff: {
		title: "Feature Flags",
		subTitle: "Ship Features 60% Faster With Less Risk",
		desc: `Ship more features and reduce risk.
		Empower your team with control and governance.`,
	},
	cc: {
		title: "Cloud Cost Management",
		subTitle: "Complete Cloud Control and Automated Cost Management",
		desc: "Make cloud costs easy with intelligence. Achieve cost transparency, cut costs up to 75%, and bridge the Finance & Engineering gap.",
	},
	srm: {
		title: "Service Reliability Management",
		subTitle: "Deploy Faster With Better Reliability",
		desc: `Your business doesn't need to choose between deployment velocity or the reliability needs of a production-quality environment - get the benefits of both.`,
	},
	sto: {
		title: "Security Testing Orchestration",
		subTitle: "Rapid Delivery of Secure Applications",
		desc: "Empower your devs with prioritized security vulnerability data - Deliver highly secure applications while maintaining deployment velocity and minimizing rework.",
	},
	ce: {
		title: "Chaos Engineering",
		subTitle: "Improve application resiliency and reduce costly downtime",
		desc: `Run controlled 'chaos' experiments to understand how real-world stress and failures impact your systems. Create more reliable applications and infrastructure.`,
	},
};

const Step2Page = (props) => {
	const { handleOnClickStep, handSelectModules, allModules } = props;

	const {
		modules = [],
		moduleOrder = [],
		loading,
		error,
		selectedModule: selectedModuleData,
	} = allModules;
	const availableModules = modules
		.filter((mdl) => !mdl.disabled)
		.map((mdl) => mdl.id);
	const [illuModule, setIlluModule] = useState("platform");

	const handleShowIllustration = (mdl) => {
		setIlluModule(mdl);
	};
	const handleResetIllustration = () => {
		setIlluModule("platform");
	};
	const handleSelectModules = () => {
		if (
			!isEmpty(selectedModuleData) &&
			Object.values(selectedModuleData).some((v) => v)
		) {
			handleOnClickStep(2);
		} else {
			message.error("Please select at least 1 module!");
		}
	};

	if (loading || error) return null;
	const mdlIllustration = moduleIllustrations[illuModule] || {};
	return (
		<div className={step2Style.step2form}>
			<div className={step2Style.selectModules}>
				<ul>
					{moduleOrder.length > 0 &&
						moduleOrder.map((item) => {
							return (
								<li
									key={item}
									onClick={() => {
										handSelectModules({ [item]: !selectedModuleData[item] });
									}}
									onKeyDown={() => {
										handSelectModules({ [item]: !selectedModuleData[item] });
									}}
									onMouseOver={() => {
										handleShowIllustration(item);
									}}
									onFocus={() => {
										handleShowIllustration(item);
									}}
									onMouseOut={handleResetIllustration}
									onBlur={handleResetIllustration}
									className={`${
										selectedModuleData?.[item] ? step2Style.selected : ""
									} ${availableModules.includes(item) ? "" : "disabled"}`}
								>
									<ReactSVG src={`${PUBLIC_URL}/assets/icon_${item}.svg`} />
									<h4>{MODULE_NAMES[item]}</h4>
									<span>
										<i />
										<ReactSVG
											className={step2Style.icon}
											src={`${PUBLIC_URL}/assets/icon_tick.svg`}
										/>
									</span>
								</li>
							);
						})}
				</ul>
				<div className={step2Style.illustration}>
					<img
						alt="Module"
						src={`${PUBLIC_URL}/assets/illu_${illuModule}.svg`}
						className={step2Style.imgIlluModule}
					/>
					<h3>{mdlIllustration.title}</h3>
					<h4>{mdlIllustration.subTitle}</h4>
					<div className={step2Style.illuDesc}>{mdlIllustration.desc}</div>
				</div>
			</div>

			<div className="btns">
				<Button type="button" onClick={() => handleOnClickStep(0)}>
					❮ Back
				</Button>
				<Button type="primary" onClick={handleSelectModules}>
					Continue ❯
				</Button>
			</div>
		</div>
	);
};

export { Step2Page };
