import { Button, Table, Tooltip } from "antd";
import ReactECharts from "echarts-for-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { updateInformationData } from "../../../../actions";
import { regCustomCategories } from "../../../../components/CustomCategories";
import {
	ADMIN_ROLE,
	BAR_COLORS,
	EDIT_PERMISSION_ROLES,
	PUBLIC_URL,
} from "../../../../constants";
import {
	currencyFormatter as curFmtr,
	getLHSVariable,
	numberFormatter as numFmtr,
} from "../../../../utils";

import { MODULE_NAMES } from "../../../../constants/modules";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import style from "./step5Forms.module.scss";

const optionLegend = {
	orient: "vertical",
	right: "right",
	top: "middle",
	padding: [20, 40, 0, 20],
};

const optionGrid = {
	left: "3%",
	right: "230px",
	bottom: "3%",
	containLabel: true,
};
const optionGridWoLegend = {
	left: "3%",
	right: "3%",
	bottom: "3%",
	containLabel: true,
};

const numberFormatter = (series) => {
	const val = typeof series === "object" ? series.value : series;
	if (val >= 1000000000) {
		return `${numFmtr(Math.round(val / 1000000))}M`;
	}
	if (val >= 1000000) {
		return `${numFmtr(Math.round(val / 1000))}K`;
	}
	return numFmtr(val);
};
const currencyFormatter = (series) => {
	const val = typeof series === "object" ? series.value : series;
	if (val >= 1000000000) {
		return `${curFmtr(Math.round(val / 1000000))}M`;
	}
	if (val >= 1000000) {
		return `${curFmtr(Math.round(val / 1000))}K`;
	}
	return curFmtr(val);
};

const Step5Module = (props) => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const {
		onClickInternalStep,
		getSubmitData,
		handleOnClickStep,
		selectedModuleOrder,
		sdcaId,
	} = props;

	const allData = useAppSelector((state) => state.calculation);
	let results = useAppSelector((state) => state.results);
	const formulas = useAppSelector((state) => state.formula);
	const { loading } = formulas;

	const userInfo = useAppSelector((state) => state.login);
	const email = userInfo.permission?.email || "";
	const role = userInfo.permission?.role || "";

	if (Object.keys(results).length < 1) {
		for (const mdl of Object.values(allData.modules)) {
			results = { ...results, ...mdl.computedData };
		}
	}
	const {
		modules: {
			common: {
				inputData: { timeUnitDivider = 60 } = {},
			} = {},
		} = {},
		createdBy = "",
	} = allData;
	const timeUnit = timeUnitDivider === 1 ? "minute" : "hour";
	const displayTimeUnit = `${timeUnit}s`;

	useEffect(() => {
		const asyncFunc = async () => {
			const data = await getSubmitData({ isDraft: false });
			await dispatch(updateInformationData(data));
		};
		if (!sdcaId) {
			asyncFunc();
		}
	}, []);
	const commonData = allData.modules.common.inputData || {};

	// Operational Time/Cost Comparison
	// Current State Cost v.s. Harness
	const getOptionCostTotal = () => {
		const option = {
			xAxis: {
				type: "category",
				data: ["Current Status", "With Harness"],
			},
			yAxis: {
				type: "value",
				axisLabel: {
					formatter: currencyFormatter,
				},
			},
			tooltip: {
				trigger: "axis",
			},
			label: {
				show: true,
				position: "top",
			},
			grid: optionGridWoLegend,
			series: [
				{
					data: [
						commonData.totalCostOfCurrentProcessYear1,
						commonData.totalCostWithHarnessYear1,
					],
					type: "bar",
					label: {
						formatter: currencyFormatter,
					},
					color: BAR_COLORS[0],
				},
			],
		};

		return option;
	};

	// Operational Time/Cost Comparison
	// Current State Effort (hours) v.s. Harness
	const getOptionEffortTotal = () => {
		const option = {
			xAxis: {
				type: "category",
				data: ["Current Status", "With Harness"],
			},
			yAxis: {
				type: "value",
				axisLabel: {
					formatter: numberFormatter,
				},
			},
			tooltip: {
				trigger: "axis",
			},
			label: {
				show: true,
				position: "top",
			},
			grid: optionGridWoLegend,
			series: [
				{
					data: [
						commonData.totalTimeEffortOfCurrentProcessYear1,
						commonData.totalTimeEffortWithHarnessYear1,
					],
					type: "bar",
					label: {
						formatter: numberFormatter,
					},
					color: BAR_COLORS[0],
				},
			],
		};

		return option;
	};

	// Operational Time/Cost Comparison 3 Years
	// Current State Cost v.s. Harness
	const getOptionCostTotal3Years = () => {
		const option = {
			title: {},
			tooltip: {
				trigger: "axis",
			},
			legend: {
				data: ["Current State", "With Harness"],
				...optionLegend,
			},
			grid: optionGrid,
			xAxis: [
				{
					type: "category",
					data: ["Year1", "Year2", "Year3"],
				},
			],
			yAxis: [
				{
					type: "value",
					axisLabel: {
						formatter: currencyFormatter,
					},
				},
			],
			label: {
				show: true,
				position: "top",
			},
			series: [
				{
					name: "Current State",
					type: "bar",
					data: [
						commonData.totalCostOfCurrentProcessYear1,
						commonData.totalCostOfCurrentProcessYear2,
						commonData.totalCostOfCurrentProcessYear3,
					],
					label: {
						formatter: currencyFormatter,
					},
					color: BAR_COLORS[0],
				},
				{
					name: "With Harness",
					type: "bar",
					data: [
						commonData.totalCostWithHarnessYear1,
						commonData.totalCostWithHarnessYear2,
						commonData.totalCostWithHarnessYear3,
					],
					label: {
						formatter: currencyFormatter,
					},
					color: BAR_COLORS[2],
				},
			],
		};
		return option;
	};

	// Operational Time/Cost Comparison 3 Years
	// Current State Effort (hours) v.s. Harness
	const getOptionEffortTotal3Years = () => {
		const option = {
			title: {},
			tooltip: {
				trigger: "axis",
			},
			legend: {
				data: ["Current State", "With Harness"],
				...optionLegend,
			},
			grid: optionGrid,
			xAxis: [
				{
					type: "category",
					data: ["Year1", "Year2", "Year3"],
				},
			],
			yAxis: [
				{
					type: "value",
					axisLabel: {
						formatter: numberFormatter,
					},
				},
			],
			label: {
				show: true,
				position: "top",
				formatter: numberFormatter,
			},
			series: [
				{
					name: "Current State",
					type: "bar",
					data: [
						commonData.totalTimeEffortOfCurrentProcessYear1,
						commonData.totalTimeEffortOfCurrentProcessYear2,
						commonData.totalTimeEffortOfCurrentProcessYear3,
					],
					color: BAR_COLORS[0],
				},
				{
					name: "With Harness",
					type: "bar",
					data: [
						commonData.totalTimeEffortWithHarnessYear1,
						commonData.totalTimeEffortWithHarnessYear2,
						commonData.totalTimeEffortWithHarnessYear3,
					],
					color: BAR_COLORS[2],
				},
			],
		};
		return option;
	};

	const getOptionStackedTime = (mdl) => {
		const moduleFormulas = formulas?.data[mdl]?.formulas || {};
		const annualTimeEffort = moduleFormulas["Annual Time Effort"];
		const annualTimeEffortWithHarness =
			moduleFormulas["Annual Time Effort with Harness"];
		if (!annualTimeEffort || !annualTimeEffortWithHarness) {
			return {};
		}
		const annualTimeEffortLength = annualTimeEffort.length;
		const moduleSummaryData = allData.modules[mdl].inputData || {};
		const { customCategoriesTimeEffort = [] } = moduleSummaryData;
		const option = {
			tooltip: {
				trigger: "axis",
			},
			legend: optionLegend,
			grid: optionGrid,

			xAxis: [
				{
					type: "category",
					data: ["Without Harness", "With Harness"],
				},
			],
			yAxis: [
				{
					type: "value",
					axisLabel: {
						formatter: numberFormatter,
					},
				},
			],
			series:
				annualTimeEffort && annualTimeEffortWithHarness
					? [
							...annualTimeEffort
								.filter(
									(activity) => !regCustomCategories.test(activity.Section),
								)
								.map((activity, idx) => ({
									name: activity.Section,
									type: "bar",
									stack: "cost",
									emphasis: {
										focus: "series",
									},
									data: [
										results[getLHSVariable(activity.Formula)],
										results[
											getLHSVariable(annualTimeEffortWithHarness[idx].Formula)
										],
									],
									color: BAR_COLORS[idx],
								})),
							...customCategoriesTimeEffort.map((activity, idx) => ({
								name: activity.categoryName,
								type: "bar",
								stack: "cost",
								emphasis: {
									focus: "series",
								},
								data: [activity.value, activity.withHarnessValue],
								color: BAR_COLORS[annualTimeEffortLength + idx],
							})),
						]
					: [
							{
								name: "No activities",
								type: "bar",
								stack: "cost",
								emphasis: {
									focus: "series",
								},
								data: [0, 0],
								color: BAR_COLORS[0],
							},
						],
		};

		return option;
	};

	// Operational Time/Cost Comparison (by Module)
	// Operational Cost Comparison
	const getOptionStackedCost = (mdl) => {
		const moduleFormulas = formulas?.data[mdl]?.formulas || {};
		const annualCosts = moduleFormulas["Annual Costs"];
		const annualCostsWithHarness = moduleFormulas["Annual Costs with Harness"];

		if (!annualCosts || !annualCostsWithHarness) {
			return {};
		}
		const annualCostsLength = annualCosts.length;
		const moduleSummaryData = allData.modules[mdl].inputData || {};
		const { customCategoriesCost = [] } = moduleSummaryData;

		const option = {
			tooltip: {
				trigger: "axis",
			},
			legend: optionLegend,
			grid: optionGrid,

			xAxis: [
				{
					type: "category",
					data: ["Without Harness", "With Harness"],
				},
			],
			yAxis: [
				{
					type: "value",
					axisLabel: {
						formatter: currencyFormatter,
					},
				},
			],
			series:
				annualCosts && annualCostsWithHarness
					? [
							...annualCosts
								.filter(
									(activity) => !regCustomCategories.test(activity.Section),
								)
								.map((activity, idx) => ({
									name: activity.Section,
									type: "bar",
									stack: "cost",
									emphasis: {
										focus: "series",
									},
									data: [
										results[getLHSVariable(activity.Formula)],
										results[
											getLHSVariable(annualCostsWithHarness[idx].Formula)
										],
									],
									color: BAR_COLORS[idx],
								})),
							...customCategoriesCost.map((activity, idx) => ({
								name: activity.categoryName,
								type: "bar",
								stack: "cost",
								emphasis: {
									focus: "series",
								},
								data: [activity.value, activity.withHarnessValue],
								color: BAR_COLORS[annualCostsLength + idx],
							})),
						]
					: [
							{
								name: "No activities",
								type: "bar",
								stack: "cost",
								emphasis: {
									focus: "series",
								},
								data: [0, 0],
								color: BAR_COLORS[0],
							},
						],
		};

		return option;
	};

	const columnsCost = [
		{
			title: "",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Year 1",
			dataIndex: "year1",
			key: "year1",
		},
		{
			title: "Year 2",
			dataIndex: "year2",
			key: "year2",
		},
		{
			title: "Year 3",
			key: "year3",
			dataIndex: "year3",
		},
	];

	// Operational Time/Cost Comparison (by Module)
	// Operational Effort (hours) Comparison
	const getTableDataTimeEffort = (mdl) => {
		const mdlUpperCase = mdl.toUpperCase();
		const data = [
			{
				key: "1",
				name: "Number of App Projected to Use Harness",
				year1: numberFormatter(results.numberOfServicesApplicationsYear1),
				year2: numberFormatter(results.numberOfServicesApplicationsYear2),
				year3: numberFormatter(results.numberOfServicesApplicationsYear3),
			},
			{
				key: "2",
				name: `Current State Time Effort (${displayTimeUnit})`,
				year1: numberFormatter(
					results[`timeEffortOfCurrentProcessYear1${mdlUpperCase}`],
				),
				year2: numberFormatter(
					results[`timeEffortOfCurrentProcessYear2${mdlUpperCase}`],
				),
				year3: numberFormatter(
					results[`timeEffortOfCurrentProcessYear3${mdlUpperCase}`],
				),
			},
			{
				key: "3",
				name: `With Harness Time Effort (${displayTimeUnit})`,
				year1: numberFormatter(
					results[`timeEffortWithHarnessYear1${mdlUpperCase}`],
				),
				year2: numberFormatter(
					results[`timeEffortWithHarnessYear2${mdlUpperCase}`],
				),
				year3: numberFormatter(
					results[`timeEffortWithHarnessYear3${mdlUpperCase}`],
				),
			},
		];
		return data;
	};

	const getTableDataCost = (mdl) => {
		const mdlUpperCase = mdl.toUpperCase();
		const data = [
			{
				key: "1",
				name: "Number of App Projected to Use Harness",
				year1: numberFormatter(results.numberOfServicesApplicationsYear1),
				year2: numberFormatter(results.numberOfServicesApplicationsYear2),
				year3: numberFormatter(results.numberOfServicesApplicationsYear3),
			},
			{
				key: "2",
				name: "Current State Costs",
				year1: currencyFormatter(
					results[`costOfCurrentProcessYear1${mdlUpperCase}`],
				),
				year2: currencyFormatter(
					results[`costOfCurrentProcessYear2${mdlUpperCase}`],
				),
				year3: currencyFormatter(
					results[`costOfCurrentProcessYear3${mdlUpperCase}`],
				),
			},
			{
				key: "3",
				name: "With Harness Costs (less License)",
				year1: currencyFormatter(
					results[`totalCostWithHarnessLessLicenseYear1${mdlUpperCase}`],
				),
				year2: currencyFormatter(
					results[`totalCostWithHarnessLessLicenseYear2${mdlUpperCase}`],
				),
				year3: currencyFormatter(
					results[`totalCostWithHarnessLessLicenseYear3${mdlUpperCase}`],
				),
			},
		];
		return data;
	};

	const columnsCumulative = [
		{
			title: "",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Year 1 Cumulative",
			dataIndex: "year1",
			key: "year1",
		},
		{
			title: "Year 2 Cumulative",
			dataIndex: "year2",
			key: "year2",
		},
		{
			title: "Year 3 Cumulative",
			key: "year3",
			dataIndex: "year3",
		},
	];
	const getTableDataCumuTimeEffort = (mdl = "") => {
		const mdlUpperCase = mdl.toUpperCase();
		const data = [
			{
				key: "1",
				name: `Current State Time Effort (${displayTimeUnit})`,
				year1: numberFormatter(
					results[`timeEffortOfCurrentProcessCumuYear1${mdlUpperCase}`],
				),
				year2: numberFormatter(
					results[`timeEffortOfCurrentProcessCumuYear2${mdlUpperCase}`],
				),
				year3: numberFormatter(
					results[`timeEffortOfCurrentProcessCumuYear3${mdlUpperCase}`],
				),
			},
			{
				key: "2",
				name: `Total Time Effort with Harness (${displayTimeUnit})`,
				year1: numberFormatter(
					results[`timeEffortWithHarnessCumuYear1${mdlUpperCase}`],
				),
				year2: numberFormatter(
					results[`timeEffortWithHarnessCumuYear2${mdlUpperCase}`],
				),
				year3: numberFormatter(
					results[`timeEffortWithHarnessCumuYear3${mdlUpperCase}`],
				),
			},
			{
				key: "3",
				name: `Time Effort Saved with Harness (${displayTimeUnit})`,
				year1: numberFormatter(
					results[`timeEffortSavedWithHarnessCumuYear1${mdlUpperCase}`],
				),
				year2: numberFormatter(
					results[`timeEffortSavedWithHarnessCumuYear2${mdlUpperCase}`],
				),
				year3: numberFormatter(
					results[`timeEffortSavedWithHarnessCumuYear3${mdlUpperCase}`],
				),
			},
			{
				key: "4",
				name: "Total Time Effort Reduction (%)",
				year1: `${numberFormatter(
					results[`percentTotalTimeEffortReductionYear1${mdlUpperCase}`],
				)}%`,
				year2: `${numberFormatter(
					results[`percentTotalTimeEffortReductionYear2${mdlUpperCase}`],
				)}%`,
				year3: `${numberFormatter(
					results[`percentTotalTimeEffortReductionYear3${mdlUpperCase}`],
				)}%`,
			},
		];
		return data;
	};

	const getTableDataCumuCost = (mdl = "") => {
		const moduleData = allData.modules[mdl]?.inputData || {};
		const mdlUpperCase = mdl.toUpperCase();
		const data = [
			{
				key: "1",
				name: "Harness License",
				year1: currencyFormatter(
					results[`costOfHarnessCumuYear1${mdlUpperCase}`] ||
						moduleData[`costOfHarnessCumuYear1${mdlUpperCase}`],
				),
				year2: currencyFormatter(
					results[`costOfHarnessCumuYear2${mdlUpperCase}`] ||
						moduleData[`costOfHarnessCumuYear2${mdlUpperCase}`],
				),
				year3: currencyFormatter(
					results[`costOfHarnessCumuYear3${mdlUpperCase}`] ||
						moduleData[`costOfHarnessCumuYear3${mdlUpperCase}`],
				),
			},
			{
				key: "2",
				name: "Total Cost with Harness",
				year1: currencyFormatter(
					results[`costWithHarnessCumuYear1${mdlUpperCase}`],
				),
				year2: currencyFormatter(
					results[`costWithHarnessCumuYear2${mdlUpperCase}`],
				),
				year3: currencyFormatter(
					results[`costWithHarnessCumuYear3${mdlUpperCase}`],
				),
			},
			{
				key: "3",
				name: "Savings with Harness",
				year1: currencyFormatter(
					results[`savingsWithHarnessCumuYear1${mdlUpperCase}`],
				),
				year2: currencyFormatter(
					results[`savingsWithHarnessCumuYear2${mdlUpperCase}`],
				),
				year3: currencyFormatter(
					results[`savingsWithHarnessCumuYear3${mdlUpperCase}`],
				),
			},
			{
				key: "4",
				name: "Total Cost Reduction (%)",
				year1: `${numberFormatter(
					results[`percentTotalCostReductionYear1${mdlUpperCase}`],
				)}%`,
				year2: `${numberFormatter(
					results[`percentTotalCostReductionYear2${mdlUpperCase}`],
				)}%`,
				year3: `${numberFormatter(
					results[`percentTotalCostReductionYear3${mdlUpperCase}`],
				)}%`,
			},
		];
		return data;
	};

	const editSDCA = () => {
		if (!allData) {
			return;
		}
		history.push("/sdca");
		handleOnClickStep(0);
	};

	const isOwnerOrAdmin =
		role === ADMIN_ROLE || email.toLowerCase() === createdBy.toLowerCase();
	const hasAccessToEdit =
		isOwnerOrAdmin && EDIT_PERMISSION_ROLES.includes(role);

	return (
		<>
			<div className="step5FormWrapper">
				<div className={style.moduleChartContainer}>
					<div className={style.summaryDesc}>
						{sdcaId
							? `Created by: ${createdBy}`
							: "Please review the summary before submission"}
					</div>
					<h2 className={style.chartSectionTitle}>
						<ReactSVG src={`${PUBLIC_URL}/assets/icon_logoOnlyImage.svg`} />
						Total Business Impact
					</h2>
					<h3>Operational Time/Cost Comparison</h3>
					<div className={style.col2Container}>
						<div>
							<h4>Current State Effort ({displayTimeUnit}) v.s. Harness</h4>
							<div className={style.chartCard}>
								<ReactECharts option={getOptionEffortTotal()} />
								<div className={style.perctImprovement}>
									Estimated {commonData.perctTimeImprovement}% Time Improvement
									With Harness
								</div>
							</div>
						</div>
						<div>
							<h4>Current State Cost v.s. Harness</h4>
							<div className={style.chartCard}>
								<ReactECharts option={getOptionCostTotal()} />
								<div className={style.perctImprovement}>
									Estimated {commonData.perctCostImprovement}% Cost Improvement
									With Harness
								</div>
							</div>
						</div>
					</div>
					<h3>Operational Time/Cost Comparison 3 Years</h3>
					<div className={style.col2Container}>
						<div>
							<h4>Current State Effort ({displayTimeUnit}) v.s. Harness</h4>
							<div className={style.chartCard}>
								<ReactECharts option={getOptionEffortTotal3Years()} />
							</div>
						</div>
						<div>
							<h4>Current State Cost v.s. Harness</h4>
							<div className={style.chartCard}>
								<ReactECharts option={getOptionCostTotal3Years()} />
							</div>
						</div>
					</div>
				</div>

				{selectedModuleOrder.map((currenModule) => (
					<div
						key={currenModule}
						className={
							style[`moduleChartContainer${currenModule.toUpperCase()}`]
						}
					>
						<h2 className={style.chartSectionTitle}>
							<ReactSVG
								src={`${PUBLIC_URL}/assets/icon_${currenModule.toLowerCase()}.svg`}
								width={28}
								height={28}
							/>
							{MODULE_NAMES[currenModule.toLowerCase()]}
						</h2>
						<h3>
							Operational Time/Cost Comparison ({currenModule.toUpperCase()})
						</h3>
						<div className={style.col2Container}>
							<div>
								<h4>Operational Effort ({displayTimeUnit}) Comparison</h4>
								<div className={style.chartCard}>
									<ReactECharts option={getOptionStackedTime(currenModule)} />
								</div>
							</div>
							<div>
								<h4>Operational Cost Comparison</h4>
								<div className={style.chartCard}>
									<ReactECharts option={getOptionStackedCost(currenModule)} />
								</div>
							</div>
						</div>
						<h3>
							Time Effort Across Y1, Y2, Y3, Scaled by # of Apps (
							{currenModule.toUpperCase()})
						</h3>
						<div className={style.chartCard}>
							<Table
								columns={columnsCost}
								dataSource={getTableDataTimeEffort(currenModule)}
								pagination={false}
							/>
						</div>
						<h3>
							Cumulative Time Effort Across 3 Years (
							{currenModule.toUpperCase()})
						</h3>
						<div className={style.chartCard}>
							<Table
								columns={columnsCumulative}
								dataSource={getTableDataCumuTimeEffort(currenModule)}
								pagination={false}
							/>
						</div>

						<h3>
							Cost Across Y1, Y2, Y3, Scaled by # of Apps (
							{currenModule.toUpperCase()})
						</h3>
						<div className={style.chartCard}>
							<Table
								columns={columnsCost}
								dataSource={getTableDataCost(currenModule)}
								pagination={false}
							/>
						</div>
						<h3>
							Cumulative Cost/Savings/ROI Across 3 Years (
							{currenModule.toUpperCase()})
						</h3>
						<div className={style.chartCard}>
							<Table
								columns={columnsCumulative}
								dataSource={getTableDataCumuCost(currenModule)}
								pagination={false}
							/>
						</div>
					</div>
				))}

				<div className="btns">
					{sdcaId ? (
						<Tooltip
							title={
								!hasAccessToEdit &&
								"Only owner (with edit access) and admin are able to edit the BVA"
							}
						>
							<Button
								type="button"
								onClick={editSDCA}
								loading={loading}
								disabled={!hasAccessToEdit}
							>
								Edit BVA
							</Button>
						</Tooltip>
					) : (
						<>
							<Button
								type="button"
								onClick={() => onClickInternalStep(["back", null])}
								loading={loading}
							>
								❮ Back
							</Button>
							<Button
								type="primary"
								onClick={() => onClickInternalStep(["next", null])}
								loading={loading}
							>
								Submit
							</Button>
						</>
					)}
				</div>
			</div>
		</>
	);
};
const Step5ModulePage = React.memo(Step5Module);
export { Step5ModulePage };
