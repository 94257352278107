import React, { useState, useEffect } from "react";

import step3Style from "./Calculation.module.scss";
import "./form3Style.scss";
import { fetchBatchUiData } from "../../../actions";
import { setAuthToken } from "../../../auth";
import { Loading, TabMenu } from "../../../components";
import { getDefaultValue as getDefaultValueCustomCategories } from "../../../components/CustomCategories";
import { getDefaultValue as getDefaultValueFTEEnum } from "../../../components/FTEEnum";
import { getDefaultValue as getDefaultValueInputFrequency } from "../../../components/InputFrequency";
import { getDefaultValue as getDefaultValueIntegerStepCustomCategories } from "../../../components/IntegerStepCustomCategories";
import { setLocallyStoredBVA } from "../../../components/LocallyStoredBVA";
import { COMMON_KEY } from "../../../constants/modules";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { sortModules } from "../../../utils";
import { Step3ModulePage } from "./step3Forms";

const regExpIsNumber = /^-?\d+\.?\d*$/;

const Step3Page = (props) => {
	const {
		handleOnClickStep,
		handleOnUpdate,
		handleSaveDraft,
		selectedModuleData,
		allData,
	} = props;
	const dispatch = useAppDispatch();
	const resUI = useAppSelector((state) => state.ui);
	const { moduleOrder = [] } = useAppSelector((state) => state.module);
	const ui = resUI?.data || {};

	const getDefultValueByType = (type, defaultValue) => {
		switch (type) {
			case "FTEEnum":
				return getDefaultValueFTEEnum(defaultValue);
			case "InputFrequency":
				return getDefaultValueInputFrequency(defaultValue);
			case "CustomCategories":
				return getDefaultValueCustomCategories(defaultValue);
			case "IntegerStepCustomCategories":
				return getDefaultValueIntegerStepCustomCategories(defaultValue);
			default:
				return 0;
		}
	};
	const getDefaultValues = (uiData) => {
		const defaultValueModules = {};
		const keys = Object.keys(uiData);
		for (const key of keys) {
			const uiLayout = uiData[key].layout;
			defaultValueModules[key] = {};
			defaultValueModules[key].inputData = {};

			const sections = Object.keys(uiLayout);
			for (const section of sections) {
				for (const field of uiLayout[section]) {
					const { DataType, VariableName, DefaultValue } = field;
					if (regExpIsNumber.test(DefaultValue)) {
						defaultValueModules[key].inputData[VariableName] = Number.parseInt(
							DefaultValue,
							10,
						);
					} else {
						defaultValueModules[key].inputData[VariableName] =
							getDefultValueByType(DataType, DefaultValue);
					}
				}
			}
		}
		return defaultValueModules;
	};
	const mergeModulesData = (defaultModules, modules) => {
		const mergedModules = {};
		const defaultMdlKeys = Object.keys(defaultModules);
		const Mdlkeys = Object.keys(modules);
		const keys =
			defaultMdlKeys.length >= Mdlkeys.length ? defaultMdlKeys : Mdlkeys;
		for (const key of keys) {
			mergedModules[key] = { inputData: {} };
			mergedModules[key].inputData = {
				...(defaultModules[key]?.inputData || {}),
				...(modules[key]?.inputData || {}),
			};
		}
		return mergedModules;
	};

	const unsortedSelectedModuleOrderWoCommon = Object.entries(selectedModuleData)
		.filter((mdl) => mdl[1] && mdl[0] !== COMMON_KEY)
		.map((mdl) => mdl[0]);
	const selectedModuleOrderWoCommon = sortModules(
		unsortedSelectedModuleOrderWoCommon,
		moduleOrder,
	);
	const selectedModuleOrder = [COMMON_KEY, ...selectedModuleOrderWoCommon];

	const [selectTab, setSelectTab] = useState(selectedModuleOrder[0]);
	useEffect(() => {
		dispatch(fetchBatchUiData(selectedModuleOrder));
	}, []);
	useEffect(() => {
		if (!resUI.loading && !resUI.error) {
			const { draftId, sdcaId, duplicatedFrom, fromRestore } = allData || {};
			const defaultData = getDefaultValues(ui);

			if (draftId || sdcaId || duplicatedFrom || fromRestore) {
				const allDataModules = allData.modules;
				const mergedData = mergeModulesData(defaultData, allDataModules);
				handleOnUpdate({ modules: mergedData });
			} else {
				handleOnUpdate({ modules: defaultData });
			}
		}
	}, [resUI]);

	const onClickTabMenu = (label) => {
		setSelectTab(label);
	};

	const changeSubStep = async (label) => {
		onClickTabMenu(label);
		await setAuthToken();
		await handleSaveDraft();
	};

	const onClickInternalStep = async (arr) => {
		const [buttonName, currentStepName] = arr;

		const moduleLength = selectedModuleOrder.length;

		const index = selectedModuleOrder.indexOf(currentStepName);

		await setAuthToken();

		const elmMain = document.querySelector(".main");
		if (index > -1 && buttonName === "next") {
			if (index === moduleLength - 1) {
				handleOnClickStep(3);
			} else if (index < moduleLength - 1) {
				onClickTabMenu(selectedModuleOrder[index + 1]);
				elmMain.scrollTo(0, 0);
			}
		}
		if (index > -1 && buttonName === "back") {
			if (index === 0) {
				handleOnClickStep(1);
			} else if (index < moduleLength) {
				onClickTabMenu(selectedModuleOrder[index - 1]);
				elmMain.scrollTo(0, 0);
			}
		}

		await handleSaveDraft();

		setLocallyStoredBVA(allData);
	};

	const useFixedOnScroll = () => {
		const [isFixed, setIsFixed] = useState(false);

		useEffect(() => {
			const onScroll = () => {
				setIsFixed(() => {
					const scrolledDown = window.scrollY > 120;

					return scrolledDown;
				});
			};

			window.addEventListener("scroll", onScroll);
			return () => {
				window.removeEventListener("scroll", onScroll);
			};
		}, [isFixed]);

		return isFixed;
	};

	return resUI.loading ? (
		<div className={step3Style.loadingDiv}>
			<Loading />
		</div>
	) : (
		<div className={step3Style.step3form}>
			<div className={step3Style.step3formInner}>
				<TabMenu
					selectedModuleData={selectedModuleOrder}
					onClick={changeSubStep}
					activeTab={selectTab}
				>
					{selectedModuleOrder.includes(selectTab) && (
						<Step3ModulePage
							useFixedOnScroll={useFixedOnScroll}
							onClickInternalStep={onClickInternalStep}
							handleOnUpdate={handleOnUpdate}
							allData={allData}
							currenModule={selectTab}
						/>
					)}
				</TabMenu>
			</div>
		</div>
	);
};

export { Step3Page };
