import { ChevronDownIcon } from "@radix-ui/react-icons";
import { InputNumber, Select } from "antd";
import React, { useState, useEffect } from "react";
import { numberFormatter, numberParser } from "../../utils";
import styles from "./InputTime.module.scss";

const { Option } = Select;

const inMinutes = {
	minutes: 1,
	hours: 60,
	days: 480, // 8 * 60
	weeks: 2400, // 5 * 8 * 60
	months: 10320, // 21.5 * 8 * 60
	years: 124800, // 52 * 5 * 8 * 60
};

const convertInputTimeInitialValue = (initialValue) => {
	let objInitialValue = initialValue || {
		initialValue: 1,
		initialUnit: "minutes",
	};
	if (initialValue && typeof initialValue === "string") {
		objInitialValue = JSON.parse(initialValue);
	}
	return objInitialValue;
};

function InputTime({ value = {}, onChange, initialValue }) {
	const [number, setNumber] = useState(
		initialValue?.originalValue || value?.originalValue || 1,
	);
	const [unit, setUnit] = useState(
		initialValue?.originalUnit || value?.originalUnit || "minutes",
	);

	const triggerChange = (changedValue) => {
		const newValue = {
			originalValue: number,
			originalUnit: unit,
			...value,
			...changedValue,
		};
		newValue.value = newValue.originalValue * inMinutes[newValue.originalUnit];
		onChange(newValue);
	};
	useEffect(() => {
		triggerChange({});
	}, []);

	const onNumberChange = (newNumber) => {
		if (Number.isNaN(newNumber)) {
			return;
		}
		setNumber(newNumber);

		triggerChange({
			originalValue: newNumber,
		});
	};

	const onUnitChange = (newUnit) => {
		setUnit(newUnit);

		triggerChange({
			originalUnit: newUnit,
		});
	};

	return (
		<span className={styles.inputTime}>
			<InputNumber
				value={value.originalValue || number}
				formatter={numberFormatter}
				parser={numberParser}
				onChange={onNumberChange}
				min="0"
				className={styles.number}
			/>
			<Select
				value={value.originalUnit || unit}
				className={styles.timeUnit}
				onChange={onUnitChange}
				suffixIcon={<ChevronDownIcon />}
			>
				<Option value="minutes">Minutes</Option>
				<Option value="hours">Hours</Option>
				<Option value="days">Days</Option>
				<Option value="weeks">Weeks</Option>
				<Option value="months">Months</Option>
				<Option value="years">Years</Option>
			</Select>
		</span>
	);
}

export default InputTime;
export { convertInputTimeInitialValue };
