import {
	RECEIVE_GET_USER_BY_EMAIL,
	RECEIVE_GET_USER_BY_EMAIL_ERROR,
	REQUEST_GET_USER_BY_EMAIL,
} from "@/core/constants";
import type {
	LoginActions,
	LoginReducer,
} from "@/core/reducers/loginReducer/types";

const initState: LoginReducer = {
	loading: false,
	error: false,
	permission: {},
};

const loginReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: LoginActions,
): LoginReducer => {
	switch (action.type) {
		case REQUEST_GET_USER_BY_EMAIL:
			return { ...state, loading: true, error: false };
		case RECEIVE_GET_USER_BY_EMAIL: {
			return {
				...state,
				loading: false,
				permission: action.payload,
				error: false,
			};
		}
		case RECEIVE_GET_USER_BY_EMAIL_ERROR:
			return {
				...state,
				loading: false,
				permission: {},
				error: true,
			};
		default:
			return state;
	}
};
export default loginReducer;
