export default {
  "filters": "_filters_1samd_1",
  "loading": "_loading_1samd_16",
  "reportingContainer": "_reportingContainer_1samd_20",
  "moduleIcon": "_moduleIcon_1samd_26",
  "total": "_total_1samd_42",
  "ci": "_ci_1samd_61",
  "tableRow": "_tableRow_1samd_69",
  "cd": "_cd_1samd_72",
  "ff": "_ff_1samd_83",
  "cc": "_cc_1samd_94",
  "ccm": "_ccm_1samd_96",
  "sto": "_sto_1samd_109",
  "srm": "_srm_1samd_120",
  "ce": "_ce_1samd_131",
  "cet": "_cet_1samd_142",
  "sei": "_sei_1samd_153",
  "moduleCharts": "_moduleCharts_1samd_164"
};import "ni:sha-256;NNlkdKTTKGH3xtdVsdpmSkRcaOAPtiKMY2bujFouGcQ";