import { PUBLIC_URL } from "@/core/constants";
import {
	MODULE_NAMES,
	type SupportedModuleKeys,
} from "@/core/constants/modules";
import { Tooltip } from "antd";
import { type ReactNode, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import TabMenusStyle from "./index.module.scss";

function TabMenu(props: {
	selectedModuleData: SupportedModuleKeys[];
	children: ReactNode;
	onClick: (_: SupportedModuleKeys) => void;
	activeTab: SupportedModuleKeys;
}) {
	const { selectedModuleData, children, onClick, activeTab } = props;
	const ulEl = useRef<HTMLUListElement>(null);

	useEffect(() => {
		const elemUl = ulEl?.current;
		const elemMain = document.querySelector(".main");
		const elemStep3FormWrapper = document.querySelector(".main .pannel");
		if (!elemUl || !elemMain || !elemStep3FormWrapper) {
			return undefined;
		}
		const menuOffsetTop = elemUl.offsetTop || 0;

		const handleScroll = () => {
			const mainScrollTop = elemMain.scrollTop;
			if (mainScrollTop >= menuOffsetTop) {
				elemUl.classList.add(TabMenusStyle.sticky);
				elemStep3FormWrapper.classList.add(TabMenusStyle.afterSticky);
			} else {
				elemUl.classList.remove(TabMenusStyle.sticky);
				elemStep3FormWrapper.classList.remove(TabMenusStyle.afterSticky);
			}
		};

		elemMain.addEventListener("scroll", handleScroll);

		return () => {
			elemMain.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<ul className={TabMenusStyle.menu} ref={ulEl}>
				{selectedModuleData.map((item) => (
					<li
						onClick={() => {
							onClick(item);
						}}
						onKeyDown={() => {
							onClick(item);
						}}
						className={
							activeTab === item
								? `${TabMenusStyle.active} ${TabMenusStyle[item]}`
								: `${TabMenusStyle[item]}`
						}
						key={item}
					>
						<Tooltip title={MODULE_NAMES[item]}>
							<ReactSVG src={`${PUBLIC_URL}/assets/icon_${item}.svg`} />
						</Tooltip>
						<h4>{MODULE_NAMES[item]}</h4>
					</li>
				))}
			</ul>
			<div className="pannel">{children}</div>
		</>
	);
}

export { TabMenu };
