import React from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { sortModules } from "../../../utils";
import step5Style from "./Calculation.module.scss";
import { Step5ModulePage } from "./step5Forms";

const Step5Page = (props) => {
	const {
		handleOnClickStep,
		allData,
		handleCommitData,
		getSubmitData,
		selectedModuleData,
		sdcaId,
	} = props;

	const getAllData = useAppSelector((state) => state.calculation);
	const { moduleOrder = [] } = useAppSelector((state) => state.module);
	const currentStep = getAllData.step;

	const selectedModuleOrder = sortModules(
		Object.entries(selectedModuleData)
			.filter((mdl) => mdl[1] && mdl[0] !== "common")
			.map((mdl) => mdl[0]),
		moduleOrder,
	);

	const onClickInternalStep = (arr) => {
		const buttomName = arr[0];

		if (buttomName === "next") {
			handleOnClickStep(currentStep + 1);
			handleCommitData();
		}
		if (buttomName === "back") {
			handleOnClickStep(currentStep - 1);
		}
	};

	return (
		<div className={step5Style.step5form}>
			<Step5ModulePage
				allData={allData}
				onClickInternalStep={onClickInternalStep}
				handleOnClickStep={handleOnClickStep}
				selectedModuleOrder={selectedModuleOrder}
				sdcaId={sdcaId}
				getSubmitData={getSubmitData}
			/>
		</div>
	);
};

export { Step5Page };
