export default {
  "step3Result": "_step3Result_w093v_16",
  "isFixed": "_isFixed_w093v_28",
  "collapse": "_collapse_w093v_32",
  "resultTop": "_resultTop_w093v_35",
  "previewModuleTitle": "_previewModuleTitle_w093v_43",
  "previewItem": "_previewItem_w093v_62",
  "cd": "_cd_w093v_65",
  "ci": "_ci_w093v_68",
  "ff": "_ff_w093v_71",
  "minus": "_minus_w093v_99",
  "low": "_low_w093v_106",
  "sectionList": "_sectionList_w093v_113",
  "active": "_active_w093v_127",
  "activeItem": "_activeItem_w093v_134",
  "sectionResult": "_sectionResult_w093v_139",
  "heartbeat": "_heartbeat_w093v_1"
};import "ni:sha-256;7nnm8_q8FT4AnCguFqblwPxfYbUJrQAGfhYfYp61kcM";