import React, { useState, useEffect, useMemo } from "react";
import IntegerStep from "../IntegerStep";
import css from "./IntegerStepCustomCategories.module.scss";

import { useAppSelector } from "../../hooks/reduxHooks";
import { getPerctReductionValue } from "../../pages/Calculation/steps/utils";
import { round } from "../../utils";

const getMean = (originalValue) => {
	const sumPerctReduction = originalValue.reduce(
		(sum, red) => sum + red.value,
		0,
	);
	const perctReduction = Math.round(sumPerctReduction / originalValue.length);
	return perctReduction;
};
const convertIntegerStepCustomCategoriesInitialValue = (initialValue) => {
	const typeofInitialValue = typeof initialValue;
	if (typeofInitialValue === "string") {
		if (!Number.isNaN(Number.parseInt(initialValue, 10))) {
			return {
				originalValue: [],
				value: Number.parseInt(initialValue, 10),
			};
		}
		const originalValue = JSON.parse(initialValue);
		if (Array.isArray(originalValue)) {
			const perctReduction = getMean(originalValue);
			return {
				originalValue,
				value: perctReduction,
			};
		}
	} else if (typeofInitialValue === "object" && Array.isArray(initialValue)) {
		const perctReduction = getMean(initialValue);
		return {
			originalValue: [...initialValue],
			value: perctReduction,
		};
	}

	return {
		originalValue: [],
		value: 60,
	};
};
const getDefaultValue = (defaultValue) => {
	return convertIntegerStepCustomCategoriesInitialValue(defaultValue);
};

const IntegerStepCustomCategories = ({
	value = {},
	onChange,
	initialValue,
	updateFormData,
	currenModule,
}) => {
	const allModules = useAppSelector((state) => state.module);
	const allData = useAppSelector((state) => state.calculation);
	const defaultModule = allModules ? allModules.moduleOrder[0] : "ci";
	const module = currenModule || defaultModule;
	const moduleCustomCategories =
		allData.modules[module].inputData[`customCategories${module.toUpperCase()}`]
			.originalValue;
	const countFormatter = (arrCount = []) => {
		const formattedCount = moduleCustomCategories.map((cat) => {
			const foundItem = arrCount.find((cnt) => cnt.id === cat.id);
			if (foundItem) {
				return foundItem;
			}
			return {
				id: cat.id,
				name: cat.categoryName,
				value: initialValue?.value || 60,
			};
		});
		return formattedCount;
	};
	const initialCount = countFormatter();
	const defaultValue = useMemo(
		() =>
			countFormatter(
				(initialValue?.originalValue &&
					initialValue.originalValue.length > 0 &&
					initialValue.originalValue) ||
					(value?.originalValue &&
						value.originalValue.length > 0 &&
						value.originalValue) ||
					initialCount,
			),
		[initialValue, value, currenModule],
	);

	const updateChangeToField = (newCount) => {
		if (!newCount) {
			return;
		}

		const retVal = convertIntegerStepCustomCategoriesInitialValue(newCount);
		onChange(retVal);

		if (!allData.modules?.[currenModule]) {
			allData.modules[currenModule] = {};
		}
		const mdlData = allData.modules[currenModule].inputData || {};
		const commonData = allData.modules?.common.inputData;

		const cateData = mdlData[`customCategories${currenModule.toUpperCase()}`];
		const cateArray = cateData?.originalValue;
		const perctReductionData = retVal;

		const timeEffortCustomCategories = cateArray.filter(
			(cate) => cate.categoryType === "Annual Time Effort",
		);
		const {
			salaryEmployeeFTEFullyBurdened,
			workHoursPerYear,
			minutesPerHour,
			timeUnitDivider,
		} = commonData;
		const salaryFTEFullyBurnedPerMinute =
			salaryEmployeeFTEFullyBurdened / (workHoursPerYear * minutesPerHour);
		let timeEffortOfCustomCategories = 0;
		let timeEffortWithHarnessOfCustomCategories = 0;
		let annualCostOfCustomCategories = 0;
		let annualCostWithHarnessOfCustomCategories = 0;

		const customCategoriesTimeEffort = timeEffortCustomCategories.map(
			(cate) => {
				const perctReductionValue = getPerctReductionValue(
					perctReductionData,
					cate.id,
				);
				const cateTimeEffort =
					(cate.fteTime.value * cate.fteNumber) / timeUnitDivider;
				const cateTimeEffortWithHarness = round(
					cateTimeEffort * (1 - perctReductionValue / 100),
				);
				timeEffortOfCustomCategories += cateTimeEffort;
				timeEffortWithHarnessOfCustomCategories += cateTimeEffortWithHarness;
				return {
					id: cate.id,
					categoryName: cate.categoryName,
					value: cateTimeEffort,
					withHarnessValue: cateTimeEffortWithHarness,
				};
			},
		);
		const customCategoriesCost = cateArray.map((cate) => {
			const perctReductionValue = getPerctReductionValue(
				perctReductionData,
				cate.id,
			);
			if (cate.categoryType === "Annual Time Effort") {
				const calculatedCost = round(
					cate.fteTime.value * cate.fteNumber * salaryFTEFullyBurnedPerMinute,
				);
				const calculatedCostWithHarness = round(
					calculatedCost * (1 - perctReductionValue / 100),
				);
				annualCostOfCustomCategories += calculatedCost;
				annualCostWithHarnessOfCustomCategories += calculatedCostWithHarness;
				return {
					id: cate.id,
					categoryName: cate.categoryName,
					value: calculatedCost,
					withHarnessValue: calculatedCostWithHarness,
				};
			}
			const withHarnessValue = round(
				cate.cost * (1 - perctReductionValue / 100),
			);
			annualCostOfCustomCategories += cate.cost;
			annualCostWithHarnessOfCustomCategories += withHarnessValue;
			return {
				id: cate.id,
				categoryName: cate.categoryName,
				value: cate.cost,
				withHarnessValue,
			};
		});

		updateFormData({
			timeEffortOfCustomCategories,
			timeEffortWithHarnessOfCustomCategories,
			annualCostOfCustomCategories,
			annualCostWithHarnessOfCustomCategories,
			customCategoriesTimeEffort,
			customCategoriesCost,
		});
	};

	const [count, setCount] = useState(defaultValue);

	useEffect(() => {
		const newCount = countFormatter(
			(value?.originalValue &&
				value.originalValue.length > 0 &&
				value.originalValue) ||
				defaultValue,
		);
		setCount(newCount);
	}, [value, defaultValue, currenModule]);

	useEffect(() => {
		const newCount = defaultValue;
		updateChangeToField(newCount);
	}, []);

	const handleChange = (idx, id, newItemVal) => {
		if (!id || newItemVal === undefined || newItemVal === null) {
			return;
		}
		const newCount = [...count];
		const changedItem = newCount.find((cnt) => cnt.id === id);
		newCount.splice(idx, 1, { ...changedItem, value: newItemVal });
		setCount(newCount);

		updateChangeToField(newCount);
	};
	return moduleCustomCategories.length > 0 ? (
		<div className={css.categoryEnum}>
			{moduleCustomCategories.map((item, idx) => {
				const countId = item.id;
				return (
					<div className={css.categoryItem} key={countId}>
						<span className={css.label}>{item.categoryName}</span>

						<IntegerStep
							id={countId}
							value={count[idx]?.value || defaultValue}
							initialValue={defaultValue || 60}
							required
							onChange={(newVal) => handleChange(idx, countId, newVal)}
						/>
					</div>
				);
			})}
		</div>
	) : (
		<div>&lt;No Custom Categories&gt;</div>
	);
};

export default IntegerStepCustomCategories;
export { getDefaultValue, convertIntegerStepCustomCategoriesInitialValue };
