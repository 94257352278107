import { QuestionCircleOutlined } from "@ant-design/icons";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Checkbox, InputNumber, Select, Table, Tooltip } from "antd";
import ReactECharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
	fetchBatchFormulasData,
	fetchBatchUiData,
	fetchModuleData,
	fetchSDCAListsData,
} from "../../actions";
import { LeftNav, Loading } from "../../components";
import { ADMINS, ADMIN_ROLE, EDITOR_ROLE } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
	convertPercentageFormatter,
	currencyFormatter,
	currencyParser,
	getLHSVariable,
	intCurrencyFormatter,
	intNumberFormatter,
	intPercentageFormatter,
	shortenedCurrencyFormatter,
	shortenedNumberFormatter,
} from "../../utils";
import styles from "./Reporting.module.scss";

const regExpTest = /test/i;
const regExpCustomCategories = /CustomCategories|Custom Categories/i;

const columnsTotal = [
	{
		title: "Ave. Annual Time Effort of Current Process",
		dataIndex: "totalTimeEffortOfCurrentProcess",
		key: "totalTimeEffortOfCurrentProcess",
	},
	{
		title: "Ave. Annual Time Effort w/ Harness",
		dataIndex: "totalTimeEffortWithHarness",
		key: "totalTimeEffortWithHarness",
	},
	{
		title: "Ave. Annual Cost of Current Process",
		dataIndex: "totalCostOfCurrentProcess",
		key: "totalCostOfCurrentProcess",
	},
	{
		title: "Ave. Annual Cost w/ Harness",
		dataIndex: "totalCostWithHarness",
		key: "totalCostWithHarness",
	},
	{
		title: (
			<>
				Ave. Cost Improvement{" "}
				<Tooltip title="(Ave. Annual Cost of Current Process - Ave. Annual Cost with Harness) / Ave. Annual Cost of Current Process">
					<QuestionCircleOutlined />
				</Tooltip>
			</>
		),
		dataIndex: "totalCostImprovementPercentage",
		key: "totalCostImprovementPercentage",
	},
	{
		title: "Ave. FTE Resources",
		dataIndex: "fteResources",
		key: "fteResources",
	},
];

const columnsCategory = [
	{
		title: "Category",
		dataIndex: "category",
		key: "category",
	},
	{
		title: "Ave. Annual Time Effort",
		dataIndex: "timeEffort",
		key: "timeEffort",
	},
	{
		title: "Ave. Annual Cost",
		dataIndex: "cost",
		key: "cost",
	},
	{
		title: (
			<>
				Ave. Improvement Factor{" "}
				<Tooltip title="x̄(Improvement Factor of module)">
					<QuestionCircleOutlined />
				</Tooltip>
			</>
		),
		dataIndex: "improvement",
		key: "improvement",
	},
];

const DEFAULT_COST_CAP = 2000000;
const DEFAULT_COST_FLOOR = 50000;
const HOURS_PER_DAY = [24, 8, 6];
const DEFAULT_HOURS_PER_DAY = HOURS_PER_DAY[1];

const getDisplayName = (formulaName) => {
	if (formulaName) {
		const displayName = formulaName
			.replace(/^annualCost(Of|To)/, "")
			.replace(/(CI|CD|FF|CC|CCM|CE|STO|SRM|SEI|IDP|CET)$/, "")
			.replace(/([A-Z]+)/g, " $1");
		return displayName;
	}
	return "-";
};
const getCssVarValue = (cssVar) => {
	if (cssVar && getComputedStyle) {
		return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
	}
	return "#0278d5";
};

function Reporting() {
	const [costCap, setCostCap] = useState(DEFAULT_COST_CAP);
	const [costFloor, setCostFloor] = useState(DEFAULT_COST_FLOOR);
	const [formattedModuleData, setFormattedModuleData] = useState([]);
	const [sdcaData, setSdcaData] = useState([]);
	const [chartOptionModuleBVANumber, setChartOptionModuleBVANumber] = useState(
		{},
	);
	const [chartOptionModuleTimeEffect, setChartOptionModuleTimeEffect] =
		useState({});
	const [chartOptionModuleCost, setChartOptionModuleCost] = useState({});
	const [hoursPerDay, setHoursPerDay] = useState(DEFAULT_HOURS_PER_DAY);
	const TIME_UNIT = [
		{ label: "hours", value: 60 },
		{ label: "days", value: 60 * hoursPerDay },
	];
	const [dispTimeUnit, setDispTimeUnit] = useState(TIME_UNIT[0].label);
	const [dividerDispTimeUnit, setDividerDispTimeUnit] = useState(
		TIME_UNIT[0].value,
	);
	const [ignoreTestEntries, setIgnoreTestEntries] = useState(true);

	const dispatch = useAppDispatch();
	const homeData = useAppSelector((state) => state.home);
	const allModules = useAppSelector((state) => state.module);
	const rawFormulas = useAppSelector((state) => state.formula);
	const rawUI = useAppSelector((state) => state.ui);
	const userInfo = useAppSelector((state) => state.login);

	const { sdcaListsLoading } = homeData;
	const { loading: modulesLoading } = allModules;
	const { loading: formulasLoading } = rawFormulas;
	const { loading: uiLoading } = rawUI;
	const { loading: userLoading } = userInfo;
	const loading =
		sdcaListsLoading ||
		modulesLoading ||
		formulasLoading ||
		uiLoading ||
		userLoading;
	const {
		permission: { role = "", email: userEmail = "" } = {},
	} = userInfo;
	const { data: formulas = {} } = rawFormulas;
	const { data: ui = {} } = rawUI;

	const roleLowerCase = role.toLowerCase();
	const isAdmin =
		roleLowerCase === EDITOR_ROLE ||
		roleLowerCase === ADMIN_ROLE ||
		ADMINS.includes(userEmail);

	const { sdcaListsData = [] } = homeData;
	const { moduleOrder = [], modules: moduleNames } = allModules;

	const updateSDCAList = () => {
		if (sdcaListsData.length === 0) {
			dispatch(fetchSDCAListsData());
		}
		if (moduleOrder.length === 0) {
			dispatch(fetchModuleData());
		}
	};
	useEffect(() => {
		updateSDCAList();
	}, []);
	useEffect(() => {
		if (moduleOrder.length > 0) {
			if (Object.keys(ui).length < moduleOrder.length) {
				dispatch(fetchBatchUiData(moduleOrder));
			}
			if (Object.keys(formulas).length < moduleOrder.length) {
				dispatch(fetchBatchFormulasData(moduleOrder));
			}
		}
	}, [moduleOrder]);
	useEffect(() => {
		if (
			sdcaListsData.length === 0 ||
			moduleOrder.length === 0 ||
			Object.keys(ui).length === 0 ||
			Object.keys(formulas).length === 0
		) {
			return;
		}
		if (
			Object.keys(ui).length < moduleOrder.length ||
			Object.keys(formulas).length < moduleOrder.length
		) {
			return;
		}

		const pStatistics = {};
		const filteredSdcaList = ignoreTestEntries
			? sdcaListsData.filter(
					(bva) =>
						!regExpTest.test(bva.nameOfSoftwareDeliveryCapabilityAssessment) &&
						!regExpTest.test(bva.description || ""),
				)
			: sdcaListsData;
		setSdcaData(filteredSdcaList);

		for (const bva of filteredSdcaList) {
			const { modules } = bva;
			const {
				common: {
					inputData: { numberOfServicesApplications, timeUnitDivider = 1 },
				},
			} = modules;
			for (const mdl of moduleOrder) {
				const moduleData = modules[mdl];
				if (moduleData) {
					const { inputData, computedData } = moduleData;
					const costOfCurrentProcess =
						computedData[`costOfCurrentProcessYear1${mdl.toUpperCase()}`] ||
						computedData[`costOfCurrentProcessCumuYear1${mdl.toUpperCase()}`];
					const isValidModuleData =
						costOfCurrentProcess &&
						costOfCurrentProcess <= costCap &&
						costOfCurrentProcess > costFloor;
					if (!isValidModuleData) {
					}
					if (isValidModuleData) {
						inputData.numberOfServicesApplications =
							numberOfServicesApplications;
						inputData.timeUnitDivider = timeUnitDivider;
						if (pStatistics[mdl]) {
							pStatistics[mdl].push(moduleData);
						} else {
							pStatistics[mdl] = [moduleData];
						}
					}
				}
			}
		}
		const pFormattedModuleData = moduleOrder.map((mdl) => {
			const mdlItems = pStatistics[mdl] || [];
			const mdlFormulas = formulas[mdl]?.formulas || {};
			const mdlUIs = ui[mdl]?.layout || {};
			const totalAnnualTimeEffortCategories = {};
			const totalAnnualCostCategories = {};
			const totalAnnualImprovementCategories = {};
			const aveData = mdlItems.reduce(
				(stat, item) => {
					const { inputData, computedData } = item;
					const { timeUnitDivider } = inputData;
					const totalTimeEffortOfCurrentProcess =
						(computedData[
							`totalTimeEffortOfCurrentProcess${mdl.toUpperCase()}`
						] ||
							computedData[
								`timeEffortOfCurrentProcessCumuYear1${mdl.toUpperCase()}`
							] ||
							0) * timeUnitDivider;
					const totalTimeEffortWithHarness =
						(computedData[`totalTimeEffortWithHarness${mdl.toUpperCase()}`] ||
							computedData[
								`timeEffortWithHarnessCumuYear1${mdl.toUpperCase()}`
							] ||
							0) * timeUnitDivider;
					const totalCostOfCurrentProcess =
						computedData[`costOfCurrentProcessYear1${mdl.toUpperCase()}`] ||
						computedData[`costOfCurrentProcessCumuYear1${mdl.toUpperCase()}`] ||
						0;
					const totalCostWithHarness =
						computedData[`totalCostWithHarnessYear1${mdl.toUpperCase()}`] ||
						computedData[`costWithHarnessCumuYear1${mdl.toUpperCase()}`] ||
						0;

					let fteResources = 0;
					for (const val of Object.values(inputData)) {
						if (
							val &&
							typeof val === "object" &&
							val.originalValue &&
							Array.isArray(val.originalValue) &&
							val.originalValue.length > 0
						) {
							const ftes = val.originalValue;
							for (const fte of ftes) {
								if (fte.fteNumber) {
									fteResources += fte.fteNumber;
								}
							}
						}
					}

					const annualCosts = mdlFormulas["Annual Costs"];
					const annualCostsWithHarness =
						mdlFormulas["Annual Costs with Harness"];
					const annualTimeEffort = mdlFormulas["Annual Time Effort"];
					const annualTimeEffortWithHarness =
						mdlFormulas["Annual Time Effort with Harness"];
					const annualImprovement = mdlUIs["Improvement Factors"];
					if (annualCosts) {
						for (const activity of annualCosts) {
							const formulaName = getLHSVariable(activity.Formula);
							const formulaValue = computedData[formulaName];
							if (totalAnnualCostCategories[formulaName]) {
								totalAnnualCostCategories[formulaName].push(formulaValue);
							} else {
								totalAnnualCostCategories[formulaName] = [formulaValue];
							}
						}
					}
					if (annualTimeEffort) {
						for (const activity of annualTimeEffort) {
							const formulaName = getLHSVariable(activity.Formula);
							const formulaValue = computedData[formulaName];
							if (totalAnnualTimeEffortCategories[formulaName]) {
								totalAnnualTimeEffortCategories[formulaName].push(
									formulaValue * timeUnitDivider,
								);
							} else {
								totalAnnualTimeEffortCategories[formulaName] = [
									formulaValue * timeUnitDivider,
								];
							}
						}
					}
					if (annualImprovement) {
						for (const activity of annualImprovement) {
							const fieldName = activity.VariableName;
							let fieldValue = inputData[fieldName];
							if (
								fieldValue &&
								typeof fieldValue === "object" &&
								fieldValue.value
							) {
								fieldValue = fieldValue.value;
							}
							if (totalAnnualImprovementCategories[fieldName]) {
								totalAnnualImprovementCategories[fieldName].push(fieldValue);
							} else {
								totalAnnualImprovementCategories[fieldName] = [fieldValue];
							}
						}
					}

					const newStat = stat;
					newStat.totalTimeEffortOfCurrentProcess +=
						totalTimeEffortOfCurrentProcess;
					newStat.totalTimeEffortWithHarness += totalTimeEffortWithHarness;
					newStat.totalCostOfCurrentProcess += totalCostOfCurrentProcess;
					newStat.totalCostWithHarness += totalCostWithHarness;
					const { numberOfServicesApplications } = inputData;
					newStat.timeEffortOfCurrentProcessPerApp +=
						totalTimeEffortOfCurrentProcess / numberOfServicesApplications;
					newStat.timeEffortWithHarnessPerApp +=
						totalTimeEffortWithHarness / numberOfServicesApplications;
					newStat.costOfCurrentProcessPerApp +=
						totalCostOfCurrentProcess / numberOfServicesApplications;
					newStat.costWithHarnessPerApp +=
						totalCostWithHarness / numberOfServicesApplications;
					newStat.fteResources += fteResources;

					newStat.totalAnnualTimeEffortCategories =
						totalAnnualTimeEffortCategories;
					newStat.totalAnnualCostCategories = totalAnnualCostCategories;
					newStat.totalAnnualImprovementCategories =
						totalAnnualImprovementCategories;

					return newStat;
				},
				{
					totalTimeEffortOfCurrentProcess: 0,
					totalTimeEffortWithHarness: 0,
					totalCostOfCurrentProcess: 0,
					totalCostWithHarness: 0,
					timeEffortOfCurrentProcessPerApp: 0,
					timeEffortWithHarnessPerApp: 0,
					costOfCurrentProcessPerApp: 0,
					costWithHarnessPerApp: 0,
					fteResources: 0,
					totalAnnualTimeEffortCategories: {},
					totalAnnualCostCategories: {},
					totalAnnualImprovementCategories: {},
				},
			);
			const moduleInfo = moduleNames.find((mdlItem) => mdlItem.id === mdl);

			const intTotalTimeEffortOfCurrentProcess =
				aveData.totalTimeEffortOfCurrentProcess /
				mdlItems.length /
				dividerDispTimeUnit;
			const intTotalCostOfCurrentProcess =
				aveData.totalCostOfCurrentProcess / mdlItems.length;
			const intTotalTimeEffortWithHarness =
				aveData.totalTimeEffortWithHarness /
				mdlItems.length /
				dividerDispTimeUnit;
			const intTotalCostWithHarness =
				aveData.totalCostWithHarness / mdlItems.length;
			const intTotalTimeEffortImpact =
				intTotalTimeEffortOfCurrentProcess - intTotalTimeEffortWithHarness;
			const intTotalCostImprovement =
				intTotalCostOfCurrentProcess - intTotalCostWithHarness;
			const intTotalTimeEffortImpactPercentage =
				intTotalTimeEffortImpact / intTotalTimeEffortOfCurrentProcess;
			const intTotalCostImprovementPercentage =
				intTotalCostImprovement / intTotalCostOfCurrentProcess;
			const formattedAveData = {
				totalTimeEffortOfCurrentProcess: `${intNumberFormatter(
					intTotalTimeEffortOfCurrentProcess,
				)} ${dispTimeUnit}`,
				totalTimeEffortWithHarness: `${intNumberFormatter(
					intTotalTimeEffortWithHarness,
				)} ${dispTimeUnit}`,
				totalTimeEffortImpact: `${intNumberFormatter(
					intTotalTimeEffortImpact,
				)} ${dispTimeUnit}`,

				totalCostOfCurrentProcess: intCurrencyFormatter(
					intTotalCostOfCurrentProcess,
				),
				totalCostWithHarness: intCurrencyFormatter(intTotalCostWithHarness),
				totalCostImprovement: intCurrencyFormatter(intTotalCostImprovement),

				totalTimeEffortImpactPercentage: convertPercentageFormatter(
					intTotalTimeEffortImpactPercentage,
				),
				totalCostImprovementPercentage: convertPercentageFormatter(
					intTotalCostImprovementPercentage,
				),

				timeEffortOfCurrentProcessPerApp: `${intNumberFormatter(
					aveData.timeEffortOfCurrentProcessPerApp /
						mdlItems.length /
						dividerDispTimeUnit,
				)} ${dispTimeUnit}`,

				timeEffortWithHarnessPerApp: `${intNumberFormatter(
					aveData.timeEffortWithHarnessPerApp /
						mdlItems.length /
						dividerDispTimeUnit,
				)} ${dispTimeUnit}`,

				costOfCurrentProcessPerApp: intCurrencyFormatter(
					aveData.costOfCurrentProcessPerApp / mdlItems.length,
				),

				costWithHarnessPerApp: intCurrencyFormatter(
					aveData.costWithHarnessPerApp / mdlItems.length,
				),

				fteResources: intNumberFormatter(
					aveData.fteResources / mdlItems.length,
				),
			};
			const dataSource = [
				{
					key: "1",
					...formattedAveData,
				},
			];

			const dataSourceCategory = Object.entries(
				aveData.totalAnnualCostCategories,
			)
				.filter(([name]) => {
					return !regExpCustomCategories.test(name);
				})
				.map(([name, value], idx) => {
					const corrTimeEffect =
						Object.values(aveData.totalAnnualTimeEffortCategories)[idx] || [];
					let corrImprovement =
						Object.values(aveData.totalAnnualImprovementCategories)[idx] || [];
					let improValue = intPercentageFormatter(
						corrImprovement.reduce(
							(a, b) =>
								b && typeof b !== "object" ? a + Number.parseInt(b, 10) : a,
							0,
						) /
							corrImprovement.filter(
								(v) => v !== null && v !== undefined && typeof v !== "object",
							).length,
					);
					if (mdl === "ci" && idx === 1) {
						const corrImprovementA =
							Object.entries(aveData.totalAnnualImprovementCategories)[idx] ||
							[];
						const corrImprovementB =
							Object.entries(aveData.totalAnnualImprovementCategories)[
								idx + 1
							] || [];
						const corrImprovementAValue = corrImprovementA[1] || [];
						const corrImprovementBValue = corrImprovementB[1] || [];
						improValue = (
							<>
								Running:{" "}
								{intPercentageFormatter(
									corrImprovementAValue.reduce(
										(a, b) =>
											b && typeof b !== "object"
												? a + Number.parseInt(b, 10)
												: a,
										0,
									) /
										corrImprovementAValue.filter(
											(v) =>
												v !== null && v !== undefined && typeof v !== "object",
										).length,
								)}
								<br />
								Testing:{" "}
								{intPercentageFormatter(
									corrImprovementBValue.reduce(
										(a, b) =>
											b && typeof b !== "object"
												? a + Number.parseInt(b, 10)
												: a,
										0,
									) /
										corrImprovementBValue.filter(
											(v) =>
												v !== null && v !== undefined && typeof v !== "object",
										).length,
								)}
							</>
						);
					} else if (mdl === "ci" && idx > 1) {
						corrImprovement =
							Object.values(aveData.totalAnnualImprovementCategories)[
								idx + 1
							] || [];
						improValue = intPercentageFormatter(
							corrImprovement.reduce(
								(a, b) =>
									b && typeof b !== "object" ? a + Number.parseInt(b, 10) : a,
								0,
							) /
								corrImprovement.filter(
									(v) => v !== null && v !== undefined && typeof v !== "object",
								).length,
						);
					}
					return {
						key: name,
						category: getDisplayName(name),
						timeEffort: `${intNumberFormatter(
							corrTimeEffect.reduce(
								(a, b) => (b ? a + Number.parseInt(b, 10) : a),
								0,
							) /
								corrTimeEffect.filter((v) => v !== null && v !== undefined)
									.length /
								dividerDispTimeUnit,
						)} ${dispTimeUnit}`,
						cost: intCurrencyFormatter(
							value.reduce((a, b) => (b ? a + Number.parseInt(b, 10) : a), 0) /
								value.filter((v) => v !== null && v !== undefined).length,
						),
						improvement: improValue,
					};
				});
			return {
				moduleId: mdl,
				moduleInfo,
				mdlItems,
				dataSource,
				dataSourceCategory,
				intTotalTimeEffortOfCurrentProcess,
				intTotalCostOfCurrentProcess,
				intTotalTimeEffortWithHarness,
				intTotalCostWithHarness,
				intTotalTimeEffortImpact,
				intTotalCostImprovement,
				intTotalTimeEffortImpactPercentage,
				intTotalCostImprovementPercentage,
			};
		});
		setFormattedModuleData(pFormattedModuleData);

		const categoryModules = pFormattedModuleData.map((mdl) => {
			const { moduleId } = mdl;
			return moduleId.toUpperCase();
		});
		const pChartOptionModuleBVANumber = {
			title: {
				text: "BVA entries by module",
			},
			xAxis: {
				type: "category",
				data: categoryModules,
			},
			yAxis: {
				type: "value",
			},
			series: [
				{
					data: pFormattedModuleData.map((mdl) => {
						const { mdlItems, moduleId } = mdl;
						const mdlBVACount = mdlItems ? mdlItems.length : 0;
						return {
							value: mdlBVACount,
							itemStyle: {
								color: getCssVarValue(`--mod-${moduleId}-200`),
							},
						};
					}),
					type: "bar",
					label: {
						show: true,
						position: "top",
					},
				},
			],
		};
		setChartOptionModuleBVANumber(pChartOptionModuleBVANumber);

		const pChartOptionModuleTimeEffect = {
			title: {
				text: `Ave. Impact by module (${dispTimeUnit})`,
			},
			xAxis: {
				type: "category",
				data: categoryModules,
			},
			yAxis: {
				type: "value",
			},
			series: [
				{
					data: pFormattedModuleData.map((mdl) => {
						const { intTotalTimeEffortImpact, moduleId } = mdl;
						return {
							value: intTotalTimeEffortImpact,
							itemStyle: {
								color: getCssVarValue(`--mod-${moduleId}-200`),
							},
						};
					}),
					type: "bar",
					label: {
						show: true,
						position: "top",
						formatter: shortenedNumberFormatter,
					},
				},
			],
		};
		setChartOptionModuleTimeEffect(pChartOptionModuleTimeEffect);

		const pChartOptionModuleCost = {
			title: {
				text: "Ave. Improvement by module (USD)",
			},
			xAxis: {
				type: "category",
				data: categoryModules,
			},
			yAxis: {
				type: "value",
			},
			series: [
				{
					data: pFormattedModuleData.map((mdl) => {
						const { intTotalCostImprovement, moduleId } = mdl;
						return {
							value: intTotalCostImprovement,
							itemStyle: {
								color: getCssVarValue(`--mod-${moduleId}-200`),
							},
						};
					}),
					type: "bar",
					label: {
						show: true,
						position: "top",
						formatter: shortenedCurrencyFormatter,
					},
				},
			],
		};
		setChartOptionModuleCost(pChartOptionModuleCost);
	}, [
		formulas,
		ui,
		costCap,
		costFloor,
		dispTimeUnit,
		dividerDispTimeUnit,
		ignoreTestEntries,
	]);

	const handleCapChange = (val) => {
		if (!val) {
			return;
		}
		setCostCap(Number.parseInt(val, 10));
	};
	const handleFloorChange = (val) => {
		if (!val) {
			return;
		}
		setCostFloor(Number.parseInt(val, 10));
	};
	const handleTimeUnitChange = (val) => {
		if (!val) {
			return;
		}
		const dividerVal = Number.parseInt(val, 10);
		const selectedTU = TIME_UNIT.find((tu) => tu.value === dividerVal);
		setDispTimeUnit(selectedTU.label);
		setDividerDispTimeUnit(dividerVal);
	};
	const handleHoursPerDayChange = (val) => {
		if (!val) {
			return;
		}
		const hrsPerDayVal = Number.parseInt(val, 10);
		setHoursPerDay(hrsPerDayVal);
		setDividerDispTimeUnit(hrsPerDayVal * 60);
	};
	const handleIgnoreTestChange = () => {
		setIgnoreTestEntries(!ignoreTestEntries);
	};
	return (
		<div className="pageWrapper">
			<Helmet>
				<title>Reporting | Business Value Assessment</title>
			</Helmet>
			<LeftNav />
			<main className="main">
				<div className="pageTop">
					<h1>Reporting</h1>
					<p>BVA Statistics</p>

					<ul className={styles.filters}>
						<li>
							Annual Cost with Harness{" "}
							<Tooltip
								title={
									<span>
										Range of Annual Cost with Harness = Annual Cost of Current
										Process - Annual Improvement + Annual License fee by module.
										<br />
										The results of this report will be filtered to include all
										BVA modules which have an Annual Cost with Harness within
										the range.
									</span>
								}
							>
								<QuestionCircleOutlined />
							</Tooltip>
							{" : "}
							<InputNumber
								defaultValue={DEFAULT_COST_FLOOR}
								onChange={handleFloorChange}
								formatter={currencyFormatter}
								parser={currencyParser}
							/>
							{" ~ "}
							<InputNumber
								defaultValue={DEFAULT_COST_CAP}
								onChange={handleCapChange}
								formatter={currencyFormatter}
								parser={currencyParser}
							/>
						</li>
						<li>
							Display Time Unit:{" "}
							<Select
								defaultValue={TIME_UNIT[0].value}
								onChange={handleTimeUnitChange}
								options={TIME_UNIT}
								suffixIcon={<ChevronDownIcon />}
							/>
							{dispTimeUnit === TIME_UNIT[1].label && (
								<Select
									defaultValue={hoursPerDay || DEFAULT_HOURS_PER_DAY}
									onChange={handleHoursPerDayChange}
									options={HOURS_PER_DAY.map((opt) => ({
										label: `${opt} hours/day`,
										value: opt,
									}))}
									suffixIcon={<ChevronDownIcon />}
								/>
							)}
						</li>
						<li>
							<Checkbox
								onChange={handleIgnoreTestChange}
								checked={ignoreTestEntries}
							>
								Ignore test entries{" "}
								<Tooltip title="Ignore BVAs which name or description contains 'test'">
									<QuestionCircleOutlined />
								</Tooltip>
							</Checkbox>
						</li>
					</ul>
				</div>
				{!isAdmin || loading || formattedModuleData.length === 0 ? (
					<div className={styles.loading}>
						<Loading />
					</div>
				) : (
					<div className={styles.reportingContainer}>
						<div className={styles.total}>
							Total valid BVA count: {sdcaData.length} BVAs{" "}
						</div>
						<ul className={styles.moduleCharts}>
							<li>
								<ReactECharts option={chartOptionModuleBVANumber} />
							</li>
							<li>
								<ReactECharts option={chartOptionModuleTimeEffect} />
							</li>
							<li>
								<ReactECharts option={chartOptionModuleCost} />
							</li>
						</ul>
						<ul>
							{formattedModuleData.map((mdl) => {
								const {
									moduleId,
									moduleInfo,
									mdlItems,
									dataSource,
									dataSourceCategory,
								} = mdl;
								const mdlName = moduleInfo ? moduleInfo.name : "Unknown";
								const mdlBVACount = mdlItems
									? ` ${mdlItems.length} BVAs`
									: " N/A";
								return (
									<li className={styles[moduleId]} key={moduleId}>
										<h3>
											<strong>
												<img
													alt="Module"
													src={`/assets/icon_${moduleId}.svg`}
													className={styles.moduleIcon}
												/>
												{mdlName}:{mdlBVACount}{" "}
												<Tooltip
													title={`There are ${mdlBVACount} contain ${mdlName} module.`}
												>
													<QuestionCircleOutlined />
												</Tooltip>
											</strong>
										</h3>

										<ul>
											<li>
												<h4>Average time effort and cost (per BVA)</h4>
												<Table
													dataSource={dataSource}
													columns={columnsTotal}
													pagination={{ position: ["none", "none"] }}
													rowClassName={styles.tableRow}
												/>
											</li>
											<li>
												<h4>Summary by Category</h4>
												<Table
													dataSource={dataSourceCategory}
													columns={columnsCategory}
													pagination={{ position: ["none", "none"] }}
													rowClassName={styles.tableRow}
												/>
											</li>
										</ul>
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</main>
		</div>
	);
}

const ReportingPage = React.memo(Reporting);

export { ReportingPage };
