import { getUserByEmail } from "@/core/actions";
import { getUser, setAuthToken } from "@/core/auth";
import TokenSession from "@/core/components/TokenSession";
import { getToken } from "@/core/components/TokenSession/utils";
import { useAppDispatch, useAppSelector } from "@/core/hooks/reduxHooks";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function SDCAComponent({ children }: { children: React.ReactNode }) {
	const [getUserCounter, setGetUserCounter] = useState(0);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { authState: oktaAuthState } = useOktaAuth();
	const permission = useAppSelector((state) => state.login.permission);
	const { pathname } = location;

	useEffect(() => {
		const getUserAsync = async () => {
			if (!permission || !permission.role) {
				const user = await getUser();
				if (!user || !user.email) {
					return;
				}

				const savedToken = getToken();
				if (savedToken) {
					dispatch(getUserByEmail(user.email));
				}

				setTimeout(() => {
					setGetUserCounter(getUserCounter + 1);
				}, 1000);
			}
		};

		getUserAsync();
	}, [oktaAuthState, getUserCounter]);

	useEffect(() => {
		const asyncFunc = async () => {
			await setAuthToken();
		};
		asyncFunc();
	}, [pathname, oktaAuthState]);

	return (
		<>
			<TokenSession />
			{children}
		</>
	);
}

const SDCA = React.memo(SDCAComponent);
export { SDCA };
