import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Select } from "antd";
import React, { useState } from "react";
import styles from "./SelectTools.module.scss";

const { Option } = Select;

// the keys of tools can not be too long
const SDLC_TOOLS = {
	/* Harness */
	harness: {
		icon: "/assets/icon_logoOnlyImage.svg",
		logo: "/assets/icon-tools-harness.png",
		title: "Harness",
	},
	harness_ci: {
		icon: "/assets/icon_ci.svg",
		logo: "/assets/icon-tools-ci.png",
		title: "Continuous Integration",
	},
	harness_cd: {
		icon: "/assets/icon_cd.svg",
		logo: "/assets/icon-tools-cd.png",
		title: "Continuous Delivery",
	},
	harness_ff: {
		icon: "/assets/icon_ff.svg",
		logo: "/assets/icon-tools-ff.png",
		title: "Feature Flags",
	},
	harness_ccm: {
		icon: "/assets/icon_ccm.svg",
		logo: "/assets/icon-tools-ccm.png",
		title: "Cloud Cost Management",
	},
	harness_srm: {
		icon: "/assets/icon_srm.svg",
		logo: "/assets/icon-tools-srm.png",
		title: "Service Reliability Management",
	},
	harness_sto: {
		icon: "/assets/icon_sto.svg",
		logo: "/assets/icon-tools-sto.png",
		title: "Security Testing Orchestration",
	},
	harness_ce: {
		icon: "/assets/icon_ce.svg",
		logo: "/assets/icon-tools-ce.png",
		title: "Chaos Engineering",
	},
	harness_sei: {
		icon: "/assets/icon_sei.svg",
		logo: "/assets/icon-tools-sei.png",
		title: "Software Engineering Insights",
	},
	/* Build */
	jenkins: {
		icon: "/assets/icon_jenkins.png",
		logo: "/assets/icon-tools-jenkins.png",
		title: "Jenkins",
	},
	circleci: {
		icon: "/assets/icon_circleci.png",
		logo: "/assets/icon-tools-circleci.png",
		title: "CircleCI",
	},
	github_actions: {
		icon: "/assets/icon_github_actions.png",
		logo: "/assets/icon-tools-github-actions.png",
		title: "Github Actions",
	},

	/* Deployment Pipeline */
	terraform: {
		icon: "/assets/icon_terraform.png",
		logo: "/assets/icon-tools-terraform.png",
		title: "Terraform",
	},
	gitlab: {
		icon: "/assets/icon_gitlab.png",
		logo: "/assets/icon-tools-gitlab.png",
		title: "Gitlab",
	},
	gitops: {
		icon: "/assets/icon_gitops.svg",
		logo: "/assets/icon-tools-gitops.png",
		title: "GitOps",
	},
	gocd: {
		icon: "/assets/icon_gocd.png",
		logo: "/assets/icon-tools-gocd.png",
		title: "GoCD",
	},
	argocd: {
		icon: "/assets/icon_argocd.png",
		logo: "/assets/icon-tools-argocd.png",
		title: "Argo CD",
	},

	/* QA & Test */
	selenium: {
		icon: "/assets/icon_selenium_small.png",
		logo: "/assets/icon-tools-selenium.png",
		title: "Selenium",
	},
	jmeter: {
		icon: "/assets/icon_jmeter.svg",
		logo: "/assets/icon-tools-jmeter.png",
		title: "JMeter",
	},
	junit5: {
		icon: "/assets/icon_junit5.png",
		logo: "/assets/icon-tools-junit5.png",
		title: "JUnit 5",
	},
	cucumber: {
		icon: "/assets/icon_cucumber.png",
		logo: "/assets/icon-tools-cucumber.png",
		title: "Cucumber",
	},

	/* Governance */
	jira: {
		icon: "/assets/icon_jira.png",
		logo: "/assets/icon-tools-jira.png",
		title: "Jira",
	},

	cherwell: {
		icon: "/assets/icon_cherwell.jpeg",
		logo: "/assets/icon-tools-cherwell.png",
		title: "Cherwell",
	},

	/* Production */
	amazon_ecs: {
		icon: "/assets/icon_amazon_ecs.png",
		logo: "/assets/icon-tools-amazon-ecs.png",
		title: "Amazon ECS",
	},
	amazon_ec2: {
		icon: "/assets/icon_amazon_ec2.png",
		logo: "/assets/icon-tools-amazon-ec2.png",
		title: "Amazon EC2",
	},
	amazon_lambda: {
		icon: "/assets/icon_amazon_lambda.png",
		logo: "/assets/icon-tools-amazon-lambda.png",
		title: "AWS Lambda",
	},

	/* Verify */
	splunk: {
		icon: "/assets/icon_splunk.png",
		logo: "/assets/icon-tools-splunk.png",
		title: "Splunk",
	},
	datadog: {
		icon: "/assets/icon_datadog.svg",
		logo: "/assets/icon-tools-datadog.png",
		title: "Datadog",
	},

	/* Rollback */
	gcp: {
		icon: "/assets/icon_gcpSmall.svg",
		logo: "/assets/icon-tools-gcp.png",
		title: "GCP",
	},
	azure: {
		icon: "/assets/icon_azureSmall.svg",
		logo: "/assets/icon-tools-azure.png",
		title: "Azure",
	},
	buildkite: {
		icon: "/assets/icon_buildkiteSmall.svg",
		logo: "/assets/icon-tools-buildkite.png",
		title: "BuildKite",
	},
	pivotal: {
		icon: "/assets/icon_pivotalSmall.svg",
		logo: "/assets/icon-tools-pivotal.png",
		title: "Pivotal",
	},
	openshift: {
		icon: "/assets/icon_openshiftSmall.svg",
		logo: "/assets/icon-tools-openshift.png",
		title: "Open Shift",
	},

	sonatype_nexus: {
		icon: "/assets/icon-sonatype-nexus.png",
		logo: "/assets/icon-tools-sonatype-nexus.png",
		title: "Sonatype Nexus",
	},
	jfrog_artifactory: {
		icon: "/assets/icon-jfrog-artifactory.png",
		logo: "/assets/icon-tools-jfrog-artifactory.png",
		title: "JFrog Artifactory",
	},
	docker_hub: {
		icon: "/assets/icon-docker-hub.png",
		logo: "/assets/icon-tools-docker-hub.png",
		title: "Docker Hub",
	},
	github_cr: {
		icon: "/assets/icon-github.png",
		logo: "/assets/icon-tools-github-container-registry.png",
		title: "Git Hub Container Registry",
	},
	amazon_ecr: {
		icon: "/assets/icon-amazon-ecr.png",
		logo: "/assets/icon-tools-amazon-ecr.png",
		title: "Amazon ECR",
	},
	azure_acr: {
		icon: "/assets/icon-azure-acr.png",
		logo: "/assets/icon-tools-azure-acr.png",
		title: "Azure ACR",
	},
	google_gcr: {
		icon: "/assets/icon-google-gcr.png",
		logo: "/assets/icon-tools-google-gcr.png",
		title: "Google GCR",
	},
	atlassian_stash: {
		icon: "/assets/icon-atlassian-stash.png",
		logo: "/assets/icon-tools-atlassian-stash.png",
		title: "Atlassian Stash",
	},
	kubernetes: {
		icon: "/assets/icon-kubernetes.png",
		logo: "/assets/icon-tools-kubernetes.png",
		title: "Kubernetes",
	},
	amazon_eks: {
		icon: "/assets/icon-amazon-eks.png",
		logo: "/assets/icon-tools-amazon-eks.png",
		title: "Amazon EKS",
	},
	azure_aks: {
		icon: "/assets/icon-azure-aks.png",
		logo: "/assets/icon-tools-azure-aks.png",
		title: "Azure AKS",
	},
	google_gke: {
		icon: "/assets/icon-google-gke.png",
		logo: "/assets/icon-tools-google-gke.png",
		title: "Google GKE",
	},
	helm: {
		icon: "/assets/icon-helm.png",
		logo: "/assets/icon-tools-helm.png",
		title: "HELM",
	},
	aws_fargate: {
		icon: "/assets/icon-aws-fargate.png",
		logo: "/assets/icon-tools-aws-fargate.png",
		title: "AWS Fargate",
	},
	amazon_ami: {
		icon: "/assets/icon-amazon-ami.png",
		logo: "/assets/icon-tools-amazon-ami.png",
		title: "Amazon AMI",
	},
	aws: {
		icon: "/assets/icon-aws.png",
		logo: "/assets/icon-tools-aws.png",
		title: "AWS",
	},
	google_cloud: {
		icon: "/assets/icon-google-cloud.png",
		logo: "/assets/icon-tools-google-cloud.png",
		title: "Google Cloud",
	},
	appdynamics: {
		icon: "/assets/icon-appdynamics.png",
		logo: "/assets/icon-tools-appdynamics.png",
		title: "APPDYNAMICS",
	},
	new_relic: {
		icon: "/assets/icon-new-relic.png",
		logo: "/assets/icon-tools-new-relic.png",
		title: "New Relic",
	},
	dynatrace: {
		icon: "/assets/icon-dynatrace.png",
		logo: "/assets/icon-tools-dynatrace.png",
		title: "Dynatrace",
	},
	prometheus: {
		icon: "/assets/icon-prometheus.png",
		logo: "/assets/icon-tools-prometheus.png",
		title: "Prometheus",
	},
	cloudwatch: {
		icon: "/assets/icon-cloudwatch.png",
		logo: "/assets/icon-tools-cloudwatch.png",
		title: "CloudWatch",
	},
	elastic: {
		icon: "/assets/icon-elastic.png",
		logo: "/assets/icon-tools-elastic.png",
		title: "Elastic",
	},
	nagios: {
		icon: "/assets/icon-nagios.png",
		logo: "/assets/icon-tools-nagios.png",
		title: "Nagios",
	},
	sonarqube: {
		icon: "/assets/icon-sonarqube.png",
		logo: "/assets/icon-tools-sonarqube.png",
		title: "SonarQube",
	},
	teragrunt: {
		icon: "/assets/icon-teragrunt.png",
		logo: "/assets/icon-tools-teragrunt.png",
		title: "Teragrunt",
	},
	cloudformation: {
		icon: "/assets/icon-cloudformation.png",
		logo: "/assets/icon-tools-cloudformation.png",
		title: "CloudFormation",
	},
	servicenow: {
		icon: "/assets/icon-servicenow.png",
		logo: "/assets/icon-tools-servicenow.png",
		title: "Servicenow",
	},
	confluence: {
		icon: "/assets/icon-confluence.png",
		logo: "/assets/icon-tools-confluence.png",
		title: "Confluence",
	},
	bmc_remedy: {
		icon: "/assets/icon-bmc-remedy.png",
		logo: "/assets/icon-tools-bmc-remedy.png",
		title: "BMC REMEDY",
	},
	git: {
		icon: "/assets/icon-git.png",
		logo: "/assets/icon-tools-git.png",
		title: "Git",
	},
	name: {
		icon: "/assets/icon-github.png",
		logo: "/assets/icon-tools-github.png",
		title: "GitHub",
	},
	bitbucket: {
		icon: "/assets/icon-bitbucket.png",
		logo: "/assets/icon-tools-bitbucket.png",
		title: "Bitbucket",
	},
	codecommit: {
		icon: "/assets/icon-codecommit.png",
		logo: "/assets/icon-tools-codecommit.png",
		title: "CodeCommit",
	},
	vault: {
		icon: "/assets/icon-vault.png",
		logo: "/assets/icon-tools-vault.png",
		title: "HashiCorp Vault",
	},
	aws_kms_sm: {
		icon: "/assets/icon-aws-kms-sm.png",
		logo: "/assets/icon-tools-aws-kms-sm.png",
		title: "AWS KMS/SM",
	},
	google_ksm: {
		icon: "/assets/icon-google-ksm.png",
		logo: "/assets/icon-tools-google-ksm.png",
		title: "Google KMS",
	},
	webhooks: {
		icon: "/assets/icon-webhooks.png",
		logo: "/assets/icon-tools-webhooks.png",
		title: "Webhooks",
	},
	ssh: {
		icon: "/assets/icon-ssh.png",
		logo: "/assets/icon-tools-ssh.png",
		title: "SSH",
	},

	custom_script: {
		icon: "/assets/icon_custom_script.png",
		logo: "/assets/icon-tools-custom-script.png",
		title: "Custom Script",
	},
	on_prem: {
		icon: "/assets/icon_on_prem.png",
		logo: "/assets/icon-tools-on-prem.png",
		title: "On-Prem",
	},
	default: {
		icon: "/assets/icon-tools-default.png",
		logo: "/assets/icon-tools-default.png",
		title: "",
	},
};

function SelectTools({ value = "", onChange }) {
	const [tools, setTools] = useState([]);

	const triggerChange = (changedValue) => {
		onChange(changedValue);
	};

	const onToolsChange = (newTools) => {
		setTools(newTools);
		triggerChange(newTools);
	};

	return (
		<span>
			<Select
				mode="multiple"
				allowClear
				value={value || tools}
				onChange={onToolsChange}
				suffixIcon={<ChevronDownIcon />}
			>
				{Object.keys(SDLC_TOOLS).map((key) => (
					<Option value={key} key={key}>
						<img src={SDLC_TOOLS[key].icon} alt={`Icon ${key.title}`} />{" "}
						{SDLC_TOOLS[key].title}
					</Option>
				))}
			</Select>
		</span>
	);
}

export default SelectTools;
