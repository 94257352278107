import { PUBLIC_URL } from "@/core/constants";
import { RightOutlined } from "@ant-design/icons";
import { Button, Carousel } from "antd";
import type { CarouselRef } from "antd/lib/carousel";
import { useRef } from "react";
import homeStyle from "./Home.module.scss";

function GetStarted({ handleSkip }: { handleSkip: () => void }) {
	const refCarousel = useRef<CarouselRef>(null);

	const handleNext = () => {
		if (refCarousel?.current) {
			refCarousel.current.next();
		}
	};

	const handleBack = () => {
		if (refCarousel?.current) {
			refCarousel.current.prev();
		}
	};

	return (
		<div className="getStarted">
			<div className={homeStyle.getStartedContent}>
				<Carousel autoplay ref={refCarousel}>
					<div>
						<div className={homeStyle.carouselItem}>
							<div className={homeStyle.left}>
								<h2>
									Welcome to <span>SDCA</span>{" "}
								</h2>
								<div className={homeStyle.desc}>
									SDCA stands for “
									<span>Software Delivery Capability Assessment</span>”.
								</div>
								<div className={homeStyle.desc}>
									Take a quick tour to get yourself familiar with this tool!
								</div>
								<div className={homeStyle.thumb}>
									<div className={homeStyle.firstThumb}>
										<Button onClick={handleNext} type="primary">
											Get Started
										</Button>
										<Button type="link" onClick={handleNext}>
											Skip this step
										</Button>
									</div>
								</div>
							</div>

							<div className={homeStyle.right}>
								<div className={homeStyle.rightFirst}>
									<img
										alt="Get started screenshot"
										src={`${PUBLIC_URL}/assets/getstarted-screenshot2.png`}
										width="750"
										className={homeStyle.img1}
									/>
									<div className={homeStyle.img2}>
										<img
											alt="Get started screenshot"
											src={`${PUBLIC_URL}/assets/getstarted-screenshot1.png`}
											width="520"
										/>
									</div>
									<div className={homeStyle.rightDesc}>
										<h1>
											BVA Tool - <br />
											ROI Calculator
										</h1>
										<h6>
											A tailor-made automation tool for Harness Sales team{" "}
										</h6>
										<p>
											Help Sales Reps manages BVAs, calculate ROI create
											cuztomizable pitch deck based clients’ solution profiles
											automatically{" "}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className={homeStyle.carouselItem}>
							<div className={homeStyle.left}>
								<h2>
									View all <span>SDCA</span>{" "}
								</h2>
								<div className={homeStyle.desc}>
									You may view all the existing SDCA in the Dashboard. And
									quickly find a record through filter and sort.
								</div>
								<div className={homeStyle.thumb}>
									<div className={homeStyle.buttons}>
										<Button onClick={handleNext} type="primary">
											Next
										</Button>
										<Button
											className={homeStyle.backButton}
											onClick={handleBack}
										>
											Back
										</Button>
									</div>
									<img
										alt="Get started screenshot"
										src={`${PUBLIC_URL}/assets/get-started-0.svg`}
										width="195"
									/>
								</div>
							</div>

							<div className={homeStyle.right}>
								<div className={homeStyle.btnSkip}>
									<Button type="link" onClick={handleSkip}>
										Skip <RightOutlined />
									</Button>
								</div>
								<div className={homeStyle.rightImg}>
									<img
										alt="Get started screenshot"
										src={`${PUBLIC_URL}/assets/get-started-screenshot-0.svg`}
										width="723"
									/>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className={homeStyle.carouselItem}>
							<div className={homeStyle.left}>
								<h2>
									Collect <br />
									<span>solution profiles</span>
								</h2>
								<div className={homeStyle.desc}>
									You may view all the existing SDCA in the Dashboard. And
									quickly find a record through filter and sort.{" "}
								</div>
								<div className={homeStyle.thumb}>
									<div className={homeStyle.buttons}>
										<Button onClick={handleNext} type="primary">
											Next
										</Button>
										<Button
											className={homeStyle.backButton}
											onClick={handleBack}
										>
											Back
										</Button>
									</div>
									<img
										alt="Get started"
										src={`${PUBLIC_URL}/assets/get-started-1.svg`}
										width="177"
									/>
								</div>
							</div>

							<div className={homeStyle.right}>
								<div className={homeStyle.btnSkip}>
									<Button type="link" onClick={handleSkip}>
										Skip <RightOutlined />
									</Button>
								</div>
								<div className={homeStyle.rightImg}>
									<img
										alt="Get started"
										src={`${PUBLIC_URL}/assets/getstarted-solutionprofile.svg`}
										width="668"
									/>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className={homeStyle.carouselItem}>
							<div className={homeStyle.left}>
								<h2>
									Fill up <br />
									<span>Rep’s input</span>
								</h2>
								<div className={homeStyle.desc}>
									You may view all the existing SDCA in the Dashboard. And
									quickly find a record through filter and sort.
								</div>
								<div className={homeStyle.thumb}>
									<div className={homeStyle.buttons}>
										<Button onClick={handleNext} type="primary">
											Next
										</Button>
										<Button
											className={homeStyle.backButton}
											onClick={handleBack}
										>
											Back
										</Button>
									</div>
									<img
										alt="Get started"
										src={`${PUBLIC_URL}/assets/get-started-2.svg`}
										width="150"
									/>
								</div>
							</div>

							<div className={homeStyle.right}>
								<div className={homeStyle.btnSkip}>
									<Button type="link" onClick={handleSkip}>
										Skip <RightOutlined />
									</Button>
								</div>
								<div className={homeStyle.rightImg}>
									<img
										alt="Get started"
										src={`${PUBLIC_URL}/assets/getstarted-repinput.svg`}
										width="730"
									/>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className={homeStyle.carouselItem}>
							<div className={homeStyle.left}>
								<h2>
									Generate
									<br /> <span>Pitch Deck</span>
								</h2>
								<div className={homeStyle.desc}>
									You may view all the existing SDCA in the Dashboard. And
									quickly find a record through filter and sort.
								</div>
								<div className={homeStyle.thumb} style={{ marginTop: "140px" }}>
									<div className={homeStyle.buttons}>
										<Button onClick={handleSkip} type="primary">
											Start <RightOutlined />
										</Button>
									</div>
									<img
										alt="Get started"
										src={`${PUBLIC_URL}/assets/get-started-3.svg`}
										width="195"
									/>
								</div>
							</div>

							<div className={homeStyle.right}>
								<div className={homeStyle.btnSkip}>
									<Button type="link" onClick={handleSkip}>
										Skip <RightOutlined />
									</Button>
								</div>
								<div className={homeStyle.rightImg}>
									<img
										alt="Get started"
										src={`${PUBLIC_URL}/assets/getstarted-pitchdeck.svg`}
										width="668"
									/>
								</div>
							</div>
						</div>
					</div>
				</Carousel>
			</div>
		</div>
	);
}

export default GetStarted;
