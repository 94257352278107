import moment from "moment";

const ONE_HOUR_IN_MS = 3600000;

const getDisplayTime = (value: string) => {
	const momentCreatedAt = moment(value);
	const isRecent = moment().diff(momentCreatedAt) < ONE_HOUR_IN_MS;
	const momentValue = isRecent
		? momentCreatedAt.fromNow()
		: momentCreatedAt.calendar();
	return momentValue;
};

export { getDisplayTime };
