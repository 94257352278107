import {
	RECEIVE_SDCA_DRAFT_DATA,
	RECEIVE_SDCA_DRAFT_DATA_ERROR,
	RECEIVE_SDCA_LISTS_DATA,
	RECEIVE_SDCA_LISTS_DATA_ERROR,
	REQUEST_SDCA_DRAFT_DATA,
	REQUEST_SDCA_LISTS_DATA,
} from "@/core/constants";
import type {
	HomeActions,
	HomeReducer,
} from "@/core/reducers/homeReducer/types";

const initState: HomeReducer = {
	sdcaListsLoading: true,
	sdcaListsError: true,
	sdcaListsData: [],
	sdcaDraftLoading: true,
	sdcaDraftError: true,
	sdcaDraftData: [],
};

// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
const homeReducer = (state = initState, action: HomeActions): HomeReducer => {
	switch (action.type) {
		case REQUEST_SDCA_LISTS_DATA:
			return { ...state, sdcaListsLoading: true, sdcaListsError: false };
		case RECEIVE_SDCA_LISTS_DATA: {
			return {
				...state,
				sdcaListsLoading: false,
				sdcaListsData: action.payload,
				sdcaListsError: false,
			};
		}
		case RECEIVE_SDCA_LISTS_DATA_ERROR:
			return {
				...state,
				sdcaListsLoading: false,
				sdcaListsData: [],
				sdcaListsError: true,
			};

		case REQUEST_SDCA_DRAFT_DATA:
			return { ...state, sdcaDraftLoading: true, sdcaDraftError: false };
		case RECEIVE_SDCA_DRAFT_DATA: {
			return {
				...state,
				sdcaDraftLoading: false,
				sdcaDraftData: action.payload,
				sdcaDraftError: false,
			};
		}
		case RECEIVE_SDCA_DRAFT_DATA_ERROR:
			return {
				...state,
				sdcaDraftLoading: false,
				sdcaDraftData: [],
				sdcaDraftError: true,
			};
		default:
			return state;
	}
};
export default homeReducer;
