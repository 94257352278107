export const getPerctReductionValue = (perctReductionData, id) => {
	const { originalValue } = perctReductionData || {};
	let perctReductionValue = perctReductionData;
	if (originalValue && Array.isArray(originalValue)) {
		if (originalValue.length > 0) {
			const corrPerctReduction = originalValue.find((redu) => redu.id === id);
			perctReductionValue = corrPerctReduction ? corrPerctReduction.value : 60;
		} else {
			perctReductionValue = perctReductionData.value;
		}
	}
	return perctReductionValue;
};
