import { UPDATE_CAL_RESULTS } from "@/core/constants";
import type {
	ResultsActions,
	ResultsReducer,
} from "@/core/reducers/resultsReducer/types";

const initState: ResultsReducer = {};

const calResultsReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: ResultsActions,
): ResultsReducer => {
	switch (action.type) {
		case UPDATE_CAL_RESULTS: {
			return {
				...state,
				...action.payload,
			};
		}
		default:
			return state;
	}
};

export default calResultsReducer;
