export default {
  "main": "_main_1ysgk_5",
  "hero": "_hero_1ysgk_5",
  "inner": "_inner_1ysgk_11",
  "desc": "_desc_1ysgk_29",
  "features": "_features_1ysgk_35",
  "imgWrapper": "_imgWrapper_1ysgk_53",
  "btnWrapper": "_btnWrapper_1ysgk_62",
  "divider": "_divider_1ysgk_65",
  "footer": "_footer_1ysgk_68",
  "logoIcons": "_logoIcons_1ysgk_72",
  "followUs": "_followUs_1ysgk_81",
  "termsNPolicies": "_termsNPolicies_1ysgk_106",
  "copyRight": "_copyRight_1ysgk_137",
  "bgDotBlue": "_bgDotBlue_1ysgk_140",
  "responsive": "_responsive_1ysgk_146"
};import "ni:sha-256;n2ssgwSpAZyMN8A8cHksqx4IT5cIyKme3tJw7mKI_08";