import calculationReducer from "@/core/reducers/calculationReducer";
import formulaReducer from "@/core/reducers/formulaReducer";
import homeReducer from "@/core/reducers/homeReducer";
import loginReducer from "@/core/reducers/loginReducer";
import moduleReducer from "@/core/reducers/moduleReducer";
import opportunityReducer from "@/core/reducers/opportunityReducer";
import resultsReducer from "@/core/reducers/resultsReducer";
import sdcaReducer from "@/core/reducers/sdcaReducer";
import settingsReducer from "@/core/reducers/settingsReducer";
import uiReducer from "@/core/reducers/uiReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
	module: moduleReducer,
	calculation: calculationReducer,
	formula: formulaReducer,
	home: homeReducer,
	login: loginReducer,
	settings: settingsReducer,
	ui: uiReducer,
	results: resultsReducer,
	opportunities: opportunityReducer,
	sdca: sdcaReducer,
});

export default rootReducer;
