import { ChevronDownIcon } from "@radix-ui/react-icons";
import { InputNumber, Select } from "antd";
import React, { useState, useEffect } from "react";
import { numberFormatter, numberParser } from "../../utils";

const { Option } = Select;

const inPerYear = {
	perDay: 260,
	perWeek: 52,
	perMonth: 12,
	perYear: 1,
};

const DEFAULT_VALUE = {
	originalValue: 1,
	originalUnit: "perDay",
};
const convertInputFrequencyInitialValue = (initialValue) => {
	let objInitialValue = initialValue || DEFAULT_VALUE;
	if (initialValue && typeof initialValue === "string") {
		objInitialValue = JSON.parse(initialValue);
	}
	return objInitialValue;
};

const getDefaultValue = (defaultValue) => {
	const objDefaultVal =
		typeof defaultValue === "string" ? JSON.parse(defaultValue) : defaultValue;
	if (defaultValue && typeof objDefaultVal === "object") {
		return {
			value:
				objDefaultVal.originalValue * inPerYear[objDefaultVal.originalUnit],
		};
	}
	return {
		value: DEFAULT_VALUE.originalValue * inPerYear[DEFAULT_VALUE.originalUnit],
	};
};

function InputFrequency({ value = {}, onChange, initialValue }) {
	const [number, setNumber] = useState(
		initialValue?.originalValue || value?.originalValue || 1,
	);
	const [unit, setUnit] = useState(
		initialValue?.originalUnit || value?.originalUnit || "perDay",
	);

	const triggerChange = (changedValue) => {
		const newValue = {
			originalValue: number,
			originalUnit: unit,
			...value,
			...changedValue,
		};
		newValue.value = newValue.originalValue * inPerYear[newValue.originalUnit];
		onChange(newValue);
	};
	useEffect(() => {
		triggerChange({});
	}, []);

	const onNumberChange = (newNumber) => {
		if (Number.isNaN(newNumber)) {
			return;
		}
		setNumber(newNumber);

		triggerChange({
			originalValue: newNumber,
		});
	};

	const onUnitChange = (newUnit) => {
		setUnit(newUnit);

		triggerChange({
			originalUnit: newUnit,
		});
	};

	return (
		<span>
			<InputNumber
				value={value.originalValue || number}
				formatter={numberFormatter}
				parser={numberParser}
				onChange={onNumberChange}
				min="0"
				style={{
					width: 150,
				}}
			/>
			<Select
				value={value.originalUnit || unit}
				style={{
					width: 120,
					margin: "0 8px",
				}}
				onChange={onUnitChange}
				suffixIcon={<ChevronDownIcon />}
			>
				<Option value="perDay">Per Day</Option>
				<Option value="perWeek">Per Week</Option>
				<Option value="perMonth">Per Month</Option>
				<Option value="perYear">Per Year</Option>
			</Select>
		</span>
	);
}

export default InputFrequency;
export { convertInputFrequencyInitialValue, getDefaultValue };
