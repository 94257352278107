import { CALLBACK_PATH, oktaAuth } from "@/core/auth/adapters/okta";
import { SDCA } from "@/core/components";
import { CLIENT_URL } from "@/core/constants";
import { useEditAccessCheck } from "@/core/hooks/useEditAccessCheck";
import {
	CalculationPage,
	HomePage,
	LearnMorePage,
	ReportingPage,
	SettingsPage,
	SignInPage,
} from "@/core/pages";
import type OktaAuth from "@okta/okta-auth-js";
import { type OAuthError, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { message } from "antd";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";

function ErrorComponent({ error }: { error: Error }) {
	const oAuthError = error as OAuthError;
	message.error(
		`Okta error [${oAuthError.errorCode || "-"}]: ${
			oAuthError.errorSummary || "Unexpected error occurred."
		}`,
	);
	return <SignInPage />;
}

function OktaLoginCallback() {
	return <LoginCallback errorComponent={ErrorComponent} />;
}

function Router() {
	const history = useHistory();

	const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
		history.replace(toRelativeUrl(originalUri || "/", CLIENT_URL));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<SDCA>
				<Switch>
					<Route exact path="/" component={withRouter(SignInPage)} />
					<Route path={CALLBACK_PATH} component={OktaLoginCallback} />
					<SecureRoute exact path="/home" component={withRouter(HomePage)} />
					<SecureRoute
						exact
						path="/sdca"
						// @ts-expect-error: TS issue with library
						component={withRouter(useEditAccessCheck(CalculationPage))}
					/>
					<SecureRoute
						exact
						path="/sdca/:sdcaId"
						component={withRouter(CalculationPage)}
					/>
					<SecureRoute
						exact
						path="/settings"
						component={withRouter(SettingsPage)}
					/>
					<SecureRoute
						exact
						path="/reporting"
						// @ts-expect-error: TS issue with library
						component={withRouter(useEditAccessCheck(ReportingPage))}
					/>
					<SecureRoute
						exact
						path="/learn-more"
						component={withRouter(LearnMorePage)}
					/>
				</Switch>
			</SDCA>
		</Security>
	);
}

export default Router;
