import { setAuthToken, signOutApp } from "@/core/auth";
import { SDCA_TOKEN_TIME_KEY } from "@/core/components/TokenSession/utils";
import { UndoOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";

const EXPIRE_DURATION = 3600000; // 1 hour
const EXPIRE_ALERT_TIME = 300000; // 5 minutes
const EXPIRE_TRIGGER_TIME = 10000; // 10 seconds

const getTokenTime = () => {
	const sdcaTokenTime = localStorage.getItem(SDCA_TOKEN_TIME_KEY);
	if (sdcaTokenTime) {
		const intSdcaTokenTime = Number.parseInt(sdcaTokenTime, 10);
		return intSdcaTokenTime || 0;
	}
	return 0;
};

function TokenSession() {
	const [currentTime, setCurrentTime] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isExpireModalOpen, setIsExpireModalOpen] = useState(false);
	const tokenTime = getTokenTime();

	const handleRenewToken = async () => {
		await setAuthToken();
		message.success("Session has been renewed!");
	};
	const handleOk = async () => {
		await handleRenewToken();
		setIsModalOpen(false);
		Modal.destroyAll();
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		Modal.destroyAll();
	};

	const showModal = () => {
		if (!isModalOpen) {
			Modal.confirm({
				title: "Session is about to expire",
				content:
					"Current session will be expired very soon, do you want to renew the session?",
				onOk: handleOk,
				onCancel: handleCancel,
			});
		}
		setIsModalOpen(true);
	};

	const handleExpireOk = async () => {
		setIsExpireModalOpen(false);
		Modal.destroyAll();
		await signOutApp();
	};

	const showExpireModal = () => {
		if (!isExpireModalOpen) {
			Modal.destroyAll();
			Modal.info({
				title: "Session Expired",
				content:
					"Current session is expired, you will be logged out and need to sign in again.",
				onOk: handleExpireOk,
			});
		}
		setIsExpireModalOpen(true);
	};

	useEffect(() => {
		if (tokenTime !== 0) {
			if (currentTime - tokenTime > EXPIRE_DURATION - EXPIRE_TRIGGER_TIME) {
				showExpireModal();
			} else if (
				currentTime - tokenTime >
				EXPIRE_DURATION - EXPIRE_ALERT_TIME
			) {
				showModal();
			}
		}

		setTimeout(() => {
			setCurrentTime(new Date().getTime());
		}, 10000);
	}, [currentTime]);

	const sessionTotalSec = Math.round(
		(EXPIRE_DURATION - (currentTime - tokenTime)) / 1000,
	);
	const sessionSec = sessionTotalSec % 60;
	const sessionMin = (sessionTotalSec - sessionSec) / 60;

	return (
		<div className={styles.tokenSession}>
			Session: {sessionMin < 0 ? 0 : sessionMin}:
			{sessionSec < 0 ? 0 : sessionSec}{" "}
			<Button onClick={handleRenewToken}>
				<UndoOutlined />
			</Button>
		</div>
	);
}

export default TokenSession;
