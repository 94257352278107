export default {
  "col2Container": "_col2Container_9ef6s_1",
  "summaryDesc": "_summaryDesc_9ef6s_7",
  "chartSectionTitle": "_chartSectionTitle_9ef6s_12",
  "chartCard": "_chartCard_9ef6s_34",
  "perctImprovement": "_perctImprovement_9ef6s_41",
  "moduleChartContainer": "_moduleChartContainer_9ef6s_50",
  "moduleChartContainerCI": "_moduleChartContainerCI_9ef6s_51",
  "moduleChartContainerCD": "_moduleChartContainerCD_9ef6s_52",
  "moduleChartContainerFF": "_moduleChartContainerFF_9ef6s_53",
  "moduleChartContainerCC": "_moduleChartContainerCC_9ef6s_54",
  "moduleChartContainerSRM": "_moduleChartContainerSRM_9ef6s_55",
  "moduleChartContainerSTO": "_moduleChartContainerSTO_9ef6s_56",
  "moduleChartContainerCE": "_moduleChartContainerCE_9ef6s_57"
};import "ni:sha-256;xCraNWybQAjSkHTlM93zj5I2hLTwF5Ty_WJkcm8dNJw";