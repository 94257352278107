export default {
  "integerStepFormItemDiv": "_integerStepFormItemDiv_tjw9o_1",
  "integerStepFormItemLabel": "_integerStepFormItemLabel_tjw9o_11",
  "integerStep": "_integerStep_tjw9o_1",
  "slider": "_slider_tjw9o_27",
  "inputNumber": "_inputNumber_tjw9o_32",
  "SliderRoot": "_SliderRoot_tjw9o_38",
  "SliderLabelDiv": "_SliderLabelDiv_tjw9o_47",
  "SliderLabelFont": "_SliderLabelFont_tjw9o_54",
  "SliderTrack": "_SliderTrack_tjw9o_64",
  "SliderRange": "_SliderRange_tjw9o_71",
  "SliderThumb": "_SliderThumb_tjw9o_77"
};import "ni:sha-256;PbtYa-rNCPcUkp9t3mPr1QvyUKcvktVigzf70ceZs1I";