import { ShareAltOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

import { Loading } from "../../../components";
import { clearLocallyStoredBVA } from "../../../components/LocallyStoredBVA";
import { CLIENT_URL, PUBLIC_URL } from "../../../constants";
import step6Style from "./Calculation.module.scss";

const Step6Page = (props) => {
	const { serverResponse, isLoading } = props;
	const { statusCode, _id: resId } = serverResponse;

	const getURL = (urls) => (Array.isArray(urls) ? urls[urls.length - 1] : urls);

	const shareSDCAURL = (sdca) => {
		if (!sdca) {
			return;
		}
		message.success(
			`The URL for "${sdca.nameOfSoftwareDeliveryCapabilityAssessment}" has been copied to Clipboard, feel free to share with others!`,
		);
	};
	useEffect(() => {
		if (!isLoading && (resId || statusCode === 200)) {
			clearLocallyStoredBVA();
		}
	}, [isLoading, statusCode]);

	return isLoading ? (
		<div className={step6Style.loading}>
			<Loading />
			<p>
				Generating Slides Deck...
				<br /> It may take up to 1 minute...
			</p>
		</div>
	) : (
		<div className={step6Style.step6form}>
			<div className={step6Style.step6formLeft}>
				{!statusCode || statusCode < 300 ? (
					<>
						<h2>BVA has been submitted successfully!</h2>
						<p>You may view results in Google Slides. </p>
						<ul>
							<li>
								BVA Name:{" "}
								{serverResponse.nameOfSoftwareDeliveryCapabilityAssessment}
							</li>
							<li>
								Date Created:{" "}
								{moment(serverResponse.createdAt).format("YYYY-MM-DD")}
							</li>
						</ul>
						<div className={step6Style.btns}>
							<a
								target="_blank"
								href={getURL(serverResponse.gSlidesURL)}
								rel="noreferrer"
							>
								<Button type="primary">
									Open in Google Slides
									<img
										alt="Download"
										src={`${PUBLIC_URL}/assets/icon_download_white.svg`}
									/>
								</Button>
							</a>
							<Tooltip title="Copy the BVA URL to clipboard and share with others">
								<CopyToClipboard
									text={`${CLIENT_URL}/sdca/${serverResponse._id}`}
									onCopy={() => shareSDCAURL(serverResponse)}
								>
									<Button type="primary">
										Share the BVA URL
										<ShareAltOutlined />
									</Button>
								</CopyToClipboard>
							</Tooltip>

							<Link to="/home">
								<Button>Back to Home</Button>
							</Link>
						</div>
					</>
				) : (
					<>
						<h2>
							<span className={step6Style.warning}>
								<WarningOutlined />
							</span>
							BVA submission failed!
						</h2>
						<p>{serverResponse.message}</p>
						<div className={step6Style.btns}>
							<Link to="/home">
								<Button>Back to Home</Button>
							</Link>
						</div>
					</>
				)}
			</div>
			<div>
				<img alt="Main" src={`${PUBLIC_URL}/assets/icon_step4Image.svg`} />
			</div>
		</div>
	);
};

export { Step6Page };
