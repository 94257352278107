import App from "@/core/App";
import { ENV, LOCAL_ENV, PROD_ENV } from "@/core/constants";
import store from "@/core/store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BugsnagWrapper } from "src/bugsnag";

console.log("Env:", ENV);

if (ENV === PROD_ENV) {
	console.log = () => null;
}

if (ENV === LOCAL_ENV) {
	new EventSource("/esbuild").addEventListener("change", () =>
		window.location.reload(),
	);
}

const dom = document.getElementById("root");
if (dom) {
	ReactDOM.render(
		<BugsnagWrapper>
			<Provider store={store}>
				<App />
			</Provider>
		</BugsnagWrapper>,
		dom,
	);
}
