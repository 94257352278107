import { CLIENT_URL } from "@/core/constants";
import { testHarnessUser } from "@/core/utils";
import OktaAuth, { type AuthState } from "@okta/okta-auth-js";

export const CALLBACK_PATH = "/login/callback";

const IS_OKTA_PROD = process.env.REACT_APP_IS_OKTA_PROD === "true";

const OKTA_DEV_HOSTNAME = "dev-06350776.okta.com";
const OKTA_PROD_HOSTNAME = "harness.okta.com";

const OKTA_HOSTNAME = IS_OKTA_PROD ? OKTA_PROD_HOSTNAME : OKTA_DEV_HOSTNAME;

const OKTA_DEV_CLIENT_ID = "0oabo2if2sxJ2nlMM5d7";
const OKTA_PROD_CLIENT_ID = "0oanltgdmxSqZjuIp2p7";
const OKTA_CLIENT_ID = IS_OKTA_PROD ? OKTA_PROD_CLIENT_ID : OKTA_DEV_CLIENT_ID;

const OKTA_DEV_AUTH_SERVER = "ausboy4bp0Teyt9sx5d7";
const OKTA_PROD_AUTH_SERVER = "auspcm38pkvNJNtLR2p7";
const OKTA_AUTH_SERVER = IS_OKTA_PROD
	? OKTA_PROD_AUTH_SERVER
	: OKTA_DEV_AUTH_SERVER;

const oktaConfig = {
	clientId: OKTA_CLIENT_ID,
	issuer: `https://${OKTA_HOSTNAME}/oauth2/${OKTA_AUTH_SERVER}`,
	redirectUri: `${CLIENT_URL}${CALLBACK_PATH}`,
	scopes: ["openid", "profile", "email"],
};

export const oktaAuth = new OktaAuth(oktaConfig);

export const getUserOkta = async (authState: AuthState | null = null) => {
	let newAuthState = authState;
	if (!newAuthState) {
		await oktaAuth.authStateManager.updateAuthState();
		newAuthState = oktaAuth.authStateManager.getAuthState();
	}
	return newAuthState?.idToken?.claims;
};

export const signInOkta = async () => {
	await oktaAuth.signInWithRedirect();
};

export const signOutOkta = async () => {
	await oktaAuth.signOut();
};

export const setTokenOkta = async (): Promise<string> => {
	await oktaAuth.authStateManager.updateAuthState();
	const authState = oktaAuth.authStateManager.getAuthState();

	if (authState === null || !authState.isAuthenticated) {
		return "";
	}

	const user = await getUserOkta(authState);
	if (!user) {
		return "";
	}

	const isHarnessUser = testHarnessUser(user);
	if (!isHarnessUser) {
		return "";
	}

	const accessToken = authState.accessToken?.accessToken;
	if (!accessToken) {
		return "";
	}

	return accessToken;
};
