import { ALL_ROLES, API_BASE_URL, EDITOR_ROLE } from "@/core/constants";
import TIMEZONES, { DEFAULT_TIMEZONE } from "@/core/constants/timezone";
import type { UserPermissionDetails } from "@/core/reducers/loginReducer/types";
import { authAxios, showErrorMessage } from "@/core/utils/fetch";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Form, Input, Select, message } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import React, { type Ref, useEffect } from "react";

const { Option } = Select;
const styleHidden = { display: "none" };

const UserForm = React.forwardRef(
	(
		props: {
			preference?: boolean;
			updateUsersData: () => void;
			user: Partial<UserPermissionDetails>;
		},
		ref: Ref<FormInstance>,
	) => {
		const {
			preference,
			updateUsersData = () => null,
			user: { _id: id, email, role, timezone, displayName, timeUnit } = {},
		} = props;

		useEffect(() => {
			if (ref && typeof ref !== "function" && ref.current) {
				ref.current.setFieldsValue({
					email,
					role,
					timezone: timezone || DEFAULT_TIMEZONE,
					displayName,
					timeUnit: timeUnit || "hour",
				});
			}
		}, [id]);

		const onFinish = (values: Partial<UserPermissionDetails>) => {
			if (id) {
				authAxios({
					method: "PATCH",
					url: preference
						? `${API_BASE_URL}/users/preference`
						: `${API_BASE_URL}/users/${id}`,
					data: values,
				})
					.then(() => {
						updateUsersData();
						message.success(`User "${email}" has been updated`);
					})
					.catch((error) => {
						showErrorMessage(error);
					});
			} else {
				authAxios({
					method: "POST",
					url: `${API_BASE_URL}/users`,
					data: values,
				})
					.then(() => {
						updateUsersData();
						message.success("New user has been created");
						if (ref && typeof ref !== "function" && ref.current) {
							ref.current.resetFields();
						}
					})
					.catch((error) => {
						showErrorMessage(error);
					});
			}
		};

		return (
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				onFinish={onFinish}
				autoComplete="off"
				ref={ref}
			>
				<Form.Item
					label="User Email"
					name="email"
					initialValue=""
					rules={[
						{
							type: "email",
							message: "The input is not valid E-mail!",
						},
						{ required: true, message: "Please input your user email!" },
					]}
				>
					<Input disabled={!!id} />
				</Form.Item>

				<Form.Item
					label="User Role"
					name="role"
					initialValue={EDITOR_ROLE}
					rules={[{ required: true, message: "Please select your user role!" }]}
				>
					<Select
						style={{ width: 200 }}
						disabled={preference}
						suffixIcon={<ChevronDownIcon />}
					>
						{[...ALL_ROLES].sort().map((val: string) => (
							<Option value={val} key={val}>
								{val.charAt(0).toUpperCase() + val.slice(1)}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Full Name" name="displayName" initialValue="">
					<Input />
				</Form.Item>

				<Form.Item
					label="Time Effort Unit"
					name="timeUnit"
					initialValue="hour"
					style={styleHidden}
				>
					<Select style={{ width: 200 }} suffixIcon={<ChevronDownIcon />}>
						<Option value="minute">Minute</Option>
						<Option value="hour">Hour</Option>
					</Select>
				</Form.Item>

				<Form.Item
					label="Timezone"
					name="timezone"
					initialValue={DEFAULT_TIMEZONE}
				>
					<Select style={{ width: 200 }} suffixIcon={<ChevronDownIcon />}>
						{TIMEZONES.map((tz) => (
							<Option value={tz} key={tz}>
								{tz}
							</Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		);
	},
);

export { UserForm };
