import { EditOutlined } from "@ant-design/icons";
import {
	Button,
	Empty,
	Modal,
	Pagination,
	Popconfirm,
	Tooltip,
	message,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";

import { fetchUsersData } from "../../actions";
import { Loading, UserForm } from "../../components";
import {
	ADMINS,
	ADMIN_ROLE,
	API_BASE_URL,
	PAGE_SIZE,
	PUBLIC_URL,
} from "../../constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { authAxios, showErrorMessage } from "../../utils/fetch";
import usersStyle from "./users.module.scss";

const UsersPage = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [userData, setUserData] = useState({});
	const dispatch = useAppDispatch();
	const ref = React.createRef();

	const userInfo = useAppSelector((state) => state.login);
	const {
		permission: { role = "", email: userEmail = "" } = {},
	} = userInfo;
	const hasPermission =
		role.toLowerCase() === ADMIN_ROLE || ADMINS.includes(userEmail);

	const getUsers = () => {
		dispatch(fetchUsersData());
	};

	useEffect(() => {
		if (hasPermission) {
			getUsers();
		}
	}, [userInfo.permission.email]);

	const history = useHistory();

	const resUsersData = useAppSelector((state) => state.settings);
	const { usersLoading, usersData, usersError } = resUsersData;

	const columnsData = [
		{
			Header: "USER",
			accessor: "_id",
		},
		{
			Header: "ACCOUNT",
			accessor: "email",
		},
		{
			Header: "ROLE",
			accessor: "role",
		},
		{
			Header: "",
			accessor: "setting",
		},
	];

	const [isOpenModule, setOpenModule] = useState(false);
	const handleTaggleCloseWindow = () => {
		setOpenModule(!isOpenModule);
	};

	const handleDelete = (sdca) => {
		const { _id: id, email, permissionId } = sdca;
		authAxios({
			method: "DELETE",
			url: `${API_BASE_URL}/users/${id}/${permissionId}`,
		})
			.then(() => {
				getUsers();
				message.success(`User "${email}" has been deleted`);
			})
			.catch((error) => {
				const errMsg = `Something went wrong while deleting User "${email}"!`;
				showErrorMessage(error, errMsg);
			});
	};

	const confirm = (e, sdca) => {
		handleDelete(sdca);
	};

	const cancel = (e) => {};

	const showModal = () => {
		setUserData({});
		setIsModalVisible(true);
	};

	const editUser = (user) => {
		setUserData({ ...user });
		setIsModalVisible(true);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const handlePageChange = (page, pSize) => {
		setCurrentPage(page);
		setPageSize(pSize);
	};

	const handleOk = () => {
		const userForm = ref.current;
		if (userForm?.validateFields) {
			userForm
				.validateFields()
				.then(() => {
					userForm.submit();
					setIsModalVisible(false);
				})
				.catch((err) => {
					console.warn("User form validation failed.", err);
				});
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const TableCell = React.memo(({ colName, value, original }) => {
		if (colName === "setting") {
			return (
				<div className={`${usersStyle.actions} ${usersStyle.cellInner}`}>
					<span>
						<Tooltip title="Edit User">
							<EditOutlined onClick={() => editUser(original)} />
						</Tooltip>
					</span>
					<span>
						<Popconfirm
							title="Are you sure to delete this user?"
							onConfirm={(e) => confirm(e, original)}
							onCancel={cancel}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip title="Delete user">
								<img
									alt="Delete"
									src={`${PUBLIC_URL}/assets/icon_delete.svg`}
								/>
							</Tooltip>
						</Popconfirm>
					</span>
				</div>
			);
		}
		if (colName === "_id") {
			return (
				<div className={`${usersStyle.cellInner} ${usersStyle.multiRow}`}>
					<div className={usersStyle.title}>{original.displayName}</div>
					<div className={usersStyle.id}>ID: {value}</div>
				</div>
			);
		}
		if (colName === "role") {
			return (
				<div className={`${usersStyle.cellInner} ${usersStyle.capitalized}`}>
					{value}
				</div>
			);
		}
		return <div className={`${usersStyle.cellInner}`}>{value}</div>;
	});

	const TableTh = React.memo(({ column }) => {
		return <>{column.render("Header")}</>;
	});

	const Table = React.memo(({ columns, data }) => {
		const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
			useTable({
				columns,
				data,
			});

		return (
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={new Date().toISOString}
						>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()} key={new Date().toISOString}>
									<TableTh column={column} />
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()} key={new Date().toISOString}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()} key={new Date().toISOString}>
											<TableCell
												colName={cell.column.id}
												value={cell.value}
												original={row.original}
												handleTaggleCloseWindow={handleTaggleCloseWindow}
											/>
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	});

	const content = () => {
		if (!hasPermission && userInfo.permission?.email) {
			return (
				<div className={usersStyle.noPermission}>
					<img alt="Tools" src={`${PUBLIC_URL}/assets/tools.svg`} />
					<div>
						You don't have permission to Users page yet, please contact
						administrators:
					</div>
					<div>
						<a href="mailto:nicholas.russell@harness.io">
							nicholas.russell@harness.io
						</a>
					</div>
				</div>
			);
		}
		if (usersLoading) {
			return (
				<div className={usersStyle.loadingDiv}>
					<Loading />
				</div>
			);
		}
		if (usersData.length === 0) {
			return <Empty />;
		}
		if (!usersLoading && !usersError) {
			const usersDataLength = Object.keys(usersData).length;

			const startIndex = (currentPage - 1) * pageSize;
			const endIndex = currentPage * pageSize;
			const currentPageData = usersData.slice(startIndex, endIndex);
			return (
				<>
					<div>
						{hasPermission ? (
							<div className={usersStyle.searchBar}>
								<div className={usersStyle.searchBarLeft}>
									<Button type="primary" onClick={showModal}>
										+ Add New
									</Button>
								</div>
							</div>
						) : (
							<div className={usersStyle.searchBar} />
						)}
					</div>
					<div className={usersStyle.homeWrapper}>
						{hasPermission && usersDataLength > 0 && (
							<div className={usersStyle.tableWrapper}>
								<Table columns={columnsData} data={currentPageData} />
								<div className={usersStyle.paginationWrapper}>
									<Pagination
										defaultCurrent={currentPage}
										total={usersDataLength}
										defaultPageSize={pageSize}
										showSizeChanger
										onChange={handlePageChange}
									/>
								</div>
							</div>
						)}
						{hasPermission && usersDataLength === 0 && (
							<div className={usersStyle.emptyPage}>
								<img
									alt="Empty"
									src={`${PUBLIC_URL}/assets/icon_empty_page.svg`}
								/>
								<p>You do not have any Users yet</p>
								<Button type="primary" onClick={showModal}>
									+ Add New
								</Button>
							</div>
						)}
					</div>
				</>
			);
		}
		if (usersError && !usersLoading) {
			return null;
		}
		return null;
	};

	return (
		<>
			{content()}
			<Modal
				title="Add New User"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				okText="Save"
			>
				<UserForm ref={ref} updateUsersData={getUsers} user={userData} />
			</Modal>
		</>
	);
};

const Users = React.memo(UsersPage);

export default Users;
