export const LOCAL_ENV = "local";
export const DEV_ENV = "dev";
export const PROD_ENV = "production";

export const ENV = process.env.REACT_APP_NODE_ENV || "";

export const { PUBLIC_URL } = process.env;

export const CLIENT_URL = window.location.origin;
let tempBaseUrl = "";
if (ENV === LOCAL_ENV) {
	tempBaseUrl = "http://localhost:3001/api";
} else if ([DEV_ENV, PROD_ENV].includes(ENV)) {
	tempBaseUrl = `${CLIENT_URL}/api`;
}
export const API_BASE_URL = tempBaseUrl;

export const ADMINS = [
	"guo.wei@harness.io",
	"wei.guo@harness.io",
	"jyoti@harness.io",
];

export const UPDATE_STEP = "UPDATE_STEP";
export const UPDATE_INFORMATION_DATA = "UPDATE_INFORMATION_DATA";
export const RESET_INFORMATION_DATA = "RESET_INFORMATION_DATA";
export const UPDATE_CAL_RESULTS = "UPDATE_CAL_RESULTS";
export const SELECT_MODULE = "SELECT_MODULE";
export const PAGE_SIZE = 20;

export const REQUEST_GET_USER_BY_EMAIL = "REQUEST_GET_USER_BY_EMAIL";
export const RECEIVE_GET_USER_BY_EMAIL = "RECEIVE_GET_USER_BY_EMAIL";
export const RECEIVE_GET_USER_BY_EMAIL_ERROR =
	"RECEIVE_GET_USER_BY_EMAIL_ERROR";

export const REQUEST_SDCA_LISTS_DATA = "REQUEST_SDCA_LISTS_DATA";
export const RECEIVE_SDCA_LISTS_DATA = "RECEIVE_SDCA_LISTS_DATA";
export const RECEIVE_SDCA_LISTS_DATA_ERROR = "RECEIVE_SDCA_LISTS_DATA_ERROR";

export const REQUEST_SDCA_DATA = "REQUEST_SDCA_DATA";
export const RECEIVE_SDCA_DATA = "RECEIVE_SDCA_DATA";
export const RECEIVE_SDCA_DATA_ERROR = "RECEIVE_SDCA_DATA_ERROR";

export const REQUEST_SDCA_DRAFT_DATA = "REQUEST_SDCA_DRAFT_DATA";
export const RECEIVE_SDCA_DRAFT_DATA = "RECEIVE_SDCA_DRAFT_DATA";
export const RECEIVE_SDCA_DRAFT_DATA_ERROR = "RECEIVE_SDCA_DRAFT_DATA_ERROR";

export const REQUEST_OPPORTUNITY = "REQUEST_OPPORTUNITY";
export const RECEIVE_OPPORTUNITY = "RECEIVE_OPPORTUNITY";
export const RECEIVE_OPPORTUNITY_ERROR = "RECEIVE_OPPORTUNITY_ERROR";

export const SAVE_INVALID_FORM_ID = "SAVE_INVALID_FORM_ID";

export const REQUEST_MODULE_DATA = "REQUEST_MODULE_DATA";
export const RECEIVE_MODULE_DATA = "RECEIVE_MODULE_DATA";
export const RECEIVE_MODULE_DATA_ERROR = "RECEIVE_MODULE_DATA_ERROR";
export const RESET_MODULE_DATA = "RESET_MODULE_DATA";

export const REQUEST_FORMULAS = "REQUEST_FORMULAS";
export const RECEIVE_FORMULAS = "RECEIVE_FORMULAS";
export const RECEIVE_FORMULAS_ERROR = "RECEIVE_FORMULAS_ERROR";
export const COMPLETE_BATCH_FORMULAS = "COMPLETE_BATCH_FORMULAS";

export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_USERS_ERROR = "RECEIVE_USERS_ERROR";

export const REQUEST_UI = "REQUEST_UI";
export const RECEIVE_UI_ERROR = "RECEIVE_UI_ERROR";
export const RECEIVE_BATCH_UI = "RECEIVE_BATCH_UI";
export const COMPLETE_BATCH_UI = "COMPLETE_BATCH_UI";

export const step1Matrix = {
	nameOfSoftwareDeliveryCapabilityAssessment:
		"nameOfSoftwareDeliveryCapabilityAssessment",
	nameOfBusinessUnit: "nameOfBusinessUnit",
	opportunity: "opportunity",
	description: "description",
	bvType: "bvType",
} as const;

export const CURRENCY_SYMBOL = "$";

export const BAR_COLORS = [
	"#0b5393",
	"#3e85c6",
	"#70a8dc",
	"#9fc5e9",
	"#d0e2f3",
	"#acacac",
	"#cccccc",
	"#efefef",
] as const;

export const VIEWER_ROLE = "viewer";
export const EDITOR_ROLE = "editor";
export const ADMIN_ROLE = "admin";

export const ALL_ROLES = [VIEWER_ROLE, EDITOR_ROLE, ADMIN_ROLE] as const;
export const EDIT_PERMISSION_ROLES = [ADMIN_ROLE, EDITOR_ROLE] as const;
export const VIEW_PERMISSION_ROLES = [
	...EDIT_PERMISSION_ROLES,
	VIEWER_ROLE,
] as const;
