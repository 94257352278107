import type { TokenTypes } from "@/core/auth/types";

export const SDCA_TOKEN_KEY = "sdcaToken";
export const SDCA_TOKEN_TIME_KEY = "sdcaTokenTime";
export const SDCA_TOKEN_AUTH_TYPE_KEY = "sdcaTokenAuthType";

export const setToken = (sdcaToken: string) => {
	localStorage.setItem(SDCA_TOKEN_KEY, sdcaToken);
	localStorage.setItem(SDCA_TOKEN_TIME_KEY, new Date().getTime().toString());
};

export const getToken = () => localStorage.getItem(SDCA_TOKEN_KEY);

export const setTokenType = (authType: TokenTypes) => {
	localStorage.setItem(SDCA_TOKEN_AUTH_TYPE_KEY, authType);
};

export const clearToken = () => {
	localStorage.removeItem(SDCA_TOKEN_KEY);
	localStorage.removeItem(SDCA_TOKEN_TIME_KEY);
	localStorage.removeItem(SDCA_TOKEN_AUTH_TYPE_KEY);
};
