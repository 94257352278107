import * as Slider from "@radix-ui/react-slider";
import { InputNumber } from "antd";
import React from "react";

import styles from "./IntegerStep.module.scss";

class IntegerStep extends React.Component {
	constructor(props) {
		super(props);
		const { initialValue, value } = this.props;
		this.state = {
			inputValue: Number.parseInt(initialValue, 10) || value || 50,
		};
		this.trigerChange = this.trigerChange.bind(this);
	}

	trigerChange(newValue) {
		const { onChange } = this.props;
		this.setState({
			inputValue: newValue,
		});
		onChange(newValue);
	}

	render() {
		const { inputValue } = this.state;
		const { value } = this.props;
		return (
			<div className={styles.integerStep}>
				<span className={styles.slider}>
					<Slider.Root
						className={styles.SliderRoot}
						max={100}
						min={1}
						step={1}
						onValueChange={(value) => this.trigerChange(value[0])}
						value={[value || (typeof inputValue === "number" ? inputValue : 0)]}
					>
						<Slider.Track className={styles.SliderTrack}>
							<Slider.Range className={styles.SliderRange} />
						</Slider.Track>
						<Slider.Thumb className={styles.SliderThumb} aria-label="Volume" />
					</Slider.Root>
					<div className={styles.SliderLabelDiv}>
						<span className={styles.SliderLabelFont}>1%</span>
						<span className={styles.SliderLabelFont}>100%</span>
					</div>
				</span>
				<span className={styles.inputNumber}>
					<InputNumber
						min={1}
						max={100}
						style={{ margin: "0 0 0 16px", width: "66%" }}
						value={value || inputValue}
						onChange={this.trigerChange}
					/>
					<span> %</span>
				</span>
			</div>
		);
	}
}

export default IntegerStep;
