import {
	RECEIVE_FORMULAS,
	RECEIVE_FORMULAS_ERROR,
	REQUEST_FORMULAS,
} from "@/core/constants";
import type {
	FormulaReducer,
	FormulasActions,
} from "@/core/reducers/formulaReducer/types";

const initState: FormulaReducer = {
	loading: false,
	error: false,
	data: {},
};

const formulaReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
	state = initState,
	action: FormulasActions,
): FormulaReducer => {
	switch (action.type) {
		case REQUEST_FORMULAS:
			return { ...state, loading: true, error: false };
		case RECEIVE_FORMULAS: {
			return {
				...state,
				loading: false,
				data: { ...state.data, [action.module]: action.payload },
				error: false,
			};
		}
		case RECEIVE_FORMULAS_ERROR:
			return {
				...state,
				loading: false,
				data: { ...state.data },
				error: true,
			};
		default:
			return state;
	}
};

export default formulaReducer;
