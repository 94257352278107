import { Modal, message } from "antd";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import {
	fetchFormulas,
	fetchModuleData,
	fetchOpportunityData,
	fetchSDCAData,
	resetInformationData,
	resetModule,
	saveInvalidFormID,
	selectModule,
	updateInformationData,
	updateStep,
} from "../../../actions";
import { setAuthToken } from "../../../auth";
import { LeftNav, StepTracker } from "../../../components";
import LocallyStoredBVA, {
	setLocallyStoredBVA,
} from "../../../components/LocallyStoredBVA";
import { API_BASE_URL, step1Matrix } from "../../../constants";
import { DEFAULT_TIMEZONE } from "../../../constants/timezone";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
	getSDCAFromSDCAData,
	getSelectedModuleFromSDCAData,
	round,
} from "../../../utils";
import {
	authAxios,
	getErrorObject,
	showErrorMessage,
} from "../../../utils/fetch";
import { Step1Page } from "./step1";
import { Step2Page } from "./step2";
import { Step3Page } from "./step3";
import { Step5Page } from "./step5";
import { Step6Page } from "./step6";
import { getPerctReductionValue } from "./utils";

function CalculationPage(props) {
	const {
		match: {
			params: { sdcaId } = {},
		} = {},
	} = props;
	const dispatch = useAppDispatch();

	const allModules = useAppSelector((state) => state.module);
	const getAllData = useAppSelector((state) => state.calculation);
	const getStep = getAllData?.step;
	const loginData = useAppSelector((state) => state.login);
	const sdcaListsData = useAppSelector((state) => state.home.sdcaListsData);
	const sdcaData = useAppSelector((state) => state.sdca.data);
	const results = useAppSelector((state) => state.results);
	const {
		permission: { email },
	} = loginData;

	const {
		[step1Matrix.nameOfSoftwareDeliveryCapabilityAssessment]: nameOfSDCA,
		bvType = "bva",
	} = getAllData;

	const [isLoading, setIsLoading] = useState(false);
	const [serverResponse, setServerResponse] = useState({});

	const handleOnClickStep = async (value) => {
		await setAuthToken();
		const ret = dispatch(updateStep(value));
		return ret;
	};

	useEffect(() => {
		dispatch(fetchModuleData());
		dispatch(fetchOpportunityData());
	}, []);
	useEffect(() => {
		if (sdcaId) {
			dispatch(fetchSDCAData(sdcaId));
		}
	}, [sdcaId]);
	useEffect(() => {
		if (sdcaData && sdcaId) {
			for (const key of Object.keys(sdcaData.modules)) {
				dispatch(fetchFormulas(key));
			}
			const selectedModule = getSelectedModuleFromSDCAData(sdcaData);
			const resetSDCAData = getSDCAFromSDCAData(sdcaData);
			dispatch(resetModule(selectedModule));
			dispatch(resetInformationData(resetSDCAData));
			handleOnClickStep(3);
		}
	}, [sdcaData]);

	useEffect(() => {
		if (!nameOfSDCA && getStep > 0 && !sdcaId) {
			handleOnClickStep(0);
		}
	}, [getAllData]);

	useEffect(() => {
		if (getStep !== null && getStep !== undefined && nameOfSDCA) {
			setLocallyStoredBVA(getAllData);
		}
	}, [getStep]);

	const handleOnUpdate = (value) => {
		return dispatch(updateInformationData(value));
	};

	const keepPreviousOwner = () =>
		new Promise((resolve, reject) => {
			const deckData = getAllData || {};
			const { createdBy } = deckData;
			if (!createdBy) {
				resolve(false);
			} else if (createdBy === email) {
				resolve(false);
			} else {
				const modal = Modal.confirm();
				try {
					modal.update({
						title: "Keep owner?!",
						content: `Do you want to keep ${createdBy} as the owner of this BVA?`,
						cancelText: "No",
						okText: "Yes",
						onCancel: () => {
							resolve(false);
						},
						onOk: () => {
							resolve(true);
						},
					});
				} catch (err) {
					reject(err);
				}
			}
		});

	const getSubmitData = async ({ isDraft = true } = {}) => {
		const deckData = getAllData || {};
		const {
			createdBy,
			modules: {
				common: {
					inputData: { timeUnitDivider = 60 } = {},
				} = {},
			} = {},
		} = deckData;
		const timeUnit = timeUnitDivider === 1 ? "minute" : "hour";

		// NOTE: All edited ROIs will be start as a draft. So we ask the user whether they want to keep previous owner in the draft and then it will be carried over to the deck
		let newCreatedBy = "";
		if (isDraft) {
			const isKeepPreviousOwner = await keepPreviousOwner();
			if (isKeepPreviousOwner) {
				newCreatedBy = createdBy;
			} else {
				newCreatedBy = email;
			}
			dispatch(updateInformationData({ createdBy: newCreatedBy }));
		} else {
			newCreatedBy = createdBy;
		}

		const data = {
			createdBy: newCreatedBy,
			createdUserTimeZone: deckData.createdUserTimeZone || DEFAULT_TIMEZONE,
			timeUnit,
			[step1Matrix.nameOfBusinessUnit]:
				deckData[step1Matrix.nameOfBusinessUnit],
			[step1Matrix.nameOfSoftwareDeliveryCapabilityAssessment]: nameOfSDCA,
			[step1Matrix.opportunity]: deckData[step1Matrix.opportunity],
			[step1Matrix.description]: deckData[step1Matrix.description],
			[step1Matrix.bvType]: deckData[step1Matrix.bvType],
		};

		data.modules = getAllData?.modules || {};
		if (data.modules.common && results) {
			const commonData = data.modules.common.inputData || {};
			const selectedModules = Object.keys(data.modules);
			const totalIn3Years = selectedModules.reduce(
				(total, mdl) => {
					const retTotal = total;
					const mdlUpperCase = mdl.toUpperCase();
					const valCostOfCurrentProcessCumuYear1 =
						results[`costOfCurrentProcessCumuYear1${mdlUpperCase}`];

					if (valCostOfCurrentProcessCumuYear1) {
						retTotal.totalCostOfCurrentProcessYear1 +=
							valCostOfCurrentProcessCumuYear1;
					}

					const valCostOfCurrentProcessCumuYear2 =
						results[`costOfCurrentProcessCumuYear2${mdlUpperCase}`];
					if (valCostOfCurrentProcessCumuYear2) {
						retTotal.totalCostOfCurrentProcessYear2 +=
							valCostOfCurrentProcessCumuYear2;
					}

					const valCostOfCurrentProcessCumuYear3 =
						results[`costOfCurrentProcessCumuYear3${mdlUpperCase}`];
					if (valCostOfCurrentProcessCumuYear3) {
						retTotal.totalCostOfCurrentProcessYear3 +=
							valCostOfCurrentProcessCumuYear3;
					}

					const valCostWithHarnessCumuYear1 =
						results[`costWithHarnessCumuYear1${mdlUpperCase}`];
					if (valCostWithHarnessCumuYear1) {
						retTotal.totalCostWithHarnessYear1 += valCostWithHarnessCumuYear1;
					}

					const valCostWithHarnessCumuYear2 =
						results[`costWithHarnessCumuYear2${mdlUpperCase}`];
					if (valCostWithHarnessCumuYear2) {
						retTotal.totalCostWithHarnessYear2 += valCostWithHarnessCumuYear2;
					}

					const valCostWithHarnessCumuYear3 =
						results[`costWithHarnessCumuYear3${mdlUpperCase}`];
					if (valCostWithHarnessCumuYear3) {
						retTotal.totalCostWithHarnessYear3 += valCostWithHarnessCumuYear3;
					}

					const valTimeEffortOfCurrentProcessCumuYear1 =
						results[`timeEffortOfCurrentProcessCumuYear1${mdlUpperCase}`];
					if (valTimeEffortOfCurrentProcessCumuYear1) {
						retTotal.totalTimeEffortOfCurrentProcessYear1 +=
							valTimeEffortOfCurrentProcessCumuYear1;
					}

					const valTimeEffortOfCurrentProcessCumuYear2 =
						results[`timeEffortOfCurrentProcessCumuYear2${mdlUpperCase}`];
					if (valTimeEffortOfCurrentProcessCumuYear2) {
						retTotal.totalTimeEffortOfCurrentProcessYear2 +=
							valTimeEffortOfCurrentProcessCumuYear2;
					}

					const valTimeEffortOfCurrentProcessCumuYear3 =
						results[`timeEffortOfCurrentProcessCumuYear3${mdlUpperCase}`];
					if (valTimeEffortOfCurrentProcessCumuYear3) {
						retTotal.totalTimeEffortOfCurrentProcessYear3 +=
							valTimeEffortOfCurrentProcessCumuYear3;
					}

					const valTimeEffortWithHarnessCumuYear1 =
						results[`timeEffortWithHarnessCumuYear1${mdlUpperCase}`];
					if (valTimeEffortWithHarnessCumuYear1) {
						retTotal.totalTimeEffortWithHarnessYear1 +=
							valTimeEffortWithHarnessCumuYear1;
					}

					const valTimeEffortWithHarnessCumuYear2 =
						results[`timeEffortWithHarnessCumuYear2${mdlUpperCase}`];
					if (valTimeEffortWithHarnessCumuYear2) {
						retTotal.totalTimeEffortWithHarnessYear2 +=
							valTimeEffortWithHarnessCumuYear2;
					}

					const valTimeEffortWithHarnessCumuYear3 =
						results[`timeEffortWithHarnessCumuYear3${mdlUpperCase}`];
					if (valTimeEffortWithHarnessCumuYear3) {
						retTotal.totalTimeEffortWithHarnessYear3 +=
							valTimeEffortWithHarnessCumuYear3;
					}

					return retTotal;
				},
				{
					totalCostOfCurrentProcessYear1: 0,
					totalCostOfCurrentProcessYear2: 0,
					totalCostOfCurrentProcessYear3: 0,
					totalCostWithHarnessYear1: 0,
					totalCostWithHarnessYear2: 0,
					totalCostWithHarnessYear3: 0,
					totalTimeEffortOfCurrentProcessYear1: 0,
					totalTimeEffortOfCurrentProcessYear2: 0,
					totalTimeEffortOfCurrentProcessYear3: 0,
					totalTimeEffortWithHarnessYear1: 0,
					totalTimeEffortWithHarnessYear2: 0,
					totalTimeEffortWithHarnessYear3: 0,
				},
			);

			selectedModules
				.filter((mdl) => mdl !== "common")
				.map((mdl) => {
					const mdlData = data.modules[mdl].inputData;
					const cateData = mdlData[`customCategories${mdl.toUpperCase()}`];
					const cateArray = cateData?.originalValue;
					const perctReductionData =
						mdlData[
							`perctReductionInCostOfCustomCategories${mdl.toUpperCase()}`
						];

					const timeEffortCustomCategories = cateArray.filter(
						(cate) => cate.categoryType === "Annual Time Effort",
					);
					const {
						salaryEmployeeFTEFullyBurdened,
						workHoursPerYear,
						minutesPerHour,
					} = commonData;
					const salaryFTEFullyBurnedPerMinute =
						salaryEmployeeFTEFullyBurdened /
						(workHoursPerYear * minutesPerHour);
					let timeEffortOfCustomCategories = 0;
					let timeEffortWithHarnessOfCustomCategories = 0;
					let annualCostOfCustomCategories = 0;
					let annualCostWithHarnessOfCustomCategories = 0;

					const customCategoriesTimeEffort = timeEffortCustomCategories.map(
						(cate) => {
							const perctReductionValue = getPerctReductionValue(
								perctReductionData,
								cate.id,
							);
							const cateTimeEffort =
								(cate.fteTime.value * cate.fteNumber) / timeUnitDivider;
							const cateTimeEffortWithHarness = round(
								cateTimeEffort * (1 - perctReductionValue / 100),
							);
							timeEffortOfCustomCategories += cateTimeEffort;
							timeEffortWithHarnessOfCustomCategories +=
								cateTimeEffortWithHarness;
							return {
								id: cate.id,
								categoryName: cate.categoryName,
								value: cateTimeEffort,
								withHarnessValue: cateTimeEffortWithHarness,
							};
						},
					);
					const customCategoriesCost = cateArray.map((cate) => {
						const perctReductionValue = getPerctReductionValue(
							perctReductionData,
							cate.id,
						);
						const calculatedCost = round(
							cate.fteTime.value *
								cate.fteNumber *
								salaryFTEFullyBurnedPerMinute,
						);
						const calculatedCostWithHarness = round(
							calculatedCost * (1 - perctReductionValue / 100),
						);
						if (cate.categoryType === "Annual Time Effort") {
							annualCostOfCustomCategories += calculatedCost;
							annualCostWithHarnessOfCustomCategories +=
								calculatedCostWithHarness;
							return {
								id: cate.id,
								categoryName: cate.categoryName,
								value: calculatedCost,
								withHarnessValue: calculatedCostWithHarness,
							};
						}
						const withHarnessValue = round(
							cate.cost * (1 - perctReductionValue / 100),
						);
						annualCostOfCustomCategories += cate.cost;
						annualCostWithHarnessOfCustomCategories += withHarnessValue;
						return {
							id: cate.id,
							categoryName: cate.categoryName,
							value: cate.cost,
							withHarnessValue,
						};
					});
					mdlData.customCategoriesTimeEffort = customCategoriesTimeEffort;
					mdlData.customCategoriesCost = customCategoriesCost;
					mdlData.timeEffortOfCustomCategories = round(
						timeEffortOfCustomCategories,
					);
					mdlData.timeEffortWithHarnessOfCustomCategories = round(
						timeEffortWithHarnessOfCustomCategories,
					);
					mdlData.annualCostOfCustomCategories = round(
						annualCostOfCustomCategories,
					);
					mdlData.annualCostWithHarnessOfCustomCategories = round(
						annualCostWithHarnessOfCustomCategories,
					);
				});

			const moreFields = {
				totalCostSavedWithHarnessYear1:
					totalIn3Years.totalCostOfCurrentProcessYear1 -
					totalIn3Years.totalCostWithHarnessYear1,
				totalCostSavedWithHarnessYear2:
					totalIn3Years.totalCostOfCurrentProcessYear2 -
					totalIn3Years.totalCostWithHarnessYear2,
				totalCostSavedWithHarnessYear3:
					totalIn3Years.totalCostOfCurrentProcessYear3 -
					totalIn3Years.totalCostWithHarnessYear3,
				totalTimeSavedWithHarnessYear1:
					totalIn3Years.totalTimeEffortOfCurrentProcessYear1 -
					totalIn3Years.totalTimeEffortWithHarnessYear1,
				totalTimeSavedWithHarnessYear2:
					totalIn3Years.totalTimeEffortOfCurrentProcessYear2 -
					totalIn3Years.totalTimeEffortWithHarnessYear2,
				totalTimeSavedWithHarnessYear3:
					totalIn3Years.totalTimeEffortOfCurrentProcessYear3 -
					totalIn3Years.totalTimeEffortWithHarnessYear3,
			};

			const evenMoreFields = {
				...moreFields,
				totalCostSavedWithHarnessOverall:
					moreFields.totalCostSavedWithHarnessYear1 +
					moreFields.totalCostSavedWithHarnessYear2 +
					moreFields.totalCostSavedWithHarnessYear3,
				totalTimeSavedWithHarnessOverall:
					moreFields.totalTimeSavedWithHarnessYear1 +
					moreFields.totalTimeSavedWithHarnessYear2 +
					moreFields.totalTimeSavedWithHarnessYear3,
			};

			data.modules.common.inputData = {
				...commonData,
				...totalIn3Years,
				...evenMoreFields,
				perctTimeImprovement: Math.round(
					((totalIn3Years.totalTimeEffortOfCurrentProcessYear1 -
						totalIn3Years.totalTimeEffortWithHarnessYear1) /
						totalIn3Years.totalTimeEffortOfCurrentProcessYear1) *
						100,
				),
				perctCostImprovement: Math.round(
					((totalIn3Years.totalCostOfCurrentProcessYear1 -
						totalIn3Years.totalCostWithHarnessYear1) /
						totalIn3Years.totalCostOfCurrentProcessYear1) *
						100,
				),
			};
		}

		const { selectedModule } = allModules;
		const selectedModules = Object.entries(selectedModule)
			.filter(([key, value]) => {
				return value;
			})
			.map(([key]) => key);
		selectedModules.push("common");
		const currentModules = Object.keys(data.modules);
		for (const mdl of currentModules) {
			if (!selectedModules.includes(mdl)) {
				delete data.modules[mdl];
			}
		}

		return data;
	};

	const handleDeleteDraft = (id) => {
		authAxios({
			method: "DELETE",
			url: `${API_BASE_URL}/drafts/${id}`,
		}).catch((error) => {
			showErrorMessage(
				error,
				`Something went wrong while deleting BVA/BVR Draft "${id}"! `,
			);
		});
	};

	const handleUpdateDraft = async (id) => {
		const data = await getSubmitData();
		authAxios({
			method: "PATCH",
			url: `${API_BASE_URL}/drafts/${id}`,
			data,
		})
			.then((res) => {
				message.success(
					`BVA/BVR Draft "${data.nameOfSoftwareDeliveryCapabilityAssessment}" has been updated successfully!`,
				);
			})
			.catch((error) => {
				showErrorMessage(
					error,
					`Something went wrong while updating BVA/BVR Draft "${id}"!`,
				);
			});
	};
	const handleSaveDraft = async () => {
		if (getAllData.draftId) {
			await handleUpdateDraft(getAllData.draftId);
			return;
		}
		const data = await getSubmitData();
		const requestOptions = {
			method: "POST",
			url: `${API_BASE_URL}/drafts`,
			data,
		};

		authAxios(requestOptions)
			.then(async (response) => {
				const {
					data: { _id: id } = {},
				} = response;
				message.success("BVA/BVR saved to draft successfully!");
				const draftId = id;
				if (draftId) {
					handleOnUpdate({ draftId });
				}
			})
			.catch((error) => {
				console.error("Save Draft Error:", error);
				showErrorMessage(error);
			});
	};

	const handleUpdateSDCA = async (id) => {
		const data = await getSubmitData({ isDraft: false });

		setIsLoading(true);

		try {
			const response = await authAxios({
				method: "PATCH",
				url: `${API_BASE_URL}/decks/${id}`,
				data,
			});
			const responseJSON = await response.data;
			const { draftId } = getAllData;
			if (draftId) {
				handleDeleteDraft(draftId);
			}
			setServerResponse(responseJSON);
			setIsLoading(false);
		} catch (error) {
			const objError = getErrorObject(error);
			setServerResponse(objError);
			setIsLoading(false);
			showErrorMessage(error);
		}
	};
	const handleCommitData = async () => {
		if (getAllData.sdcaId) {
			handleUpdateSDCA(getAllData.sdcaId);
			return;
		}
		const data = await getSubmitData({ isDraft: false });

		setIsLoading(true);
		try {
			const response = await authAxios({
				method: "POST",
				url: `${API_BASE_URL}/decks`,
				data,
			});
			const responseJSON = await response.data;
			const { draftId } = getAllData;
			if (draftId) {
				handleDeleteDraft(draftId);
			}
			setServerResponse(responseJSON);
			setIsLoading(false);
		} catch (error) {
			const objError = getErrorObject(error);
			setServerResponse(objError);
			setIsLoading(false);
			showErrorMessage(error);
		}
	};

	const handSelectModules = (value) => {
		dispatch(selectModule(value));
	};
	const handleOnBlur = (key, value) => {
		const valueType = typeof value;
		let valueIsValid;
		if (valueType === "string" && value !== "") {
			valueIsValid = true;
		} else if (
			valueType === "object" &&
			value &&
			Object.keys(value).length > 0
		) {
			valueIsValid = true;
		} else if (valueType === "number" && value) {
			valueIsValid = true;
		} else {
			valueIsValid = false;
		}

		if (!valueIsValid) {
			dispatch(saveInvalidFormID({ key, type: "add" }));
		} else {
			dispatch(saveInvalidFormID({ key, type: "remove" }));
		}
	};

	const handleValidation = (validKeysArr) => {
		for (let i = 0; i < validKeysArr.length; i++) {
			const item = validKeysArr[i];
			const key = item;
			const value = getAllData[item];
			handleOnBlur(key, value);
		}
	};
	return (
		<div className="pageWrapper">
			<Helmet>
				<title>Home | Business Value Assessment</title>
			</Helmet>
			<LeftNav />
			<main className="main" id="main">
				<div className="pageTop">
					<h1>
						{nameOfSDCA
							? `${bvType.toUpperCase()}: ${nameOfSDCA}`
							: "Create New Business Value"}
					</h1>
				</div>
				{!sdcaId && (
					<StepTracker active={getStep} handleOnClickStep={handleOnClickStep} />
				)}
				{getStep === 0 ? (
					<Step1Page
						handleOnClickStep={handleOnClickStep}
						handleOnUpdate={handleOnUpdate}
						handleValidation={handleValidation}
						handleOnBlur={handleOnBlur}
						allData={getAllData}
						step1Matrix={step1Matrix}
					/>
				) : null}

				{getStep === 1 ? (
					<Step2Page
						handSelectModules={handSelectModules}
						handleOnClickStep={handleOnClickStep}
						allData={getAllData}
						allModules={allModules}
					/>
				) : null}

				{getStep === 2
					? Object.keys(allModules.selectedModule).length > 0 && (
							<Step3Page
								handleOnClickStep={handleOnClickStep}
								handleOnUpdate={handleOnUpdate}
								handleSaveDraft={handleSaveDraft}
								selectedModuleData={allModules.selectedModule}
								allData={getAllData}
							/>
						)
					: null}

				{getStep === 3 ? (
					<Step5Page
						allData={getAllData}
						handleOnClickStep={handleOnClickStep}
						handleCommitData={handleCommitData}
						selectedModuleData={allModules.selectedModule}
						sdcaId={sdcaId}
						getSubmitData={getSubmitData}
					/>
				) : null}
				{getStep === 4 ? (
					<Step6Page
						isLoading={isLoading}
						handleOnClickStep={handleOnClickStep}
						serverResponse={serverResponse}
					/>
				) : null}
			</main>
			<LocallyStoredBVA allData={getAllData} />
		</div>
	);
}

export { CalculationPage };
