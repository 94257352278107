import { LeftNav } from "@/core/components";
import Preferences from "@/core/pages/Settings/Preferences";
import Users from "@/core/pages/Settings/users";
import { Tabs } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import styles from "./index.module.scss";

function Settings() {
	return (
		<div className="pageWrapper">
			<Helmet>
				<title>Settings | Business Value Assessment</title>
			</Helmet>
			<LeftNav />
			<main className="main">
				<div className="pageTop">
					<h1>Settings</h1>
					<p>Manage user accounts and preferences</p>
				</div>
				<div className={styles.settingsTabs}>
					<Tabs defaultActiveKey="users">
						<Tabs.TabPane tab="Users" key="users">
							<Users />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Preferences" key="preferences">
							<Preferences />
						</Tabs.TabPane>
					</Tabs>
				</div>
			</main>
		</div>
	);
}

const SettingsPage = React.memo(Settings);

export { SettingsPage };
