import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import resultStyle from "./Result.module.scss";

import { useAppSelector } from "../../../../../hooks/reduxHooks";
import {
	getDisplayName,
	getLHSVariable,
	numberFormatter,
} from "../../../../../utils";

const getFormatedResult = (value, unit, timeUnit) => {
	const isMinus = value < 0;
	const isLow = unit === "%" && value >= 0 && value < 20;
	let preUnit = "";
	let sufUnit = unit;
	switch (unit) {
		case "USD":
		case "$":
			preUnit = "$";
			sufUnit = "";
			break;
		case "minutes":
			sufUnit = `${timeUnit}s`;
			break;
		case "NA":
			preUnit = "";
			sufUnit = "";
			break;
		default:
	}
	return (
		<span
			className={`${isMinus ? resultStyle.minus : ""}${
				isLow ? resultStyle.low : ""
			}`}
		>
			{preUnit} {numberFormatter(value)} {sufUnit}{" "}
			{isMinus && (
				<Tooltip
					title={
						<>
							<strong>Your business case is negative</strong>
							<br />
							Your business case is negative as a result of the current state
							level of effort vs. the Harness licensing cost. Please double
							check that we are accounting for all level of effort in the
							customers current state and ensure that it exceeds the proposed
							license cost proposed.
						</>
					}
				>
					<WarningOutlined />
				</Tooltip>
			)}
			{isLow && (
				<Tooltip
					title={
						<>
							<strong>Your business case is weak</strong>
							<br />
							Your business case is weak as a result of the current state level
							of effort vs. the Harness licensing cost. Please double check that
							we are accounting for all level of effort in the customers current
							state and ensure that it exceeds the proposed license cost
							proposed.
						</>
					}
				>
					<InfoCircleOutlined />
				</Tooltip>
			)}
		</span>
	);
};

const SectionResult = React.memo((props) => {
	const { type, arrSectionFormulas } = props;

	const results = useAppSelector((state) => state.results);
	const allData = useAppSelector((state) => state.calculation);
	const {
		modules: {
			common: {
				inputData: { timeUnitDivider = 60 } = {},
			} = {},
		} = {},
	} = allData;
	const timeUnit = timeUnitDivider === 1 ? "minute" : "hour";

	return arrSectionFormulas.length > 0 ? (
		<div className={`${resultStyle.sectionResult} ${resultStyle[type]}`}>
			<h5>Preview Result</h5>
			<ul>
				{arrSectionFormulas.map((secFormula) => {
					const secFormulaItem = secFormula || {};
					const unit = secFormulaItem.Unit;
					const previewResult = results[getLHSVariable(secFormulaItem.Formula)];
					return (
						<li
							className={resultStyle.previewItem}
							key={secFormulaItem.Formula}
						>
							<p>{getDisplayName(secFormula)}</p>
							{getFormatedResult(previewResult, unit, timeUnit)}
						</li>
					);
				})}
			</ul>
		</div>
	) : null;
});

export { SectionResult, getFormatedResult };
