import * as DOMPurify from "dompurify";
import React from "react";
import { CURRENCY_SYMBOL } from "../constants";
import { initState as sdcaInitState } from "../reducers/calculationReducer";

const getAccountFromOpportunity = (oppo, classes) => {
	const sufFix = [
		"com",
		"net",
		"io",
		"org",
		"edu",
		"gov",
		"us",
		"co.uk",
		"cn",
		"fr",
		"ee",
		"nz",
		"in",
	];
	const sufFixLen = sufFix.length;
	let attemptCount = 0;
	const strAccount = oppo
		.split(/\||-|\//)[0]
		.trim()
		.toLowerCase();
	const strAccountTitle = strAccount.toUpperCase();
	return (
		<img
			alt="Account logo"
			src={`/logo/${strAccount}.png`}
			className={classes.accountLogo}
			onError={(err) => {
				const targetImg = err.target;
				if (targetImg && attemptCount < sufFixLen) {
					targetImg.src = `https://logo.clearbit.com/${strAccount}.${sufFix[attemptCount]}`;
					targetImg.alt = strAccountTitle;
					targetImg.title = strAccountTitle;
					attemptCount += 1;
				}
				if (targetImg?.parentElement && attemptCount === sufFixLen) {
					targetImg.outerHTML = `<span>${strAccountTitle}</span>`;
					attemptCount += 1;
				}
			}}
		/>
	);
};

const numberFormatter = (value) =>
	`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const numberParser = (value) => value.replace(/\$\s?|(,*)/g, "");
const currencyFormatter = (value) =>
	`${CURRENCY_SYMBOL}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const currencyParser = (value) => value.replace(/\$\s?|(,*)/g, "");

const intNumberFormatter = (series) => {
	const val =
		typeof series === "object" && series.value ? series.value : series;
	if (Number.isNaN(val) || !Number.isFinite(val)) {
		return "-";
	}
	return numberFormatter(Math.round(val));
};
const intCurrencyFormatter = (series) => {
	const val =
		typeof series === "object" && series.value ? series.value : series;
	if (Number.isNaN(val) || !Number.isFinite(val)) {
		return "-";
	}
	return currencyFormatter(Math.round(val));
};

const shortenedNumberFormatter = (series) => {
	const val = Math.round(typeof series === "object" ? series.value : series);
	if (val >= 1000000000) {
		return `${numberFormatter(Math.round(val / 1000000))}M`;
	}
	if (val >= 1000000) {
		return `${numberFormatter(Math.round(val / 1000))}K`;
	}
	return numberFormatter(val);
};
const shortenedCurrencyFormatter = (series) => {
	const val = Math.round(typeof series === "object" ? series.value : series);
	if (val >= 1000000000) {
		return `${currencyFormatter(Math.round(val / 1000000))}M`;
	}
	if (val >= 1000000) {
		return `${currencyFormatter(Math.round(val / 1000))}K`;
	}
	return currencyFormatter(val);
};

const intPercentageFormatter = (series) => {
	if (Number.isNaN(series) || !Number.isFinite(series)) {
		return "-%";
	}
	const val = typeof series === "object" ? series.value : series;

	return `${Math.round(val)}%`;
};

const convertPercentageFormatter = (series) => {
	if (Number.isNaN(series) || !Number.isFinite(series)) {
		return "-%";
	}
	const val = typeof series === "object" ? series.value : series;

	return `${Math.round(val * 100)}%`;
};

const testHarnessUser = (user) =>
	user &&
	((user.email || "").endsWith("@harness.io") ||
		user.email === "amourguo@gmail.com");

const flattenFormulas = (formulas) => {
	if (Array.isArray(formulas)) {
		return formulas;
	}
	if (typeof formulas === "object") {
		const arrFormulasData = Object.values(formulas);
		const arrFlattenFormulas = arrFormulasData.reduce(
			(arrFmls, sectionFmls) => {
				// biome-ignore lint/performance/noAccumulatingSpread: Not sure how to do this properly
				return [...arrFmls, ...sectionFmls];
			},
			[],
		);
		return arrFlattenFormulas;
	}
	return [];
};

const getLHSVariable = (strFormula) => {
	const LHSVar = (strFormula.split("=")[0] || "").trim();
	return LHSVar;
};

const getDisplayName = (formula) => {
	if (formula?.Name && formula.Name !== "NA") {
		return formula.Name;
	}
	if (formula?.Formula) {
		const variableName = getLHSVariable(formula.Formula);
		const displayName = variableName.replace(
			/[A-Z-_\&](?=[a-z0-9]+)|[A-Z-_\&]+(?![a-z0-9])/g,
			" $&",
		);
		return displayName;
	}
	return "-";
};

const flattenScope = (scope) => {
	const flatScope = {};
	const keys = Object.keys(scope);
	for (const item of keys) {
		const scopeItem = scope[item];
		if (scopeItem && typeof scopeItem === "object") {
			const scopeItemValue = scopeItem.value;
			const scopeItemCost = scopeItem.cost;
			if (scopeItemValue !== null && scopeItemValue !== undefined) {
				flatScope[item] = scopeItemValue;
			}
			if (scopeItemCost !== null && scopeItemCost !== undefined) {
				flatScope[`${item}Cost`] = scopeItemCost;
			}
		} else {
			flatScope[item] = scopeItem;
		}
	}
	return flatScope;
};

const sortModules = (modules, moduleOrder) => {
	const sortedModules = [...modules];
	sortedModules.sort((a, b) => {
		return (
			moduleOrder.findIndex((mdl) => mdl === a) -
			moduleOrder.findIndex((mdl) => mdl === b)
		);
	});
	return sortedModules;
};

const getSDCAFromSDCAData = (sdca) => {
	if (!sdca) {
		return sdcaInitState;
	}
	const {
		_id: id,
		gSlidesURL,
		createdBy,
		createdUserTimeZone,
		nameOfBusinessUnit,
		nameOfSoftwareDeliveryCapabilityAssessment,
		opportunity,
		description,
		modules,
		timeUnit,
		bvType,
	} = sdca;
	const isFromDraft = !gSlidesURL;
	const refinedSDCAData = {
		createdBy,
		createdUserTimeZone,
		nameOfBusinessUnit,
		nameOfSoftwareDeliveryCapabilityAssessment,
		opportunity,
		description,
		modules,
		timeUnit,
		bvType,
	};
	if (isFromDraft) {
		refinedSDCAData.draftId = id;
	} else {
		refinedSDCAData.sdcaId = id;
	}
	return refinedSDCAData;
};

const getSelectedModuleFromSDCAData = (sdca) => {
	const selectedModule = {};
	if (!sdca) {
		return selectedModule;
	}
	for (const key of Object.keys(sdca.modules)) {
		selectedModule[key] = true;
	}
	return selectedModule;
};

const round = (value, n = 2) => {
	const divider = 10 ** n;
	return Math.round(value * divider) / divider;
};

const sanitizeHtml = (htmlString) => DOMPurify.sanitize(htmlString);

export {
	getAccountFromOpportunity,
	currencyFormatter,
	currencyParser,
	numberFormatter,
	numberParser,
	intNumberFormatter,
	intCurrencyFormatter,
	intPercentageFormatter,
	convertPercentageFormatter,
	shortenedNumberFormatter,
	shortenedCurrencyFormatter,
	testHarnessUser,
	flattenFormulas,
	getDisplayName,
	getLHSVariable,
	flattenScope,
	sortModules,
	getSDCAFromSDCAData,
	getSelectedModuleFromSDCAData,
	round,
	sanitizeHtml,
};
