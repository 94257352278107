const TIMEZONES = [
	"America/Los_Angeles",
	"America/New_York",
	"America/Fortaleza",
	"Europe/Paris",
	"Europe/Rome",
	"Asia/Kolkata",
	"Asia/Singapore",
	"Asia/Shanghai",
] as const;

export const DEFAULT_TIMEZONE = TIMEZONES[0];
export default TIMEZONES;
