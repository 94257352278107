import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import styles from "./InputTimeUnit.module.scss";

const { Option } = Select;

const timeUnit = {
	minute: 1,
	hour: 60,
};
export const DEFAULT_TIME_UNIT = timeUnit.hour;

function InputTimeUnit({ value, onChange, initialValue }) {
	const [unit, setUnit] = useState(
		Number.parseInt(value || initialValue, 10) || DEFAULT_TIME_UNIT,
	);

	const triggerChange = (changedValue) => {
		const newValue = Number.parseInt(changedValue, 10);
		setUnit(newValue);
		onChange(newValue);
	};
	useEffect(() => {
		triggerChange(unit);
	}, []);

	return (
		<Select
			value={unit}
			className={styles.timeUnit}
			onChange={triggerChange}
			suffixIcon={<ChevronDownIcon />}
		>
			{Object.entries(timeUnit).map(([key, divider]) => (
				<Option key={key} value={divider} className={styles.timeUnitOption}>
					{key}
				</Option>
			))}
		</Select>
	);
}

export default InputTimeUnit;
