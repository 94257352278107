import { setAuthToken, signInApp } from "@/core/auth";
import { OKTA_TOKEN_TYPE } from "@/core/auth/constants";
import { PUBLIC_URL } from "@/core/constants";
import { useOktaAuth } from "@okta/okta-react";
import { Button } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import indexStyle from "./SignIn.module.scss";

function SignIn() {
	const history = useHistory();
	const location = useLocation();
	const { authState: oktaAuthState } = useOktaAuth();
	const { search } = location;
	let redirectURL = "/home";
	if (URLSearchParams) {
		const params = new URLSearchParams(search);
		const paramRedirectURL = params.get("redirect");
		if (paramRedirectURL) {
			redirectURL = paramRedirectURL;
		}
	}

	useEffect(() => {
		const asyncFunc = async () => {
			if (oktaAuthState?.isAuthenticated) {
				const success = await setAuthToken();
				if (success) {
					history.push(redirectURL);
				}
			}
		};
		asyncFunc();
	}, [oktaAuthState]);

	return (
		<div className={indexStyle.loginMain}>
			<Helmet>
				<title>Sign In | Business Value Assessment</title>
			</Helmet>
			<div className={indexStyle.loginMainLeft}>
				<div className={indexStyle.loginMainLeftInner}>
					<div className={indexStyle.logo}>
						<img
							alt="Harness logo"
							src={`${PUBLIC_URL}/assets/logo.svg`}
							width="120"
						/>
						Sales
					</div>
					<h2>
						Sign in - <span>ROI Calculator</span>
					</h2>
					<p>
						and get your pitch deck done.{" "}
						<Link to="/learn-more">
							Learn More{" "}
							<img
								alt="Open link"
								src={`${PUBLIC_URL}/assets/icon_link.svg`}
								width="10"
							/>
						</Link>
					</p>

					<div className={indexStyle.links}>
						<Button onClick={() => signInApp(OKTA_TOKEN_TYPE)}>
							Sign in with Okta
						</Button>
					</div>
					<p className={indexStyle.btmText}>
						No account?{" "}
						<a
							href="https://app.harness.io/auth/#/signup"
							target="_blank"
							rel="noreferrer"
						>
							Get Started
						</a>
					</p>
				</div>
			</div>
			<div className={`${indexStyle.loginMainRight} ${indexStyle.bgDotBlue}`}>
				<img alt="Sign In" src={`${PUBLIC_URL}/assets/sign-in-bg.svg`} />
			</div>
		</div>
	);
}
const SignInPage = React.memo(SignIn);
export { SignInPage };
