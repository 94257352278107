export default {
  "step1form": "_step1form_1fxrv_1",
  "dropdownBox": "_dropdownBox_1fxrv_27",
  "btns": "_btns_1fxrv_30",
  "step2form": "_step2form_1fxrv_40",
  "selectModules": "_selectModules_1fxrv_52",
  "illustration": "_illustration_1fxrv_59",
  "imgIlluModule": "_imgIlluModule_1fxrv_64",
  "illuDesc": "_illuDesc_1fxrv_83",
  "icon": "_icon_1fxrv_147",
  "selected": "_selected_1fxrv_163",
  "step3form": "_step3form_1fxrv_250",
  "step3formInner": "_step3formInner_1fxrv_250",
  "step5form": "_step5form_1fxrv_264",
  "step6form": "_step6form_1fxrv_275",
  "step6formLeft": "_step6formLeft_1fxrv_281",
  "warning": "_warning_1fxrv_316",
  "loading": "_loading_1fxrv_335",
  "breathe": "_breathe_1fxrv_1",
  "loadingDiv": "_loadingDiv_1fxrv_349"
};import "ni:sha-256;TF-O6uyNUeIQxwuAIdoMclDMIFEy25aeVby3HwzCn-g";