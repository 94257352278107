import React from "react";

import { Form, Input, InputNumber } from "antd";

import CustomCategories, {
	convertCustomCategoriesInitialValue,
} from "../../../../components/CustomCategories";
import FTEEnum, {
	convertFTEEnumInitialValue,
} from "../../../../components/FTEEnum";
import InputFrequency, {
	convertInputFrequencyInitialValue,
} from "../../../../components/InputFrequency";
import InputTime, {
	convertInputTimeInitialValue,
} from "../../../../components/InputTime";
import IntegerStep from "../../../../components/IntegerStep";
import IntegerStepCustomCategories, {
	convertIntegerStepCustomCategoriesInitialValue,
} from "../../../../components/IntegerStepCustomCategories";
import SelectAvailability, {
	DEFAULT_VALUE,
} from "../../../../components/SelectAvailability";
import SelectTools from "../../../../components/SelectTools";

import InputTimeUnit from "../../../../components/InputTimeUnit";
import {
	currencyFormatter,
	currencyParser,
	numberFormatter,
	numberParser,
} from "../../../../utils";

import integerStepCss from "../../../../components/IntegerStep/IntegerStep.module.scss";

const { TextArea } = Input;

const FieldComponent = ({ item, currenModule, updateFormData }) => {
	const helpText =
		!!item.HelpText && item.HelpText !== "NA" ? item.HelpText : null;
	let defaultValue = item.DefaultValue;
	const { Name, Required } = item;
	const isRequired = Required === "1" || Required === 1;
	switch (item.DataType) {
		case "Hidden":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={Number.parseInt(defaultValue, 10) || 0}
					tooltip={helpText}
					style={{ display: "none" }}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputNumber />
				</Form.Item>
			);
		case "Number":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={Number.parseInt(defaultValue, 10) || 0}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputNumber
						formatter={numberFormatter}
						parser={numberParser}
						min="0"
					/>
				</Form.Item>
			);
		case "Currency":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={Number.parseInt(defaultValue, 10) || 0}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputNumber
						formatter={currencyFormatter}
						parser={currencyParser}
						min="0"
					/>
				</Form.Item>
			);
		case "Percentage":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={Number.parseInt(defaultValue, 10) || 0}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputNumber
						addonAfter="%"
						style={{
							width: 120,
						}}
					/>
				</Form.Item>
			);
		case "InputTime":
			defaultValue = convertInputTimeInitialValue(defaultValue);
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputTime initialValue={defaultValue} />
				</Form.Item>
			);
		case "InputTimeUnit":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputTimeUnit initialValue={defaultValue} />
				</Form.Item>
			);
		case "InputFrequency":
			defaultValue = convertInputFrequencyInitialValue(defaultValue);
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<InputFrequency initialValue={defaultValue} />
				</Form.Item>
			);
		case "SelectAvailability":
			if (
				defaultValue &&
				typeof defaultValue === "string" &&
				defaultValue !== "NA" &&
				!Number.isNaN(defaultValue)
			) {
				defaultValue = Number.parseFloat(defaultValue);
			} else {
				defaultValue = DEFAULT_VALUE;
			}
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<SelectAvailability initialValue={defaultValue} />
				</Form.Item>
			);
		case "SelectTools":
			if (
				defaultValue &&
				typeof defaultValue === "string" &&
				defaultValue !== "NA" &&
				Array.isArray(JSON.parse(defaultValue))
			) {
				defaultValue = JSON.parse(defaultValue);
			} else {
				defaultValue = [];
			}
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<SelectTools initialValue={defaultValue} />
				</Form.Item>
			);
		case "Text":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue || ""}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<Input />
				</Form.Item>
			);
		case "TextArea":
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue || ""}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
				>
					<TextArea rows={3} />
				</Form.Item>
			);
		case "IntegerStep":
			return (
				<div className={integerStepCss.integerStepFormItemDiv}>
					<label className={integerStepCss.integerStepFormItemLabel}>
						% Improvement
					</label>
					<div style={{ display: "flex", gap: "10px" }}>
						<label style={{ flexGrow: "2" }}>{item.Name}</label>
						<Form.Item
							name={item.VariableName}
							key={item.VariableName}
							initialValue={defaultValue || 0}
							tooltip={helpText}
							rules={[
								{ required: isRequired, message: `${Name} is required!` },
							]}
							style={{ marginBottom: "0px", flexGrow: "1" }}
						>
							<IntegerStep initialValue={defaultValue || 0} />
						</Form.Item>
					</div>
				</div>
			);

		case "FTEEnum":
			defaultValue = convertFTEEnumInitialValue(defaultValue);
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
					style={{ marginBottom: "0px" }}
				>
					<FTEEnum initialValue={defaultValue} />
				</Form.Item>
			);
		case "CustomCategories":
			defaultValue = convertCustomCategoriesInitialValue(defaultValue);
			return (
				<Form.Item
					name={item.VariableName}
					key={item.VariableName}
					initialValue={defaultValue}
					tooltip={helpText}
					rules={[{ required: isRequired, message: `${Name} is required!` }]}
					style={{ marginBottom: "0px" }}
				>
					<CustomCategories
						initialValue={defaultValue}
						updateFormData={updateFormData}
						currenModule={currenModule}
					/>
				</Form.Item>
			);
		case "IntegerStepCustomCategories":
			defaultValue =
				convertIntegerStepCustomCategoriesInitialValue(defaultValue);
			return (
				<div className={integerStepCss.integerStepFormItemDiv}>
					<label className={integerStepCss.integerStepFormItemLabel}>
						% Improvement
					</label>
					<label>{item.Name}</label>
					<div>
						<Form.Item
							name={item.VariableName}
							key={item.VariableName}
							initialValue={defaultValue}
							tooltip={helpText}
							rules={[
								{ required: isRequired, message: `${Name} is required!` },
							]}
							style={{ marginBottom: "0px" }}
						>
							<IntegerStepCustomCategories
								initialValue={defaultValue}
								currenModule={currenModule}
								updateFormData={updateFormData}
							/>
						</Form.Item>
					</div>
				</div>
			);

		default:
			return <span>Unknow DataType - &quot;{item.DataType}&quot;</span>;
	}
};

export default FieldComponent;
