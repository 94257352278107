import {
	CalendarOutlined,
	CopyOutlined,
	FileExcelOutlined,
	FileWordOutlined,
	QuestionCircleOutlined,
	SearchOutlined,
	ShareAltOutlined,
	SnippetsOutlined,
} from "@ant-design/icons";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
	Button,
	Checkbox,
	Dropdown,
	Input,
	Menu,
	Modal,
	Popconfirm,
	Space,
	Table,
	Tooltip,
	message,
} from "antd";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
	fetchModuleData,
	fetchOpportunityData,
	fetchSDCADraftData,
	fetchSDCAListsData,
	resetInformationData,
	resetModule,
	updateStep,
} from "../../actions";
import { LeftNav, Loading } from "../../components";
import { clearLocallyStoredBVA } from "../../components/LocallyStoredBVA";
import {
	ADMIN_ROLE,
	API_BASE_URL,
	CLIENT_URL,
	EDIT_PERMISSION_ROLES,
	PUBLIC_URL,
	VIEWER_ROLE,
	VIEW_PERMISSION_ROLES,
} from "../../constants";
import { MODULE_NAMES } from "../../constants/modules";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { initState as calculationInitState } from "../../reducers/calculationReducer";
import {
	getAccountFromOpportunity,
	getSDCAFromSDCAData,
	getSelectedModuleFromSDCAData,
	sortModules,
} from "../../utils";
import { authAxios, showErrorMessage } from "../../utils/fetch";
import { getDisplayTime } from "../../utils/moment";
import GetStarted from "./GetStarted";
import homeStyle from "./Home.module.scss";

const availableModules = (modules) =>
	modules
		.filter((mdl) => !mdl.disabled)
		.map((mdl) => ({
			label: (
				<div className={homeStyle.dropdownModuleItem}>
					<ReactSVG
						src={`${PUBLIC_URL}/assets/icon_${mdl.id.toLowerCase()}Small.svg`}
						className={homeStyle.svgDiv}
					/>
					{mdl.name}
				</div>
			),
			value: mdl.id,
		}));

const getDeckURL = (gSlidesURL) =>
	Array.isArray(gSlidesURL) ? gSlidesURL[gSlidesURL.length - 1] : gSlidesURL;

const getNameFromEmail = (email) => {
	const strEmailName = email.split("@")[0];
	return <a href={`mailto:${email}`}>{strEmailName.replace(".", " ")}</a>;
};

function Home(props) {
	const { history: propsHistory } = props;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [onlyShowMyBVAs, setOnlyShowMyBVAs] = useState(true);
	const [searchShow, setsearchShow] = useState(false);
	const [searchText, setsearchText] = useState("");
	const [filterValue, setFilterValue] = useState("");
	const [filteredTableData, setFilteredTableData] = useState();
	const [objOpportunities, setObjOpportunities] = useState({});
	const [showCalendar, setShowCalendar] = useState(false);

	const dispatch = useAppDispatch();
	const permission = useAppSelector((state) => state.login.permission);
	const { modules, moduleOrder = [] } = useAppSelector((state) => state.module);
	const initialDataLoaded = useRef(false);

	const updateSDCAList = () => {
		dispatch(fetchSDCAListsData());
		dispatch(fetchSDCADraftData());
	};
	useEffect(() => {
		if (permission.email && !initialDataLoaded.current) {
			dispatch(fetchModuleData());
			dispatch(fetchOpportunityData());
		}
	}, [permission.email]);

	useEffect(() => {
		if (modules.length > 0) {
			updateSDCAList();
			initialDataLoaded.current = true;
		}
	}, [modules]);

	useEffect(() => {
		if (permission.role === VIEWER_ROLE) {
			setOnlyShowMyBVAs(false);
		}
	}, [permission.role]);

	const history = useHistory();

	const showModal = () => {
		setIsModalVisible(true);
	};
	const showCalendarModal = () => {
		setShowCalendar(true);
	};
	const hideCalendarModal = () => {
		setShowCalendar(false);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const searchExpand = () => {
		if (!searchText) {
			setsearchShow((current) => !current);
		}
	};

	const userInfo = useAppSelector((state) => state.login);
	const getAllHomeData = useAppSelector((state) => state.home);
	const opportunities = useAppSelector((state) => state.opportunities);
	const email = userInfo.permission?.email;
	const role = userInfo.permission?.role || "";
	const hasEditPermission = EDIT_PERMISSION_ROLES.includes(role);
	const hasViewPermission = VIEW_PERMISSION_ROLES.includes(role);

	const {
		sdcaListsLoading,
		sdcaListsData = [],
		sdcaListsError,
		sdcaDraftData = [],
	} = getAllHomeData;

	useEffect(() => {
		const newObjOppo = {};
		const arrOppo = opportunities.opportunities || [];
		for (const oppo of arrOppo) {
			newObjOppo[oppo.opportunity_id] = oppo.opportunity_name;
		}
		setObjOpportunities(newObjOppo);
	}, [opportunities]);

	const fullTableData = [
		...(Array.isArray(sdcaDraftData)
			? sdcaDraftData.map((item) => Object.assign(item, { isDraft: true }))
			: []),
		...(Array.isArray(sdcaListsData) ? sdcaListsData : []),
	].map((item) => Object.assign(item, { key: item._id }));
	const tableData = onlyShowMyBVAs
		? fullTableData.filter((sdca) => sdca.createdBy === email)
		: fullTableData;

	const handleOnClickStep = (value) => {
		dispatch(updateStep(value));
	};

	const handleDelete = (id, name) => {
		authAxios({
			method: "DELETE",
			url: `${API_BASE_URL}/decks/${id}`,
		})
			.then(() => {
				updateSDCAList();
				message.success(`BVA "${name}" has been deleted`);
			})
			.catch((error) => {
				showErrorMessage(error);
			});
	};

	const handleDeleteDraft = (id, name) => {
		authAxios({
			method: "DELETE",
			url: `${API_BASE_URL}/drafts/${id}`,
		})
			.then(() => {
				updateSDCAList();
				message.success(`BVA Draft "${name}" has been deleted`);
			})
			.catch((error) => {
				showErrorMessage(
					error,
					`Something went wrong while deleting BVA Draft "${name}"! `,
				);
			});
	};

	const confirmDeleteSDCA = (e, id, name) => {
		handleDelete(id, name);
	};

	const confirmDeleteDraft = (e, id, name) => {
		handleDeleteDraft(id, name);
	};
	const getConfirmDeleteFunc = (isDraft) =>
		isDraft ? confirmDeleteDraft : confirmDeleteSDCA;

	const onChange = (checkedValues) => {
		setFilterValue(checkedValues);
		if (checkedValues.length === 0) {
			if (filterValue) {
				const searchedData = tableData.filter(
					(sdca) =>
						sdca.nameOfSoftwareDeliveryCapabilityAssessment
							.toLowerCase()
							.includes(searchText.toLowerCase()) ||
						sdca.nameOfBusinessUnit
							.toLowerCase()
							.includes(searchText.toLowerCase()) ||
						sdca.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
						sdca.createdAt.toLowerCase().includes(searchText.toLowerCase()),
				);
				setFilteredTableData(searchedData);
			} else {
				setFilteredTableData();
			}
		} else {
			const filteredData = (searchText ? filteredTableData : tableData).filter(
				(sdca) =>
					checkedValues.find((checkedValue) => {
						return Object.keys(sdca.modules || {}).includes(checkedValue);
					}),
			);
			setFilteredTableData(filteredData);
		}
	};
	const searchTextChange = (event) => {
		let searchedData = [];
		setsearchText(event.target.value);
		if (!event.target.value) {
			if (filterValue) {
				const filteredData = tableData.filter((sdca) =>
					filterValue.find((checkedValue) => {
						return Object.keys(sdca.modules || {}).includes(checkedValue);
					}),
				);
				setFilteredTableData(filteredData);
			} else {
				setFilteredTableData();
			}
		} else {
			searchedData = (
				filterValue.length !== 0 ? filteredTableData : tableData
			).filter(
				(sdca) =>
					sdca.nameOfSoftwareDeliveryCapabilityAssessment
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					sdca.nameOfBusinessUnit
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					sdca.createdBy
						.toLowerCase()
						.includes(event.target.value.toLowerCase()) ||
					sdca.createdAt
						.toLowerCase()
						.includes(event.target.value.toLowerCase()),
			);
			setFilteredTableData(searchedData);
		}
	};

	const menu = (
		<Menu style={{ padding: "0px" }}>
			<Checkbox.Group
				options={availableModules(modules)}
				defaultValue={[""]}
				onChange={onChange}
				className={homeStyle.modulesCheckbox}
			/>
		</Menu>
	);

	const handleNewSDCA = () => {
		dispatch(
			resetInformationData({
				...calculationInitState,
				...{ createdBy: email },
			}),
		);
		dispatch(resetModule({}));
		propsHistory.push("/sdca");
		handleOnClickStep(0);
	};

	const editSDCA = (sdca) => {
		if (!sdca) {
			return;
		}
		const selectedModule = getSelectedModuleFromSDCAData(sdca);
		const resetSDCAData = getSDCAFromSDCAData(sdca);
		dispatch(resetModule(selectedModule));
		dispatch(resetInformationData(resetSDCAData));
		propsHistory.push("/sdca");
		handleOnClickStep(0);
	};

	const duplicateData = (sdca, suffix = "duplicated") => {
		if (!sdca) {
			return null;
		}
		const newSdca = { ...sdca };
		const resetSDCAData = getSDCAFromSDCAData(newSdca);
		resetSDCAData.duplicatedFrom = newSdca._id;
		if (resetSDCAData.draftId) {
			resetSDCAData.draftId = undefined;
		}
		if (resetSDCAData.sdcaId) {
			resetSDCAData.sdcaId = undefined;
		}
		resetSDCAData.createdBy = email;
		resetSDCAData.nameOfSoftwareDeliveryCapabilityAssessment += ` [${suffix}]`;
		return resetSDCAData;
	};

	const duplicateSDCA = (sdca) => {
		if (!sdca) {
			return;
		}

		const resetSDCAData = duplicateData(sdca);
		const selectedModule = getSelectedModuleFromSDCAData(resetSDCAData);
		clearLocallyStoredBVA();
		dispatch(resetModule(selectedModule));
		dispatch(resetInformationData(resetSDCAData));
		propsHistory.push("/sdca");
		handleOnClickStep(0);
	};

	const duplicateBVR = (sdca) => {
		if (!sdca) {
			return;
		}

		const resetSDCAData = duplicateData(sdca, "BVR");
		resetSDCAData.bvType = "bvr";
		const selectedModule = getSelectedModuleFromSDCAData(resetSDCAData);
		clearLocallyStoredBVA();
		dispatch(resetModule(selectedModule));
		dispatch(resetInformationData(resetSDCAData));
		propsHistory.push("/sdca");
		handleOnClickStep(0);
	};

	const shareSDCAURL = (sdca) => {
		if (!sdca) {
			return;
		}
		message.success(
			`The URL for "${sdca.nameOfSoftwareDeliveryCapabilityAssessment}" has been copied to Clipboard, feel free to share with others!`,
		);
	};

	const columnsData = [
		{
			title: "NAME",
			dataIndex: "nameOfSoftwareDeliveryCapabilityAssessment",
			key: "nameOfSoftwareDeliveryCapabilityAssessment",
			render: (text, record) => {
				const { __v: version, isDraft, description } = record || {};
				return (
					<div
						className={`${homeStyle.cellInner}`}
						style={{ cursor: "pointer" }}
						onClick={() => editSDCA(record)}
						onKeyDown={() => editSDCA(record)}
					>
						<div className={homeStyle.title}>
							<Tooltip title={description}>
								{text}
								{!!isDraft && <span className={homeStyle.draft}>Pending</span>}
								{version > 0 && (
									<span className={homeStyle.version}>v{version + 1}</span>
								)}
							</Tooltip>
						</div>
					</div>
				);
			},
		},
		{
			title: "TYPE",
			dataIndex: "bvType",
			key: "bvType",
			render: (text, record) => (
				<div
					className={`${homeStyle.cellInner} ${homeStyle.cellInnerCenter}`}
					style={{ cursor: "pointer" }}
					onClick={() => editSDCA(record)}
					onKeyDown={() => editSDCA(record)}
				>
					{(text || "bva").toUpperCase()}
				</div>
			),
		},
		{
			title: "ACCOUNT",
			dataIndex: "opportunity",
			key: "opportunity",
			render: (text, record) => (
				<div
					className={`${homeStyle.cellInner} ${homeStyle.cellInnerCenter}`}
					style={{ cursor: "pointer" }}
					onClick={() => editSDCA(record)}
					onKeyDown={() => editSDCA(record)}
				>
					{getAccountFromOpportunity(objOpportunities[text] || text, homeStyle)}
				</div>
			),
		},
		{
			title: "BUSINESS UNIT",
			dataIndex: "nameOfBusinessUnit",
			key: "nameOfBusinessUnit",
			render: (text, record) => (
				<div
					style={{ cursor: "pointer" }}
					onClick={() => editSDCA(record)}
					onKeyDown={() => editSDCA(record)}
				>
					{text}
				</div>
			),
		},
		{
			title: "CREATED AT",
			dataIndex: "createdAt",
			key: "createdAt",
			defaultSortOrder: "descend",
			sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
			render: (text, record) => (
				<div
					className={`${homeStyle.cellInner}`}
					style={{ cursor: "pointer" }}
					onClick={() => editSDCA(record)}
					onKeyDown={() => editSDCA(record)}
				>
					{getDisplayTime(text)}
				</div>
			),
		},
		{
			title: "BY",
			dataIndex: "createdBy",
			key: "createdBy",
			render: (text) => (
				<div className={`${homeStyle.cellInner} ${homeStyle.capitalized}`}>
					{getNameFromEmail(text)}
				</div>
			),
		},
		{
			title: "MODULES",
			dataIndex: "modules",
			key: "modules",
			render: (text, record) => (
				<div
					className={`${homeStyle.cellInner}`}
					style={{ cursor: "pointer" }}
					onClick={() => editSDCA(record)}
					onKeyDown={() => editSDCA(record)}
				>
					{sortModules(Object.keys(text || {}), moduleOrder).map((item) =>
						item === "common" ? null : (
							<Tooltip title={MODULE_NAMES[item]} key={item}>
								<span className={homeStyle.icon}>
									<ReactSVG
										src={`${PUBLIC_URL}/assets/icon_${item.toLowerCase()}Small.svg`}
									/>
								</span>
							</Tooltip>
						),
					)}
				</div>
			),
		},
		{
			title: "",
			dataIndex: "setting",
			key: "setting",
			render: (text, record) => {
				const {
					_id: id,
					isDraft,
					gSlidesURL,
					createdBy,
					nameOfSoftwareDeliveryCapabilityAssessment,
					bvType,
					chartImageURLs,
				} = record || {};
				const bvTypeDispText = (bvType || "bva").toUpperCase();
				const chartImageURL = chartImageURLs?.barChartCostComparison3Years;
				const confirmDelete = getConfirmDeleteFunc(isDraft);
				const isOwnerOrAdmin =
					role === ADMIN_ROLE ||
					email.toLowerCase() === createdBy.toLowerCase();
				return (
					<div className={`${homeStyle.actions} ${homeStyle.cellInner}`}>
						{!isDraft && (
							<>
								<span>
									<Tooltip
										title={`Copy the ${bvTypeDispText} URL to clipboard and share with others`}
									>
										<CopyToClipboard
											text={`${CLIENT_URL}/sdca/${record._id}`}
											onCopy={() => shareSDCAURL(record)}
										>
											<ShareAltOutlined />
										</CopyToClipboard>
									</Tooltip>
								</span>
								<span>
									<a
										href={getDeckURL(gSlidesURL)}
										target="_blank"
										rel="noreferrer"
									>
										<Tooltip
											title={
												<div>
													<p>Check out the deck</p>
													<img
														alt="Chart preview"
														src={chartImageURL}
														className={homeStyle.tooltipImg}
													/>
												</div>
											}
										>
											<img
												alt="Open"
												src={`${PUBLIC_URL}/assets/icon_download.svg`}
											/>
										</Tooltip>
									</a>
								</span>
								{hasEditPermission && bvType !== "bvr" && (
									<span>
										<Tooltip title="Create BVR upon BVA">
											<SnippetsOutlined onClick={() => duplicateBVR(record)} />
										</Tooltip>
									</span>
								)}
							</>
						)}
						{hasEditPermission && (
							<span>
								<Tooltip title={`Duplicate ${bvTypeDispText}`}>
									<CopyOutlined onClick={() => duplicateSDCA(record)} />
								</Tooltip>
							</span>
						)}
						{hasEditPermission && isOwnerOrAdmin && (
							<>
								<span>
									<Tooltip title="Edit BVA">
										<img
											alt="Edit"
											src={`${PUBLIC_URL}/assets/icon_edit.svg`}
											onClick={() => editSDCA(record)}
											onKeyDown={() => editSDCA(record)}
										/>
									</Tooltip>
								</span>

								<span>
									<Popconfirm
										title="Are you sure to delete this BVA?"
										onConfirm={(e) =>
											confirmDelete(
												e,
												id,
												nameOfSoftwareDeliveryCapabilityAssessment,
											)
										}
										okText="Yes"
										cancelText="No"
									>
										<Tooltip title="Delete BVA">
											<img
												alt="Delete"
												src={`${PUBLIC_URL}/assets/icon_delete.svg`}
											/>
										</Tooltip>
									</Popconfirm>
								</span>
							</>
						)}
					</div>
				);
			},
		},
	];

	const handleOnlyShowMyBVAsChange = () => {
		setOnlyShowMyBVAs(!onlyShowMyBVAs);
	};

	const handleDownloadJSON = () => {
		const timestamp = moment().format("YYYY-MM-DD_HH-mm-ss");
		const filename = `BVAs-${timestamp}.json`;
		authAxios({
			method: "GET",
			responseType: "blob",
			url: `${API_BASE_URL}/decks`,
			headers: {
				"Content-Disposition": `attachment; filename=${filename}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				message.success("Downloading the JSON file...");
				// create file link in browser's memory
				const href = window.URL.createObjectURL(new Blob([response.data]));

				// create "a" HTML element with href to file & click
				const link = document.createElement("a");
				link.href = href;
				link.setAttribute("download", filename); // or any other extension
				document.body.appendChild(link);
				link.click();

				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				window.URL.revokeObjectURL(href);
			})
			.catch((error) => {
				const errMsg = "Something went wrong while downloading the JSON file!";
				showErrorMessage(error, errMsg);
			});
	};

	const content = () => {
		if (sdcaListsLoading) {
			return <Loading />;
		}
		if (!sdcaListsLoading && !sdcaListsError) {
			const sdcaListsDataLength = Object.keys(tableData).length;
			return (
				<>
					<div className="pageTop">
						<div className="pageHeading">
							<h1>Business Value </h1>
							<div id="btnSchedulingCal">
								<Button type="primary" onClick={showCalendarModal}>
									<CalendarOutlined />
									BVA Office Hours
								</Button>
								<Modal
									title={null}
									centered
									visible={showCalendar}
									onOk={hideCalendarModal}
									onCancel={hideCalendarModal}
									footer={null}
									width={1080}
								>
									<iframe
										frameBorder={0}
										title="Book BVA Office Hours Appointment"
										src="https://calendar.google.com/calendar/appointments/AcZssZ11nuvvktdg1nDdehIsAKZixs_OKwY6Gr9Hh5w=?gv=true"
										width="100%"
										height={650}
									/>
								</Modal>
							</div>
						</div>

						<div className={homeStyle.sdcaDesc}>
							<span>
								Create your return on investment decks for your prospects and
								customers
							</span>
							<span className={homeStyle.getStarted}>
								<a
									href="https://docs.google.com/spreadsheets/d/1hnMRRGavrQHUmp4fDTjMS8_GqtP4Ahmq8zRjpgh6Sjk/edit#"
									target="_blank"
									rel="noreferrer"
								>
									<FileExcelOutlined />
									ROI Spreadsheet
								</a>
								<span>|</span>
								<a
									href="https://docs.google.com/document/d/1Uy_dtBnfh39xhvcukishbmXst1UI6Iv8LWWMmzkW8pk/edit#"
									target="_blank"
									rel="noreferrer"
								>
									<FileWordOutlined />
									Questions Doc
								</a>
								<span>|</span>
								<div
									onClick={showModal}
									onKeyDown={showModal}
									style={{ cursor: "pointer" }}
								>
									<QuestionCircleOutlined />
									Get Started
								</div>
								<Modal
									className="getStartedModal"
									width="1222px"
									visible={isModalVisible}
									onOk={handleOk}
									onCancel={handleCancel}
									footer={[
										<Button key="skip" type="primary" onClick={handleOk}>
											Skip
										</Button>,
									]}
								>
									<GetStarted handleSkip={handleOk} />
								</Modal>
							</span>
						</div>
						<div className={homeStyle.searchBar}>
							{hasEditPermission && (
								<div className={homeStyle.searchBarLeft}>
									<Button type="primary" onClick={handleNewSDCA}>
										+ Add New
									</Button>
								</div>
							)}

							{fullTableData.length > 0 && (
								<div className={homeStyle.searchBarMid}>
									<Checkbox
										onChange={handleOnlyShowMyBVAsChange}
										checked={onlyShowMyBVAs}
									>
										Only show BVAs created by me
									</Checkbox>
								</div>
							)}

							{sdcaListsDataLength < 1 && (
								<div className={homeStyle.searchBarMid} />
							)}
							{sdcaListsDataLength > 0 && (
								<div className={homeStyle.searchRight}>
									<Input
										placeholder="Search"
										prefix={<SearchOutlined className="site-form-item-icon" />}
										style={{ display: searchShow ? "flex" : "none" }}
										onChange={searchTextChange}
										value={searchText}
										onPressEnter={searchTextChange}
										onMouseLeave={searchExpand}
									/>

									<Button
										icon={<SearchOutlined />}
										className={homeStyle.dropdown}
										onClick={searchExpand}
										style={{ display: searchShow ? "none" : "block" }}
									/>

									<Dropdown overlay={menu} className={homeStyle.dropdown}>
										<div
											style={{ cursor: "pointer" }}
											onClick={(e) => e.preventDefault()}
											onKeyDown={(e) => e.preventDefault()}
										>
											<Space>
												Filter by Modules
												<div className={homeStyle.dropdownText}>
													<ChevronDownIcon />
												</div>
											</Space>
										</div>
									</Dropdown>
									{hasEditPermission && (
										<span className={homeStyle.export}>
											<div
												onClick={handleDownloadJSON}
												onKeyDown={handleDownloadJSON}
											>
												<Tooltip title="Export raw data in JSON format">
													<img alt="Export" src="/assets/icon_export.svg" />
												</Tooltip>
											</div>
										</span>
									)}
								</div>
							)}
						</div>
					</div>
					{!hasEditPermission && !hasViewPermission ? (
						<div className={homeStyle.noPermission}>
							<img alt="Tools" src={`${PUBLIC_URL}/assets/tools.svg`} />
							<div>
								You don't have permission to view or edit SDCAs yet, please
								contact administrators:
							</div>
							<div>
								<a href="mailto:nicholas.russell@harness.io">
									nicholas.russell@harness.io
								</a>
							</div>
						</div>
					) : (
						<div className={homeStyle.homeWrapper}>
							{sdcaListsDataLength > 0 && (
								<>
									<div className={homeStyle.tableWrapper}>
										<Table
											columns={columnsData}
											dataSource={filteredTableData || tableData}
										/>
									</div>
								</>
							)}
							{sdcaListsDataLength === 0 && hasEditPermission && (
								<div className={homeStyle.emptyPage}>
									<img
										alt="Empty page"
										src={`${PUBLIC_URL}/assets/icon_empty_page.svg`}
									/>
									<p>You do not have any BVA yet</p>
									<Button type="primary" onClick={handleNewSDCA}>
										+ Add New
									</Button>
								</div>
							)}
						</div>
					)}
				</>
			);
		}
		if (sdcaListsError && !sdcaListsLoading) {
			return null;
		}
		return null;
	};

	return (
		<div className="pageWrapper">
			<Helmet>
				<title>Home | Business Value Assessment</title>
			</Helmet>
			<LeftNav />
			<main className="main">{content()}</main>
		</div>
	);
}

const HomePage = React.memo(Home);

export { HomePage };
