import {
	COMPLETE_BATCH_UI,
	RECEIVE_BATCH_UI,
	RECEIVE_UI_ERROR,
	REQUEST_UI,
} from "@/core/constants";
import type { UiActions, UiReducer } from "@/core/reducers/uiReducer/types";

const initState: UiReducer = {
	loading: true,
	error: true,
	data: {},
};

// biome-ignore lint/style/useDefaultParameterLast: This is how Redux works
const uiReducer = (state = initState, action: UiActions): UiReducer => {
	switch (action.type) {
		case REQUEST_UI:
			return { ...state, loading: true, error: false };
		case RECEIVE_BATCH_UI: {
			return {
				...state,
				data: { ...state.data, [action.module]: action.payload },
			};
		}
		case COMPLETE_BATCH_UI:
			return {
				...state,
				loading: false,
				error: false,
			};
		case RECEIVE_UI_ERROR:
			return {
				...state,
				loading: false,
				data: { ...state.data },
				error: true,
			};
		default:
			return state;
	}
};
export default uiReducer;
