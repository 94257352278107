import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { resetInformationData, resetModule } from "../../actions";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { getSelectedModuleFromSDCAData } from "../../utils";

export const setLocallyStoredBVA = (bvaData) => {
	let strBvaData = bvaData;
	if (typeof bvaData !== "string") {
		strBvaData = JSON.stringify(bvaData);
	}
	localStorage.setItem("bvaData", strBvaData);
};

const getLocallyStoredBVA = () => {
	const strBvaData = localStorage.getItem("bvaData");
	if (!strBvaData) {
		return null;
	}
	const bvaData = JSON.parse(strBvaData);
	return bvaData;
};

export const clearLocallyStoredBVA = () => {
	localStorage.removeItem("bvaData");
};

const LocallyStoredBVA = ({ allData = {} }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useAppDispatch();

	const handleOk = () => {
		const locallyStoredBVA = getLocallyStoredBVA();
		if (locallyStoredBVA) {
			locallyStoredBVA.step = 0;
			locallyStoredBVA.fromRestore = true;
			if (locallyStoredBVA.draftId) {
				locallyStoredBVA.draftId = undefined;
			}
			if (locallyStoredBVA.sdcaId) {
				locallyStoredBVA.sdcaId = undefined;
			}
			const selectedModule = getSelectedModuleFromSDCAData(locallyStoredBVA);
			dispatch(resetModule(selectedModule));
			dispatch(resetInformationData(locallyStoredBVA));
		}
		setIsModalOpen(false);
		Modal.destroyAll();
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		Modal.destroyAll();
	};

	const showModal = () => {
		if (!isModalOpen) {
			Modal.confirm({
				title: "Found unsubmitted BVA",
				content:
					"Found unsubmitted BVA data, do you want to restore the unsubmitted BVA data?",
				onOk: () => handleOk(),
				onCancel: handleCancel,
			});
		}
		setIsModalOpen(true);
	};

	useEffect(() => {
		const { draftId: initDraftId, sdcaId: initSdcaId, step } = allData;
		const locallyStoredBVA = getLocallyStoredBVA();
		if (!initDraftId && !initSdcaId && step < 1 && locallyStoredBVA) {
			showModal();
		}
	}, []);

	return <></>;
};

export default LocallyStoredBVA;
