import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Button, Form, Input, Select } from "antd";
import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../hooks/reduxHooks";
import step1Style from "./Calculation.module.scss";

const { Option } = Select;
const { TextArea } = Input;
const formItemRules = [
	{
		required: true,
	},
];

function Step1Page(props) {
	const { handleOnClickStep, handleOnUpdate, allData, step1Matrix } = props;
	const opportunities = useAppSelector((state) => state.opportunities);
	const history = useHistory();
	const formEl = useRef(null);

	const options = (opportunities.opportunities || []).map((oppo) => (
		<Option key={oppo.opportunity_id} value={oppo.opportunity_id}>
			{oppo.opportunity_name}
		</Option>
	));
	const optionsType = [
		<Option key="bva" value="bva">
			BVA
		</Option>,
		<Option key="bvr" value="bvr">
			BVR
		</Option>,
	];

	const {
		draftId,
		sdcaId,
		duplicatedFrom,
		fromRestore,
		opportunity,
		nameOfSoftwareDeliveryCapabilityAssessment,
		nameOfBusinessUnit,
		description,
		bvType = "bva",
	} = allData;

	useEffect(() => {
		if (draftId || sdcaId || duplicatedFrom || fromRestore) {
			const formRef = formEl.current;
			if (formRef) {
				const formValues = allData
					? {
							opportunity,
							nameOfSoftwareDeliveryCapabilityAssessment,
							nameOfBusinessUnit,
							description,
							bvType,
						}
					: {};
				formRef.setFieldsValue(formValues);
			}
		}
	}, [fromRestore]);

	const handleFormSubmit = (allFields) => {
		if (handleOnUpdate) {
			handleOnUpdate(allFields);
		}
		handleOnClickStep(1);
	};

	return (
		<div className={step1Style.step1form}>
			<p>Please fill in the following information</p>
			<Form layout="vertical" onFinish={handleFormSubmit} ref={formEl}>
				<Form.Item
					label="Name of Business Value"
					name={step1Matrix.nameOfSoftwareDeliveryCapabilityAssessment}
					initialValue={
						allData[step1Matrix.nameOfSoftwareDeliveryCapabilityAssessment] ||
						""
					}
					rules={[...formItemRules, { max: 80 }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Select Type"
					name={step1Matrix.bvType}
					initialValue={allData[step1Matrix.bvType] || "bva"}
					rules={formItemRules}
				>
					<Select suffixIcon={<ChevronDownIcon />}>{optionsType}</Select>
				</Form.Item>
				<Form.Item
					label="Name of Business Unit"
					name={step1Matrix.nameOfBusinessUnit}
					initialValue={allData[step1Matrix.nameOfBusinessUnit] || ""}
					rules={formItemRules}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Select Opportunity"
					name={step1Matrix.opportunity}
					initialValue={allData[step1Matrix.opportunity] || ""}
					rules={formItemRules}
				>
					<Select
						showSearch
						placeholder="Type a keyword to filter the opportunities..."
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.props.children
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						}
						suffixIcon={<ChevronDownIcon />}
					>
						{options}
					</Select>
				</Form.Item>
				<Form.Item
					label="Description"
					name={step1Matrix.description}
					initialValue={allData[step1Matrix.description] || ""}
				>
					<TextArea rows={3} />
				</Form.Item>
				<div className="btns">
					<Button
						type="button"
						onClick={() => {
							handleOnUpdate({
								[step1Matrix.nameOfSoftwareDeliveryCapabilityAssessment]: "",
							});
							handleOnUpdate({ [step1Matrix.nameOfBusinessUnit]: "" });
							handleOnUpdate({ [step1Matrix.opportunity]: {} });
							history.push("/home");
						}}
					>
						Cancel
					</Button>
					<Button type="primary" htmlType="submit">
						Continue ❯
					</Button>
				</div>
			</Form>
		</div>
	);
}

export { Step1Page };
