import { EditOutlined } from "@ant-design/icons";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Button, Collapse, Empty, Form, Input } from "antd";
import { isEmpty } from "lodash";
import { ConstantNode, evaluate, parse } from "mathjs";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { fetchFormulas, updateCalResults } from "../../../../actions";
import {
	flattenFormulas,
	flattenScope,
	getLHSVariable,
	numberFormatter,
	sanitizeHtml,
} from "../../../../utils";
import FieldComponent from "../components";
import { Result, SectionResult } from "./components";

import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import step3FormCss from "./step3Form.module.scss";

const { Panel } = Collapse;
const { TextArea } = Input;

function Step3Module(props) {
	const {
		currenModule,
		onClickInternalStep,
		useFixedOnScroll,
		handleOnUpdate,
		allData,
	} = props;
	const { draftId, sdcaId, duplicatedFrom, fromRestore } = allData || {};
	const [resultIsOpen, setResultIsOpen] = useState(true);
	const [flatScopeState, setFlatScopeState] = useState({});
	const formEl = useRef(null);
	const dispatch = useAppDispatch();
	const isFixed = useFixedOnScroll();

	const rawFormulas = useAppSelector((state) => state.formula);
	const resUI = useAppSelector((state) => state.ui);
	const results = useAppSelector((state) => state.results);
	const { data: formulas, loading: formulasLoading } = rawFormulas;

	const {
		loading: uiLoading,
		data: {
			[currenModule]: { layout: ui = {} } = {},
		},
	} = resUI;

	const loading = formulasLoading || uiLoading;

	const arrUI = Object.entries(ui);
	const {
		numberOfServicesApplicationsYear1,
		numberOfServicesApplicationsYear2,
		numberOfServicesApplicationsYear3,
		costOfHarnessCumuYear1,
		costOfHarnessCumuYear2,
		costOfHarnessCumuYear3,
	} = results;

	const updateFormData = async (allFields) => {
		const allModules = allData.modules || {};
		const currentModuleItem = allModules[currenModule] || {};
		const commonModuleItem = allModules.common || {};
		const commonInputData = { ...(commonModuleItem.inputData || {}) };
		const keys = Object.keys(commonInputData);
		for (const commonDataItem of keys) {
			if (!commonInputData[commonDataItem]) {
				delete commonInputData[commonDataItem];
			}
		}

		if (currenModule && handleOnUpdate) {
			const moduleFieldValues = {
				inputData: {
					...(currentModuleItem.inputData || {}),
					...allFields,
				},
			};
			const modules = { ...allModules, [currenModule]: moduleFieldValues };

			await handleOnUpdate({
				modules,
			});
		}
	};

	useEffect(() => {
		dispatch(fetchFormulas(currenModule));
		const allModules = allData.modules || {};
		const currentModuleItem = allModules[currenModule] || {};
		const currentModuleInputData = currentModuleItem.inputData || {};
		updateFormData({
			customCategoriesTimeEffort: [],
			customCategoriesCost: [],
			timeEffortOfCustomCategories: 0,
			timeEffortWithHarnessOfCustomCategories: 0,
			annualCostOfCustomCategories: 0,
			annualCostWithHarnessOfCustomCategories: 0,
			...currentModuleInputData,
		});
	}, [currenModule]);

	useEffect(() => {
		if (draftId || sdcaId || duplicatedFrom || fromRestore) {
			const formRef = formEl.current;
			if (formRef) {
				const formValues = allData.modules[currenModule]?.inputData || {};
				formRef.setFieldsValue(formValues);
			}
		}
	}, [draftId, sdcaId, resUI, currenModule]);

	const doCalculation = (strFormula, scope) => {
		let result;
		try {
			result = evaluate(strFormula, scope);
		} catch (e) {
			console.warn(".. doCalculation : sth goes wrong ..", {
				result,
				strFormula,
				scope,
				e,
			});
			return 0;
		}

		if (!result) {
			return 0;
		}
		return result < 1 ? Math.round(result * 100) / 100 : Math.round(result);
	};

	const updateResults = (value) => {
		dispatch(updateCalResults(value));
	};

	useEffect(() => {
		const {
			modules: {
				common: { inputData: commonScopeData = {} } = {},
				[currenModule]: { inputData: scopeData = {} } = {},
			} = {},
		} = allData;
		const dupCommonScopeData = { ...commonScopeData };
		const keys = Object.keys(dupCommonScopeData);
		for (const commonDataItem of keys) {
			if (
				!dupCommonScopeData[commonDataItem] &&
				commonDataItem !== "numberOfServicesOnboardedPerYear" // NOTE: Possible for this to be 0 because clients mre working with a monolith
			) {
				delete dupCommonScopeData[commonDataItem];
			}
		}

		const resFormulasData =
			formulas[currenModule]?.formulas || formulas[currenModule] || {};
		const formulasData = resFormulasData.statusCode ? {} : resFormulasData;
		const flatScope = flattenScope({
			...scopeData,
			...dupCommonScopeData,
			numberOfServicesApplicationsYear1,
			numberOfServicesApplicationsYear2,
			numberOfServicesApplicationsYear3,
			costOfHarnessCumuYear1,
			costOfHarnessCumuYear2,
			costOfHarnessCumuYear3,
		});
		setFlatScopeState(flatScope);

		const arrFormulasData = Object.entries(formulasData);
		for (const arrSection of arrFormulasData) {
			const secFormulas = arrSection[1];
			const sectionFormulas = Array.isArray(secFormulas) ? secFormulas : [];
			for (const secFormula of sectionFormulas) {
				const strFormula = secFormula?.Formula;
				const result = doCalculation(strFormula, flatScope);
				updateResults({ [getLHSVariable(strFormula)]: result });
			}
		}
	}, [formulas, allData, currenModule]);

	const handleToggle = () => {
		setResultIsOpen(!resultIsOpen);
	};

	const handleFieldsChange = async (changedFields, allFields) => {
		if (currenModule && handleOnUpdate) {
			const allModules = allData.modules || {};
			const currentModuleItem = allModules[currenModule] || {};
			const inputData = { ...(currentModuleItem.inputData || {}) };
			for (const field of allFields) {
				inputData[(field.name || [])[0]] = field.value;
			}
			const moduleFieldValues = { inputData };
			const modules = { ...allModules, [currenModule]: moduleFieldValues };
			await handleOnUpdate({
				modules,
			});
		}
	};

	const onSave = () => {
		updateFormData(formEl.current.getFieldsValue(true));
		onClickInternalStep(["", currenModule]);
	};

	const handleFormSubmit = (allFields) => {
		updateFormData(allFields);
		onClickInternalStep(["next", currenModule]);
	};

	const hasUIs = arrUI.length > 0;
	return (
		hasUIs && (
			<>
				<div className="step3FormWrapper">
					<Form
						layout="vertical"
						onFieldsChange={handleFieldsChange}
						onFinish={handleFormSubmit}
						ref={formEl}
					>
						<div className="formSectionOuter">
							{hasUIs &&
								arrUI.map(([sectionName, rows]) => {
									const resFormulasData =
										formulas[currenModule]?.formulas ||
										formulas[currenModule] ||
										{};
									const formulasData = resFormulasData.statusCode
										? {}
										: resFormulasData;
									const arrFlattenFormulas = flattenFormulas(formulasData);
									const arrSectionFormulas = arrFlattenFormulas.filter(
										(formula) =>
											formula.Section &&
											formula.Section !== "NA" &&
											formula.Section.trim() === sectionName.trim(),
									);

									return (
										<div name={sectionName} key={sectionName}>
											<Collapse
												defaultActiveKey={[
													/Harness Cost Factors/.test(sectionName)
														? null
														: sectionName,
												]}
												key={sectionName}
												bordered={false}
												expandIcon={({ isActive }) => (
													<ChevronDownIcon
														style={{
															transform: isActive ? "rotate(180deg)" : "",
															transition: "0.5s",
															transformOrigin: "50% 30%",
														}}
													/>
												)}
												expandIconPosition="end"
												className="site-collapse"
											>
												<Panel
													className="formSection"
													key={sectionName}
													header={sectionName}
												>
													<div className="formSectionInner">
														<div className="formItems">
															{rows.map((row) => (
																<>
																	{![
																		"Hidden",
																		"IntegerStep",
																		"IntegerStepCustomCategories",
																	].includes(row.DataType) && (
																		<label>
																			<b>{row.Name}</b>
																		</label>
																	)}
																	{row.dataType !== "Hidden" &&
																		row.Quote &&
																		row.Quote !== "NA" && (
																			<div className={step3FormCss.quoteDiv}>
																				<div
																					className={step3FormCss.quoteLine}
																				/>
																				<div
																					className={step3FormCss.quoteFont}
																					// biome-ignore lint/security/noDangerouslySetInnerHtml: Value is sanitized so its fine
																					dangerouslySetInnerHTML={{
																						__html: sanitizeHtml(row.Quote),
																					}}
																				/>
																			</div>
																		)}
																	<FieldComponent
																		item={row}
																		currenModule={currenModule}
																		updateFormData={updateFormData}
																		key={row.Name}
																	/>
																</>
															))}
															{arrSectionFormulas.filter(
																(row) =>
																	row.WorkingValue && row.WorkingValue !== "NA",
															).length > 0 && (
																<>
																	<Collapse
																		ghost
																		expandIcon={({ isActive }) => (
																			<div style={{ marginTop: "5px" }}>
																				<ChevronDownIcon
																					style={{
																						transform: isActive
																							? "rotate(180deg)"
																							: "",
																						transition: "0.5s",
																						transformOrigin: "50% 50%",
																					}}
																				/>
																			</div>
																		)}
																	>
																		<Panel
																			header={
																				<span
																					className={
																						step3FormCss.formulaLabelFont
																					}
																				>
																					Formulas
																				</span>
																			}
																		>
																			<div
																				style={{
																					marginTop: "-15px",
																					display: "flex",
																					flexDirection: "column",
																					gap: "15px",
																				}}
																			>
																				{arrSectionFormulas.map((formula) => {
																					const replaceVariableWithValue = (
																						text,
																					) => {
																						const transformed = parse(
																							text.trim(),
																						).transform((node) => {
																							if (!isEmpty(flatScopeState)) {
																								if (
																									node.isSymbolNode &&
																									Object.keys(
																										flatScopeState,
																									).includes(node.name)
																								) {
																									return new ConstantNode(
																										numberFormatter(
																											+Number.parseFloat(
																												flatScopeState[
																													node.name
																												],
																											).toFixed(2),
																										),
																									);
																								}
																								if (
																									node.isSymbolNode &&
																									node.name ===
																										"timeUnitDividerRefConst"
																								) {
																									if (
																										flatScopeState.timeUnitDivider ===
																										60
																									) {
																										return new ConstantNode(
																											"hours",
																										);
																									}
																									if (
																										flatScopeState.timeUnitDivider ===
																										1
																									) {
																										return new ConstantNode(
																											"mins",
																										);
																									}
																									return new ConstantNode("-");
																								}
																							}
																							return node;
																						});
																						let transformedText = transformed
																							.toString()
																							.replaceAll('"', "")
																							.replaceAll(" %", "%")
																							.replaceAll("$ ", "$")
																							.replaceAll(" / 1)", "");
																						if (
																							transformedText.endsWith(" / 1")
																						) {
																							transformedText =
																								transformedText.replace(
																									" / 1",
																									"",
																								);
																						}
																						return transformedText;
																					};

																					const formulaText = (
																						formula.Name || ""
																					).trim();
																					let workingText =
																						formula.WorkingValue || "";
																					try {
																						workingText =
																							replaceVariableWithValue(
																								workingText,
																							);
																					} catch (err) {
																						console.error(err);
																					}
																					return workingText &&
																						workingText !== "NA" ? (
																						<span
																							key={formulaText}
																							className={
																								step3FormCss.formulaContentFont
																							}
																						>
																							{formulaText}: <br />
																							<i>{workingText}</i>
																						</span>
																					) : null;
																				})}
																			</div>
																		</Panel>
																	</Collapse>
																</>
															)}
															<Collapse
																ghost
																expandIcon={({ isActive }) => (
																	<div style={{ marginTop: "5px" }}>
																		<ChevronDownIcon
																			style={{
																				transform: isActive
																					? "rotate(180deg)"
																					: "",
																				transition: "0.5s",
																				transformOrigin: "50% 50%",
																			}}
																		/>
																	</div>
																)}
															>
																{rows.filter(
																	(row) =>
																		row.ImprovementDescription &&
																		row.ImprovementDescription !== "NA",
																).length > 0 && (
																	<Panel
																		header={
																			<span
																				className={
																					step3FormCss.formulaLabelFont
																				}
																			>
																				% Improvement Benchmark:
																			</span>
																		}
																	>
																		<div
																			style={{
																				marginTop: "-15px",
																				display: "flex",
																				flexDirection: "column",
																				gap: "15px",
																			}}
																		>
																			{rows.map((row) =>
																				row.ImprovementDescription &&
																				row.ImprovementDescription !== "NA" ? (
																					<span
																						key={row.Name}
																						className={
																							step3FormCss.improvementContentFont
																						}
																					>
																						{row.Name}:{" "}
																						{row.ImprovementDescription}
																						<br />
																					</span>
																				) : null,
																			)}
																		</div>
																	</Panel>
																)}
															</Collapse>
															<Collapse
																bordered={false}
																expandIconPosition="end"
																className="note-collapse"
																expandIcon={({ isActive }) => (
																	<ChevronDownIcon
																		style={{
																			transform: isActive
																				? "rotate(180deg)"
																				: "",
																			transition: "0.5s",
																			transformOrigin: "50% 30%",
																		}}
																	/>
																)}
															>
																<Panel
																	header={
																		<div>
																			<EditOutlined /> Add Notes
																		</div>
																	}
																	key={`${sectionName.split(" ").join("")}-text`}
																>
																	<Form.Item
																		name={`${sectionName.split(" ").join("")}-note`}
																		label={
																			<p>
																				Additional Information
																				<span>(optional)</span>
																			</p>
																		}
																	>
																		<TextArea rows={4} id="fte-notes" />
																	</Form.Item>
																</Panel>
															</Collapse>
														</div>

														<div className="previewItems">
															<SectionResult
																type={currenModule}
																arrSectionFormulas={arrSectionFormulas}
															/>
														</div>
													</div>
												</Panel>
											</Collapse>
										</div>
									);
								})}
						</div>
						{!hasUIs && (
							<div className="comingSoon">
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</div>
						)}

						<div className="btns">
							<Button
								htmlType="button"
								onClick={() => onClickInternalStep(["back", currenModule])}
								loading={loading}
							>
								❮ Back
							</Button>
							<Button type="primary" htmlType="submit" loading={loading}>
								Save and Continue ❯
							</Button>
							<Button
								type="link"
								loading={loading}
								className={step3FormCss.saveAsDraftBtn}
								onClick={onSave}
							>
								Save as Draft
							</Button>
						</div>
					</Form>
				</div>
				<Result
					isFixed={isFixed}
					resultIsOpen={resultIsOpen}
					handleToggle={handleToggle}
					type={currenModule}
					formulas={formulas}
					arrUI={arrUI}
				/>
			</>
		)
	);
}

const Step3ModulePage = React.memo(Step3Module);
export { Step3ModulePage };
