import { getToken } from "@/core/components/TokenSession/utils";
import { message } from "antd";
import axios, { type AxiosError, type AxiosRequestConfig } from "axios";

type ApiErrorObject = {
	statusCode: number;
	message: string;
};

const getErrorObject = (error: AxiosError): ApiErrorObject => {
	if (error.response) {
		return {
			statusCode: error.response.status,
			message: error.response.statusText,
		};
	}
	return {
		statusCode: -1,
		message: "An unexpected error occurred. Response not found in AxiosError",
	};
};

const showErrorMessage = (
	error: AxiosError,
	errMsg = "Something went wrong: ",
) => {
	const objError = getErrorObject(error);
	const { statusCode, message: objErrorMessage } = objError;
	message.error(
		<>
			<span>{errMsg}</span>
			<br />
			<span>
				{statusCode ? `[${statusCode}] ` : ""}
				{objErrorMessage || ""}
			</span>
		</>,
	);
};

const authAxios = (reqConfig: AxiosRequestConfig) => {
	const token = getToken();
	const newHeaders = {
		...(reqConfig?.headers || {}),
		Authorization: `Bearer ${token}`,
	};
	const newConfig = { ...reqConfig, headers: newHeaders };
	return axios(newConfig);
};

export { authAxios, getErrorObject, showErrorMessage };
