import { PUBLIC_URL } from "@/core/constants";
import {
	FacebookFilled,
	GithubOutlined,
	InstagramFilled,
	LinkedinFilled,
	TwitterOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import css from "./index.module.scss";

function LearnMore() {
	return (
		<div className={css.main}>
			<div className={css.hero}>
				<div className={css.responsive}>
					<Link to="/">
						<img
							alt="Harness logo"
							src={`${PUBLIC_URL}/assets/logo.svg`}
							width="120"
						/>
					</Link>
					<div className={css.inner}>
						<h2>BVA Tool - ROI Calculation</h2>
						<h4>A tailor-made automation tool for Harness Sales team </h4>
						<div className={css.desc}>
							Help Sales Reps manages SDCAs, calculate ROI create cuztomizable
							pitch deck based clients’ solution profiles automatically{" "}
						</div>
						<a href="#features">
							<Button>View More</Button>
						</a>
					</div>
				</div>
			</div>
			<div className={`${css.bgDotBlue} ${css.divider}`}>x</div>

			<div className={css.features} id="features">
				<div className={css.responsive}>
					<h2>BVA Tool-RoI Calculation</h2>
					<div className={css.desc}>Feature | Feature | Feature | Feature</div>
					<div className={css.imgWrapper}>
						<img alt="features" src={`${PUBLIC_URL}/assets/features.svg`} />
					</div>
					<div className={css.btnWrapper}>
						<Button type="primary">Use Now</Button>
					</div>
				</div>
			</div>
			<div className={css.footer}>
				<div className={css.responsive}>
					<div className={css.logoIcons}>
						<div className={css.logo}>
							<Link to="/">
								<img
									alt="Harness logo"
									src={`${PUBLIC_URL}/assets/logo-light.svg`}
									width="120"
								/>
							</Link>
						</div>
						<ul className={css.followUs}>
							<div>Follow us</div>
							<li>
								<a href="https://github.com/drone/drone">
									<GithubOutlined />
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/harnessinc/">
									<FacebookFilled />
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/harnessinc/">
									<LinkedinFilled />
								</a>
							</li>
							<li>
								<a href="https://twitter.com/harnessio/">
									<TwitterOutlined />
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/harness.io/">
									<InstagramFilled />
								</a>
							</li>
						</ul>
					</div>
					<div className={css.termsNPolicies}>
						<div className={css.copyRight}>© 2024 Harness Inc.</div>
					</div>
				</div>
			</div>
		</div>
	);
}
const LearnMorePage = React.memo(LearnMore);
export { LearnMorePage };
