import { ENV, PROD_ENV } from "@/core/constants";
import type { CalculationActions } from "@/core/reducers/calculationReducer/types";
import type { FormulasActions } from "@/core/reducers/formulaReducer/types";
import type { HomeActions } from "@/core/reducers/homeReducer/types";
import type { LoginActions } from "@/core/reducers/loginReducer/types";
import type { ModuleActions } from "@/core/reducers/moduleReducer/types";
import type { OpportunityActions } from "@/core/reducers/opportunityReducer/types";
import type { ResultsActions } from "@/core/reducers/resultsReducer/types";
import rootReducer from "@/core/reducers/rootReducer";
import type { SdcaActions } from "@/core/reducers/sdcaReducer/types";
import type { SettingsActions } from "@/core/reducers/settingsReducer/types";
import type { UiActions } from "@/core/reducers/uiReducer/types";
import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, createStore } from "redux";
import thunk, { type ThunkDispatch } from "redux-thunk";

let composedEnhancer = applyMiddleware(thunk);
if (ENV !== PROD_ENV) {
	composedEnhancer = composeWithDevTools(composedEnhancer);
}

const store = createStore(rootReducer, composedEnhancer);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<
	RootState,
	null,
	| CalculationActions
	| FormulasActions
	| HomeActions
	| LoginActions
	| ModuleActions
	| OpportunityActions
	| ResultsActions
	| SdcaActions
	| SettingsActions
	| UiActions
>;
export default store;
