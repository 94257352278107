import {
	getUserOkta,
	setTokenOkta,
	signInOkta,
	signOutOkta,
} from "@/core/auth/adapters/okta";
import type { TokenTypes } from "@/core/auth/types";
import {
	clearToken,
	setToken,
	setTokenType,
} from "@/core/components/TokenSession/utils";

export const signInApp = async (tokenType: TokenTypes) => {
	setTokenType(tokenType);
	await signInOkta();
};

export const signOutApp = async () => {
	clearToken();
	await signOutOkta();
};

export const setAuthToken = async () => {
	const accessToken = await setTokenOkta();
	if (accessToken) {
		setToken(accessToken);
	}
	return !!accessToken;
};

export const getUser = async () => getUserOkta();
