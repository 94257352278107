import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Button, InputNumber, Select } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { ReactSVG } from "react-svg";
import { PUBLIC_URL } from "../../constants";
import { numberFormatter, numberParser } from "../../utils";
import InputTime from "../InputTime";
import css from "./FTEEnum.module.scss";

const { Option } = Select;

const FTEList = [
	"Software Engineer",
	"DevOps Engineer",
	"Developer",
	"QA Engineer",
	"Operations Engineer",
	"Database Engineer",
	"Security Engineer",
	"SRE",
	"Manager",
	"Others",
];
const DEFAULT_VALUE = {
	fteType: null,
	fteNumber: 1,
	fteTime: { originalValue: 1, originalUnit: "hours", value: 60 },
};

const convertFTEEnumInitialValue = (initialValue) => {
	let arrInitialValue = [DEFAULT_VALUE];
	if (Array.isArray(initialValue)) {
		arrInitialValue = initialValue;
	} else if (
		initialValue &&
		typeof initialValue === "string" &&
		initialValue.length > 2
	) {
		arrInitialValue = JSON.parse(initialValue);
	}

	const valueSum = arrInitialValue.reduce((sum, item) => {
		if (item?.fteTime) {
			return sum + item.fteNumber * item.fteTime.value;
		}
		return sum;
	}, 0);
	const retVal = {
		originalValue: arrInitialValue,
		value: valueSum,
	};
	return retVal;
};
const getDefaultValue = (defaultValue) => {
	const objDefaultVal =
		typeof defaultValue === "string" ? JSON.parse(defaultValue) : defaultValue;
	return Array.isArray(objDefaultVal)
		? convertFTEEnumInitialValue(objDefaultVal)
		: { value: DEFAULT_VALUE.fteNumber * DEFAULT_VALUE.fteTime.value };
};

function FTEEnum({ value = {}, onChange, initialValue }) {
	const defaultValue = useMemo(
		() =>
			initialValue?.originalValue || value?.originalValue || [DEFAULT_VALUE],
		[initialValue, value],
	);

	const updateChangeToField = (newCount) => {
		if (!newCount) {
			return;
		}

		const retVal = convertFTEEnumInitialValue(newCount);
		onChange(retVal);
	};

	const [count, setCount] = useState(defaultValue);

	useEffect(() => {
		const newCount = value?.originalValue || [defaultValue];
		setCount(newCount);
	}, [value, defaultValue]);

	useEffect(() => {
		const newCount = initialValue?.originalValue ||
			value?.originalValue || [defaultValue];
		updateChangeToField(newCount);
	}, []);

	const handleChange = (idx, key, newItemVal) => {
		if (!key || !newItemVal) {
			return;
		}
		const newCount = [...count];
		const changedItem = newCount[idx];
		newCount.splice(idx, 1, { ...changedItem, [key]: newItemVal });
		setCount(newCount);

		updateChangeToField(newCount);
	};

	const addFTE = () => {
		const newCount = [...count, DEFAULT_VALUE];
		setCount(newCount);
		updateChangeToField(newCount);
	};
	const removeFTE = (idx) => {
		const newCount = [...count];
		newCount.splice(idx, 1);
		setCount(newCount);
		updateChangeToField(newCount);
	};

	return (
		<div className={css.fteEnum}>
			{count.map((item, idx) => (
				<div className={css.fteItem} key={new Date().toISOString}>
					<div className={css.row}>
						<span className={css.label}>Type of FTEs</span>
						<Select
							onChange={(newVal) => handleChange(idx, "fteType", newVal)}
							id="fte-type"
							value={item.fteType}
							style={{
								width: 200,
							}}
							suffixIcon={<ChevronDownIcon />}
						>
							{FTEList.map((fte) => (
								<Option value={fte} key={fte}>
									{fte}
								</Option>
							))}
						</Select>
					</div>
					<div className={css.row}>
						<span className={css.label}># of People</span>
						<InputNumber
							id="fte-number"
							value={item.fteNumber}
							formatter={numberFormatter}
							parser={numberParser}
							onChange={(newVal) => handleChange(idx, "fteNumber", newVal)}
							style={{
								width: 200,
							}}
						/>
					</div>

					<div className={css.row}>
						<span className={css.label}>Time Spent</span>
						<InputTime
							id="fte-time"
							min="0"
							value={item.fteTime}
							onChange={(newVal) => handleChange(idx, "fteTime", newVal)}
						/>
					</div>
					<Button
						type="text"
						onClick={() => removeFTE(idx)}
						className={css.deleteButton}
						icon={<ReactSVG src={`${PUBLIC_URL}/assets/icon-bin.svg`} />}
					/>
				</div>
			))}

			<div className={css.addButton}>
				<Button type="link" onClick={addFTE}>
					+ Add FTE
				</Button>
			</div>
		</div>
	);
}

export default FTEEnum;
export { getDefaultValue, convertFTEEnumInitialValue };
