import "antd/lib/dropdown/style/css";
import "antd/lib/button/style/css";
import "antd/lib/table/style/css";
import "antd/lib/popconfirm/style/css";
import "antd/lib/message/style/css";
import "antd/lib/input/style/css";
import "antd/lib/input-number/style/css";
import "antd/lib/checkbox/style/css";
import "antd/lib/space/style/css";
import "antd/lib/tooltip/style/css";
import "antd/lib/collapse/style/css";
import "antd/lib/empty/style/css";
import "antd/lib/list/style/css";
import "antd/lib/form/style/css";
import "antd/lib/menu/style/css";
import "antd/lib/modal/style/css";
import "antd/lib/auto-complete/style/css";
import "antd/lib/carousel/style/css";
import "antd/lib/slider/style/css";
import "antd/lib/tabs/style/css";
import "./global.scss"; // This import has to come AFTER all the antd imports if not the overriding CSS have issues
import Router from "@/core/router";
import { BrowserRouter } from "react-router-dom";

function App() {
	return (
		<div className="container">
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</div>
	);
}

export default App;
