import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Button, Input, InputNumber, Select } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { ReactSVG } from "react-svg";
import { PUBLIC_URL } from "../../constants";
import { useAppSelector } from "../../hooks/reduxHooks";
import { getPerctReductionValue } from "../../pages/Calculation/steps/utils";
import {
	currencyFormatter,
	currencyParser,
	numberFormatter,
	numberParser,
	round,
} from "../../utils";
import InputTime from "../InputTime";
import css from "./CustomCategories.module.scss";

const { Option } = Select;

export const regCustomCategories = /^Misc|Additional Value/;

const categoryList = ["Annual Time Effort", "Annual Cost"];
const DEFAULT_VALUE = {
	id: "custom_category_0",
	categoryName: "",
	categoryType: categoryList[1],
	fteNumber: 1,
	fteTime: { originalValue: 1, originalUnit: "hours", value: 60 },
	cost: 0,
};

const convertCustomCategoriesInitialValue = (initialValue) => {
	let arrInitialValue = [];
	if (Array.isArray(initialValue)) {
		arrInitialValue = initialValue;
	} else if (
		initialValue &&
		typeof initialValue === "string" &&
		initialValue.length > 2
	) {
		arrInitialValue = JSON.parse(initialValue) || [];
	}

	const valueSum = arrInitialValue.reduce(
		(sum, item) => {
			const { categoryType, fteNumber, fteTime, cost } = item || {};
			if (item && categoryType) {
				const costOnly = categoryType === categoryList[1];
				if (costOnly) {
					return {
						value: sum.value,
						cost: sum.cost + cost,
					};
				}
				return {
					// leave cost as it is to avoid double-adding-up,
					// since the time effort will be converted and added to the cost as well later
					value: sum.value + fteNumber * fteTime.value,
					cost: sum.cost,
				};
			}
			return sum;
		},
		{ value: 0, cost: 0 },
	);
	const retVal = {
		originalValue: arrInitialValue,
		value: valueSum.value, // time effort => needs to be converted to cost
		cost: valueSum.cost, // cost only
	};
	return retVal;
};
const getDefaultValue = (defaultValue) => {
	return convertCustomCategoriesInitialValue(defaultValue);
};

const generateUniqueId = (idx, timestamp, prefix = "custom_cate_") => {
	const timeInfo = timestamp || new Date().getTime() % 1000000000;
	return prefix + timeInfo + idx;
};
const addId = (arrCount, prefix = "custom_cate_") => {
	if (!arrCount || !Array.isArray(arrCount)) {
		return [];
	}
	const timeInfo = new Date().getTime() % 1000000000;
	const newCount = arrCount.map((item, idx) => {
		if (item.id) {
			return item;
		}
		return Object.assign(item, { id: generateUniqueId(idx, timeInfo, prefix) });
	});
	return newCount;
};

function CustomCategories({
	value = {},
	onChange,
	initialValue,
	updateFormData,
	currenModule,
}) {
	const allData = useAppSelector((state) => state.calculation);
	const defaultValue = useMemo(
		() => initialValue?.originalValue || value?.originalValue || [],
		[initialValue, value],
	);

	const updateChangeToField = (newCount) => {
		if (!newCount) {
			return;
		}

		const retVal = convertCustomCategoriesInitialValue(newCount);

		onChange(retVal);

		if (!allData.modules?.[currenModule]) {
			allData.modules[currenModule] = {};
		}
		const mdlData = allData.modules[currenModule].inputData || {};
		const commonData = allData.modules?.common.inputData;

		const cateArray = newCount;
		const perctReductionData =
			mdlData[
				`perctReductionInCostOfCustomCategories${currenModule.toUpperCase()}`
			];

		const timeEffortCustomCategories = cateArray.filter(
			(cate) => cate.categoryType === "Annual Time Effort",
		);
		const {
			salaryEmployeeFTEFullyBurdened,
			workHoursPerYear,
			minutesPerHour,
			timeUnitDivider,
		} = commonData;
		const salaryFTEFullyBurnedPerMinute =
			salaryEmployeeFTEFullyBurdened / (workHoursPerYear * minutesPerHour);
		let timeEffortOfCustomCategories = 0;
		let timeEffortWithHarnessOfCustomCategories = 0;
		let annualCostOfCustomCategories = 0;
		let annualCostWithHarnessOfCustomCategories = 0;

		const customCategoriesTimeEffort = timeEffortCustomCategories.map(
			(cate) => {
				const perctReductionValue = getPerctReductionValue(
					perctReductionData,
					cate.id,
				);
				const cateTimeEffort =
					(cate.fteTime.value * cate.fteNumber) / timeUnitDivider;
				const cateTimeEffortWithHarness = round(
					cateTimeEffort * (1 - perctReductionValue / 100),
				);
				timeEffortOfCustomCategories += cateTimeEffort;
				timeEffortWithHarnessOfCustomCategories += cateTimeEffortWithHarness;
				return {
					id: cate.id,
					categoryName: cate.categoryName,
					value: cateTimeEffort,
					withHarnessValue: cateTimeEffortWithHarness,
				};
			},
		);
		const customCategoriesCost = cateArray.map((cate) => {
			const perctReductionValue = getPerctReductionValue(
				perctReductionData,
				cate.id,
			);
			if (cate.categoryType === "Annual Time Effort") {
				const calculatedCost = round(
					cate.fteTime.value * cate.fteNumber * salaryFTEFullyBurnedPerMinute,
				);
				const calculatedCostWithHarness = round(
					calculatedCost * (1 - perctReductionValue / 100),
				);
				annualCostOfCustomCategories += calculatedCost;
				annualCostWithHarnessOfCustomCategories += calculatedCostWithHarness;
				return {
					id: cate.id,
					categoryName: cate.categoryName,
					value: calculatedCost,
					withHarnessValue: calculatedCostWithHarness,
				};
			}
			const withHarnessValue = round(
				cate.cost * (1 - perctReductionValue / 100),
			);
			annualCostOfCustomCategories += cate.cost;
			annualCostWithHarnessOfCustomCategories += withHarnessValue;
			return {
				id: cate.id,
				categoryName: cate.categoryName,
				value: cate.cost,
				withHarnessValue,
			};
		});

		updateFormData({
			timeEffortOfCustomCategories,
			timeEffortWithHarnessOfCustomCategories,
			annualCostOfCustomCategories,
			annualCostWithHarnessOfCustomCategories,
			customCategoriesTimeEffort,
			customCategoriesCost,
		});
	};

	const [count, setCount] = useState(defaultValue);

	useEffect(() => {
		const newCount = addId(value?.originalValue || defaultValue);
		setCount(newCount);
	}, [value, defaultValue]);

	useEffect(() => {
		const newCount = addId(
			initialValue?.originalValue || value?.originalValue || defaultValue,
		);
		updateChangeToField(newCount);
	}, []);

	const handleChange = (idx, key, newItemVal) => {
		if (!key || newItemVal === undefined || newItemVal === null) {
			return;
		}
		const newCount = [...count];
		const changedItem = newCount[idx];
		newCount.splice(idx, 1, { ...changedItem, [key]: newItemVal });
		setCount(newCount);

		updateChangeToField(newCount);
	};

	const addCategory = () => {
		const newCount = [
			...count,
			Object.assign(DEFAULT_VALUE, { id: generateUniqueId(count.length) }),
		];
		setCount(newCount);
		updateChangeToField(newCount);
	};
	const removeCategory = (idx) => {
		const newCount = [...count];
		newCount.splice(idx, 1);
		setCount(newCount);
		updateChangeToField(newCount);
	};

	return (
		<div className={css.categoryEnum}>
			{count.map((item, idx) => (
				<div className={css.categoryItem} key={item.id}>
					<div className={css.fieldsBinRow}>
						<div>
							<div className={css.row}>
								<span className={css.label}>Category Name</span>
								<Input
									type="text"
									id="category-name"
									value={item.categoryName}
									required
									onChange={(e) =>
										handleChange(idx, "categoryName", e.target.value)
									}
									style={{
										width: 260,
									}}
								/>
							</div>
							<div className={css.row}>
								<span className={css.label}>Type</span>
								<Select
									onChange={(newVal) =>
										handleChange(idx, "categoryType", newVal)
									}
									id="category-type"
									value={item.categoryType}
									style={{
										width: 260,
									}}
									suffixIcon={<ChevronDownIcon />}
								>
									{categoryList.map((cateType) => (
										<Option value={cateType} key={cateType}>
											{cateType}
										</Option>
									))}
								</Select>
							</div>
							{item.categoryType === categoryList[0] ? (
								<>
									<div className={css.row}>
										<span className={css.label}>Number of FTEs</span>
										<InputNumber
											id="fte-number"
											value={item.fteNumber}
											formatter={numberFormatter}
											parser={numberParser}
											onChange={(newVal) =>
												handleChange(idx, "fteNumber", newVal)
											}
											style={{
												width: 260,
											}}
										/>
									</div>

									<div className={css.row}>
										<span className={css.label}>Time spent per FTE</span>
										<InputTime
											id="fte-time"
											min="0"
											value={item.fteTime}
											onChange={(newVal) =>
												handleChange(idx, "fteTime", newVal)
											}
										/>
									</div>
								</>
							) : (
								<div className={css.row}>
									<span className={css.label}>Annual Cost</span>
									<InputNumber
										id="cost"
										value={item.cost}
										formatter={currencyFormatter}
										parser={currencyParser}
										onChange={(newVal) => handleChange(idx, "cost", newVal)}
										style={{
											width: 260,
										}}
									/>
								</div>
							)}
						</div>
						<Button
							type="text"
							onClick={() => removeCategory(idx)}
							className={css.deleteButton}
							icon={<ReactSVG src={`${PUBLIC_URL}/assets/icon-bin.svg`} />}
						/>
					</div>
				</div>
			))}

			<div className={css.addButton}>
				<Button type="link" onClick={addCategory}>
					+ Add Category
				</Button>
			</div>
		</div>
	);
}

export default CustomCategories;
export { getDefaultValue, convertCustomCategoriesInitialValue };
