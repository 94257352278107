export default {
  "loginMain": "_loginMain_1ekre_1",
  "loginMainLeft": "_loginMainLeft_1ekre_6",
  "loginMainLeftInner": "_loginMainLeftInner_1ekre_12",
  "logo": "_logo_1ekre_17",
  "notification": "_notification_1ekre_42",
  "lineWrapper": "_lineWrapper_1ekre_49",
  "notice": "_notice_1ekre_71",
  "or": "_or_1ekre_77",
  "links": "_links_1ekre_88",
  "linksBtm": "_linksBtm_1ekre_128",
  "btmText": "_btmText_1ekre_149",
  "loginMainRight": "_loginMainRight_1ekre_156",
  "bgDotBlue": "_bgDotBlue_1ekre_169"
};import "ni:sha-256;tuD0A4b0iR5ZZ9avoVnMdzl3XzPhvvmz30L09Qwfkig";