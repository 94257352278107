import { ENV, LOCAL_ENV } from "@/core/constants";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
	type BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import React from "react";

function BugsnagWrapper({ children }: { children: React.ReactNode }) {
	if (ENV !== LOCAL_ENV && process.env.REACT_APP_BUGSNAG_API_KEY) {
		Bugsnag.start({
			apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
			plugins: [new BugsnagPluginReact()],
			releaseStage: ENV,
		});
		const plugin: BugsnagPluginReactResult | undefined =
			Bugsnag.getPlugin("react");
		if (plugin) {
			const Wrapper = plugin.createErrorBoundary(React);
			return <Wrapper>{children}</Wrapper>;
		}
	}
	return <div>{children}</div>;
}

const setUser = (id: string, email: string, name?: string | undefined) => {
	Bugsnag.setUser(id, email, name);
};

const notifyBugsnag = (err: Error) => Bugsnag.notify(err as Error);

export { BugsnagWrapper, setUser, notifyBugsnag };
